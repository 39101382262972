import {space, color, atoms as a, Image, Icon, web} from '@/base'
import React from 'react'
import {Time} from '@/components/Time'
import sb from '@sb/util'
import store from '@sb/store'
import {Progress} from '@/components/Progress'
import {View, ScrollView, StyleSheet} from 'react-native'
import {useInteractionState} from '@/base/hooks/useInteractionState'
import {NotificationItem} from '@/components/NotificationItem'

export function NotificationList({show}) {
	if (!store.me().id) return <Text>Need login</Text>
	const {state: hovered, onIn: onHoverIn, onOut: onHoverOut} = useInteractionState()

	let [_, forceUpdate] = React.useState(0)
	let [loading, setLoading] = React.useState(true)
	let [anchor, setAnchor] = React.useState('')
	let [loadingMore, setLoadingMore] = React.useState(false)
	let [outOfNoti, setOutOf] = React.useState(false)

	React.useEffect(() => {
		let _seenInterval = setInterval(() => {
			if (show) store.seenAllNoti()
		}, 5000)

		let cleanF = store.onNoti(this, () => {
			return
			forceUpdate((p) => p + 1)
		})

		;(async function () {
			let notibody = store.matchNotis() || {}
			if (lo.size(notibody.notifications) == 0) await store.fetchNotis('all')
			setLoading(false)

			if (show) store.seenAllNoti()
		})()
		return () => {
			cleanF()
			clearInterval(_seenInterval)
		}
	}, [])

	let $body = null
	if (loading) {
		$body = (
			<View style={[a.flex_col, a.align_center, a.justify_center, a.h_full]}>
				<Icon size='xl' color={color.contrast_500} name='loader' />
				<Text style={[a.text_muted, a.mt_md]}>{$t('loading')}</Text>
			</View>
		)
	}

	let onNotiClick = (noti) => {
		store.markReadNoti(noti.id)
	}
	let notibody = store.matchNotis() || {}
	console.log('DDDD', notibody)
	let notis = lo.orderBy(notibody.notifications, 'created', 'desc')
	let $notis = lo.map(notis, (noti) => {
		return <NotificationItem noti={noti} key={noti.id} onClick={() => onNotiClick(noti)} />
	})

	let $loadmore = null
	if (loadingMore) {
		$loadmore = (
			<div class='d-flex align-items-center justify-content-center mt-4 mb-4'>
				<Spinner size='20' mode='blue' />
			</div>
		)
	}

	if (notis.length == 0) {
		$body = (
			<View style={[a.flex_col, a.p_xl, a.align_center, a.justify_center, {height: '100%'}]}>
				<View style={[a.p_md, a.bg_contrast_100, a.rounded_full]}>
					<Icon size='xl' color={color.contrast_500} name='list-details' />
				</View>
				<Text style={[a.text_center, a.text_muted, a.mt_md]}>{$t('notifications_empty')}</Text>
			</View>
		)
	} else {
		$body = <ScrollView style={[a.flex_col, a.flex_1]}>{$notis}</ScrollView>
	}

	let $outofnoti = null
	if (outOfNoti) {
		$outofnoti = (
			<div class='text__muted text-center ml-4 mr-4 pt-3 mt-3' style='border-top: 2px solid #eee'>
				{$t('out_of_notification')}
			</div>
		)
	}

	return (
		<View style={[a.w_full, a.flex_col, {background: color.contrast_50, overflowY: 'hidden', height: 400}]}>
			{$body}
			{$outofnoti}
			{$loadmore}
		</View>
	)
}
