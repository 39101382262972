import React, {Children} from 'react'
import {Text as RNText, StyleSheet} from 'react-native'
// import {UITextView} from 'react-native-uitextview'
import createEmojiRegex from 'emoji-regex'
import {web} from './platform'

import sb from '@sb/util'
import {atoms as a, fontWeight, lineHeight} from './atoms'
import {applyFonts, fonts, useFonts as ufs} from './fonts'

/**
 * Util to calculate lineHeight from a text size atom and a leading atom
 *
 * Example:
 *   `leading(atoms.text_md, atoms.leading_normal)` // => 24
 */
export function leading(textSize, leading) {
	const size = textSize?.fontSize || atoms.text_md.fontSize
	const lineHeight = leading?.lineHeight || atoms.leading_normal.lineHeight
	return Math.round(size * lineHeight)
}

/**
 * Ensures that `lineHeight` defaults to a relative value of `1`, or applies
 * other relative leading atoms.
 *
 * If the `lineHeight` value is > 2, we assume it's an absolute value and
 * returns it as-is.
 */
export function normalizeTextStyles(styles, {fontScale, fontFamily}) {
	const s = StyleSheet.flatten(styles)
	// should always be defined on these components
	s.fontSize = (s.fontSize || atoms.text_md.fontSize) * fontScale

	if (s?.lineHeight) {
		if (s.lineHeight !== 0 && s.lineHeight <= 2) {
			s.lineHeight = Math.round(s.fontSize * s.lineHeight)
		}
	} else if (!sb.isNative) {
		s.lineHeight = s.fontSize * lineHeight.normal
	}

	applyFonts(s, fontFamily)
	return s
}

const EMOJI = createEmojiRegex()

export function childHasEmoji(children) {
	let hasEmoji = false
	Children.forEach(children, (child) => {
		if (typeof child === 'string' && createEmojiRegex().test(child)) {
			hasEmoji = true
		}
	})
	return hasEmoji
}

export function renderChildrenWithEmoji(children, props, emoji) {
	if (!sb.isIOS || !emoji) {
		return children
	}
	return Children.map(children, (child) => {
		if (typeof child !== 'string') return child

		const emojis = child.match(EMOJI)

		if (emojis === null) {
			return child
		}

		return child.split(EMOJI).map((stringPart, index) => [
			stringPart,
			emojis[index] ? (
				<RNText {...props} style={[props?.style, {fontFamily: 'System'}]}>
					{emojis[index]}
				</RNText>
			) : null,
		])
	})
}

const SINGLE_EMOJI_RE = /^[\p{Emoji_Presentation}\p{Extended_Pictographic}]+$/u
export function isOnlyEmoji(text) {
	return text.length <= 15 && SINGLE_EMOJI_RE.test(text)
}

/**
 * Our main text component. Use this most of the time.
 */
export function Text({children, emoji, style, selectable, title, dataSet, ...rest}) {
	const s = normalizeTextStyles([a.text_md, a.text, StyleSheet.flatten(style)], {
		fontScale: fonts.scale,
		fontFamily: fonts.family,
	})

	if (__DEV__) {
		if (!emoji && childHasEmoji(children)) {
			logger.warn(`Text: emoji detected but emoji not enabled: "${children}"\n\nPlease add <Text emoji />'`)
		}
	}

	const shared = {
		uiTextView: true,
		selectable,
		style: s,
		dataSet: Object.assign({tooltip: title}, dataSet || {}),
		...rest,
	}

	return <RNText {...shared}>{renderChildrenWithEmoji(children, shared, emoji ?? false)}</RNText>
}

function createHeadingElement({level, style}) {
	let hstyle = style
	return function HeadingElement({style, ...rest}) {
		const attr =
			web({
				role: 'heading',
				'aria-level': level,
			}) || {}
		return <Text {...attr} {...rest} style={StyleSheet.flatten(hstyle, style)} />
	}
}

/*
 * Use semantic components when it's beneficial to the user or to a web scraper
 */
export const H1 = createHeadingElement({level: 1, style: {fontSize: 40, fontWeight: fontWeight.bold}})
export const H2 = createHeadingElement({level: 2, style: {fontSize: 32, fontWeight: fontWeight.bold}})
export const H3 = createHeadingElement({level: 3, style: {fontSize: 24, fontWeight: fontWeight.bold}})
export const H4 = createHeadingElement({level: 4, style: {fontSize: 20, fontWeight: fontWeight.bold}})
export const H5 = createHeadingElement({level: 5, style: {fontSize: 18, fontWeight: fontWeight.bold}})
export const H6 = createHeadingElement({level: 6, style: {fontSize: 16, fontWeight: fontWeight.bold}})

export function P({style, ...rest}) {
	const attr =
		web({
			role: 'paragraph',
		}) || {}
	return <Text {...attr} {...rest} style={[atoms.text_md, atoms.leading_normal, StyleSheet.flatten(style)]} />
}

export function LabelText({nativeID, children}) {
	return (
		<Text nativeID={nativeID} style={[a.font_bold, a.text_contrast_medium, a.mb_sm]}>
			{children}
		</Text>
	)
}

export let useFonts = ufs
