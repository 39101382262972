import sb from '@sb/util'

import {
	useFonts as ufs,
	Inter_400Regular,
	Inter_500Medium,
	Inter_600SemiBold,
	Inter_700Bold,
} from '@expo-google-fonts/inter'

const WEB_FONT_FAMILIES = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`

const factor = 0.0625 // 1 - (15/16)
const fontScaleMultipliers = {
	'-2': 1 - factor * 3,
	'-1': 1 - factor * 2,
	0: 1 - factor * 1, // default
	1: 1,
	2: 1 + factor * 1,
}

export let fontScale = 1

export function computeFontScaleMultiplier(scale) {
	return fontScaleMultipliers[scale]
}

export function getFontScale() {
	return fontScale ?? '0'
}

export function setFontScale(fs) {
	fontScale = fs
}

export let fontFamily = 'theme'

export function getFontFamily() {
	return fontFamily
}

export function setFontFamily(fF) {
	fontFamily = fF
}

/*
 * Unused fonts are commented out, but the files are there if we need them.
 */
export function applyFonts(style, fontFamily /* 'system' | 'theme'*/) {
	if (fontFamily === 'theme') {
		if (sb.isAndroid) {
			style.fontFamily =
				{
					400: 'Inter-Regular',
					500: 'Inter-Regular',
					600: 'Inter-SemiBold',
					700: 'Inter-SemiBold',
				}[String(style.fontWeight || '400')] || 'Inter-Regular'

			if (style.fontStyle === 'italic') {
				if (style.fontFamily === 'Inter-Regular') {
					style.fontFamily = 'Inter-Italic'
				} else {
					style.fontFamily += 'Italic'
				}
			}

			/*
			 * These are not supported on Android and actually break the styling.
			 */
			delete style.fontWeight
			delete style.fontStyle
		} else {
			style.fontFamily = 'Inter'

			if (style.fontStyle === 'italic') {
				style.fontFamily += 'Italic'
			}
		}

		if (sb.isWeb) {
			// fallback families only supported on web
			style.fontFamily += `, ${WEB_FONT_FAMILIES}`
		}

		/**
		 * Disable contextual alternates in Inter
		 * {@link https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant}
		 */
		style.fontVariant = (style.fontVariant || []).concat('no-contextual')
	} else {
		// fallback families only supported on web
		if (sb.isWeb) {
			style.fontFamily = style.fontFamily || WEB_FONT_FAMILIES
		}

		/**
		 * Overridden to previous spacing for the `system` font option.
		 * https://github.com/bluesky-social/social-app/commit/2419096e2409008b7d71fd6b8f8d0dd5b016e267
		 */
		style.letterSpacing = 0.25
	}
}

export let useFonts = () => {
	return ufs({
		Inter_400Regular,
		Inter_500Medium,
		Inter_600SemiBold,
		Inter_700Bold,
	})
}
export let fonts = {
	scale: fontScale,
	family: fontFamily,
}
