import React from 'react'
import {space, color, atoms as a, Image, Icon, web} from '@/base'
import {Pressable} from 'react-native'
import {Time} from '@/components/Time'
import sb from '@sb/util'
import store from '@sb/store'
import {Progress} from '@/components/Progress'
import {View, StyleSheet} from 'react-native'
import {useInteractionState} from '@/base/hooks/useInteractionState'

function getItemTitle(noti) {
	if (noti.type == 'broadcast_sent') {
		if (noti.meta?.campaign_type == 'zns') {
			return `${$t('send_zns')} ${noti.meta.campaign_name} ${$t('to').toLowerCase()} ${noti.meta?.campaign_total_customers} ${$t('customer').toLowerCase()}`
		}
		if (noti.meta?.campaign_type == 'email') {
			return `${$t('send_email')} ${noti.meta.campaign_name} ${$t('to').toLowerCase()} ${noti.meta?.campaign_total_customers} ${$t('customer').toLowerCase()}`
		}
	}
}

export function NotificationItem({onClick, noti}) {
	if (noti.type == 'user_created') return null
	const {state: hovered, onIn: onHoverIn, onOut: onHoverOut} = useInteractionState()

	let renderImage = (noti) => {
		let $image = null
		let size = 48
		if (noti.type == 'broadcast_sent') {
			if (noti.meta?.campaign_type == 'zns') {
				$image = <Image style={{width: size, height: size}} source={require('../assets/images/noti-send.png')} />
			}
			if (noti.meta?.campaign_type == 'email') {
				$image = <Image style={{width: size, height: size}} source={require('../assets/images/noti-send.png')} />
			}
		}
		return <View style={[a.rounded_full, a.overflow_hidden]}>{$image}</View>
	}

	let $title = null
	let unread = (noti.read_at || 0) < (noti.created || 0)
	let $unread = null
	if (unread) {
		$unread = (
			<View
				style={[{marginLeft: space.sm, backgroundColor: color.primary, width: 12, height: 12, borderRadius: '50%'}]}
			></View>
		)
	}

	let renderContentBody = () => {
		let textstyle = [{}, a.text_sm]
		if (!unread) textstyle.push(a.text_muted)
		if (noti.type == 'broadcast_sent') {
			let status = noti.meta?.campaign_status
			if (noti.meta?.campaign_status == 'completed') {
				return (
					<Text style={textstyle}>
						{$t('send_noti_completed', {
							0: (noti.meta?.campaign_num_acks || 0) - (noti.meta.campaign_num_errors || 0),
							1: noti.meta?.campaign_num_errors,
						})}
					</Text>
				)
			}

			if (status == 'cancelled' || status == 'pause') {
				let $errs = null
				if (noti.meta?.campaign_num_errors) {
					$errs = <>. {noti.meta?.campaign_num_errors + ' ' + $t('failed')}</>
				}
				return (
					<Text style={textstyle}>
						{$t('cancelled_by')}{' '}
						{!store.me().id ? 'Kieu Thanh' : sb.getAgentDisplayName(store.matchAgent(noti.meta.campaign_cancelled_by))}.{' '}
						{(noti.meta?.campaign_num_acks || 0) - (noti.meta.campaign_num_errors || 0) + ' ' + $t('success')}
						{$errs}
					</Text>
				)
			} else {
				return (
					<View style={[a.flex_col, a.gap_sm]}>
						<Text style={textstyle}>
							{$t('sending_noti_to_customer', {
								0: (noti.meta?.campaign_num_acks || 0) - (noti.meta.campaign_num_errors || 0),
								1: noti.meta?.campaign_num_errors,
							})}
						</Text>
						<Progress
							size='sm'
							failed={noti.meta.campaign_num_errors}
							progress={noti.meta?.campaign_num_acks}
							total={noti.meta.campaign_total_customers}
						/>
					</View>
				)
			}
		}
	}

	let timestyle = [a.text_sm, a.text_muted]
	if (unread) timestyle = [{color: color.primary}, a.text_sm, a.font_bold]
	let $time = (
		<View style={[a.ml_md, a.flex_row, a.align_items]}>
			<Time style={timestyle} time={noti.created} ago />
		</View>
	)
	let titlestyle = [{flex: 1}, a.text_muted]
	if (unread) titlestyle = [a.font_semibold]

	let style = [a.flex_row, a.align_start, a.gap_md, a.p_md, a.rounded_sm, a.cursor_pointer, {width: '100%'}]
	if (!unread) style.push({backgroundColor: color.contrast_25})
	if (hovered) style.push({backgroundColor: color.contrast_100})
	return (
		<Pressable onPress={onClick}>
			<View
				style={style}
				{...web({
					onMouseOver: onHoverIn,
					onMouseOut: onHoverOut,
				})}
			>
				{renderImage(noti)}
				<View style={[a.flex_col, a.flex_1, a.gap_xs]}>
					<View style={[a.flex_row, a.align_center]}>
						<Text numberOfLines={1} style={titlestyle}>
							{getItemTitle(noti)}
						</Text>
						{$time}
						{$unread}
					</View>
					{renderContentBody(noti)}
				</View>
			</View>
		</Pressable>
	)
}
