import sb from '@sb/util'
import twemoji from 'twemoji'

window.twemoji = twemoji
const loadEmojis = () => import(/*webpackChunkName: 'emojis'*/ '../../subiz/utils/emojis.json')

const DROPDOWN_WIDTH = 320
const DROPDOWN_HEIGHT = 300
const LIMIT = 100

export default {
	name: 'emoji-picker',
	props: ['selected', 'sm', 'md', 'lg', 'custom', 'extra_cls'],
	data() {
		return {
			isDropdownOpen: false,
			keyword: '',

			emojis: [],
			page: 1,
		}
	},

	async mounted() {
		this.emojis = await loadEmojis()
	},

	methods: {
		toggleDropdown() {
			this.isDropdownOpen = !this.isDropdownOpen
			if (this.isDropdownOpen) {
				this.resetData()
				this.calculateStyle()
			}
		},

		resetData() {
			this.page = 1
			this.keyword = ''
		},

		getDropdownPosition() {
			// return 'bottom-left', 'bottom-right', 'top-left', 'top-right'
			let $trigger = this.$refs.trigger
			let rect = $trigger.getBoundingClientRect() || {}

			let {x = 0, y = 0, width = 0, height = 0} = rect

			// choose 10px for edge with screen
			let isTop = window.innerHeight - (y + height) < DROPDOWN_HEIGHT + 10
			let isRight = window.innerWidth - x < DROPDOWN_WIDTH + 10
			if (isTop && isRight) return 'top-right'
			if (isTop) return 'top-left'
			if (isRight) return 'bottom-right'
			return 'bottom-left'
		},

		calculateStyle() {
			let $trigger = this.$refs.trigger
			let rect = $trigger.getBoundingClientRect() || {}
			let {x = 0, y = 0, bottom = 0, right = 0} = rect
			let position = this.getDropdownPosition()

			if (position === 'top-right') {
				return `bottom: ${window.innerHeight - y}px; right: ${window.innerWidth - right}px; left: unset; top: unset;`
			}
			if (position === 'top-left') {
				return `bottom: ${window.innerHeight - y}px; left: ${x}px; right: unset; top: unset;`
			}
			if (position === 'bottom-right') {
				return `top: ${bottom}px; right: ${window.innerWidth - right}px; left: unset; bottom: unset;`
			}
			return `top: ${bottom}px; left: ${x}px; right: unset; bottom: unset;`
		},

		renderDropdown() {
			if (!this.isDropdownOpen) return null

			let style = this.calculateStyle()
			style += `position: fixed; width: ${DROPDOWN_WIDTH}px; max-width: unset; height: ${DROPDOWN_HEIGHT}px; max-height: unset; overflow: hidden;`
			return (
				<div class='dropdown d-flex flex-column' style={style}>
					<div class='dropdown_filter_container no-shrink'>
						<div class='dropdown_filter_input_container'>
							<Icon name='search' class='dropdown_filter_input_search_icon' size='13' />
							<input
								class='form-control dropdown_filter_input'
								placeholder={this.$t('search')}
								vModel={this.keyword}
								vOn:input={() => (this.page = 1)}
								ref='filter_input'
							/>
							{this.keyword && (
								<Icon
									name='x'
									size='13'
									class='dropdown_filter_input_x_icon'
									vOn:click={() => {
										this.keyword = ''
										this.page = 1
									}}
								/>
							)}
						</div>
					</div>
					<div class='flex__1' style='overflow: auto;' vOn:scroll={this.onScroll}>
						{this.renderItems()}
					</div>
				</div>
			)
		},

		onScroll: lo.throttle(function (e) {
			let list = e.target
			let distToBottom = list.scrollHeight - list.scrollTop - list.clientHeight
			console.log('distToBottom', distToBottom)
			if (distToBottom < 500) this.page = this.page + 1
		}, 800),

		getFilteredEmojis() {
			return lo.filter(this.emojis, (emoji) => {
				if (!this.keyword) return true
				let text = sb.unicodeToAscii(emoji.text).toLowerCase()
				let keyword = sb.unicodeToAscii(this.keyword).toLowerCase()
				return text.indexOf(keyword) >= 0
			})
		},

		renderItems() {
			let emojis = this.getFilteredEmojis()
			emojis = lo.take(emojis, this.page * LIMIT)

			let groups = lo.groupBy(emojis, (emoji) => emoji.category)
			return lo.map(groups, (emojis, groupName) => {
				return (
					<Fragment>
						<div class='text__muted text__semibold text__uppercase ml-3 mt-3 mb-2' style='font-size: 13px'>
							{groupName}
						</div>
						<div class='emoji_picker_dropdown_emojis'>
							{lo.map(emojis, (emoji) => (
								<div
									class='emoji_picker_dropdown_emoji_item'
									title={lo.trim(emoji.text)}
									vOn:click={() => this.selectEmoji(emoji)}
								>
									<Emoji emojiCode={emoji.code} />
								</div>
							))}
						</div>
					</Fragment>
				)
			})
		},

		closeDropdown() {
			this.isDropdownOpen = false
		},

		selectEmoji(emoji) {
			this.$emit('select', emoji.code)
			this.isDropdownOpen = false
		},
	},

	render() {
		return (
			<div class='emoji_picker_wrapper' v-clickaway={this.closeDropdown}>
				<div class={'emoji_picker_trigger ' + this.extra_cls} ref='trigger' vOn:click={this.toggleDropdown}>
					{this.selected ? (
						<Emoji emojiCode={this.selected} sm={this.sm} md={this.md} lg={this.lg} />
					) : (
						<span>{this.$t('choose_emoji')}</span>
					)}
				</div>
				{this.renderDropdown()}
			</div>
		)
	},
}
