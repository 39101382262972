import React from 'react'
import {Image as Img} from 'react-native'

// examples:
// <Image source={require('../assets/images/logo.png')} />
// <Image source={{uri: 'https://reactnative.dev/img/tiny_logo.png'}} />
// <Image style={{width: 20, height: 30}} source={{uri: 'https://reactnative.dev/img/tiny_logo.png'}} />
export function Image({...rest}) {
	return <Img {...rest} />
}
