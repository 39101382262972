const sb = require('@sb/util')
var api = require('./api.js')
const flow = require('@subiz/flow')
import InMemKV from './inmem_kv.js'
import NewObjectStore from './object_store.js'
const config = require('@sb/config')

// mirror at '../src/campaign/outbound_call_entry_helpers.js'
function getEntryId(entry) {
	return `${entry.campaign_id}%${entry.user_id}`
}

function CallEntryStore(realtime, pubsub) {
	let kv = new InMemKV()
	kv.init()

	let me = {}
	realtime.onEvent(async (ev) => {
		if (!ev || !ev.type) return
		switch (ev.type) {
			case 'outbound_call_entry_updated':
				var newentry = lo.get(ev, 'data.outbound_call_entry', {})
				if (!newentry.user_id) return
				if (!newentry.campaign_id) return
				let id = getEntryId(newentry)
				if (!entrydb.has(id)) return
				var entry = entrydb.match(id) // remember to merge with old fields
				entry = Object.assign(entry, newentry)
				pubsub.publish('outbound_call_entry', entry)
				return entrydb.put(id, entry)

			// tell client to update
			case 'outbound_call_entry_deleted':
				entrydb.del(lo.get(ev, 'data.entry.user_id'))
				pubsub.publish('outbound_call_entry', lo.get(ev, 'data.entry', {}))
				return
			// tell client to update

			case 'outbound_call_updated':
				let reportData = lo.get(ev, 'data.outbound_call_update')
				pubsub.publish('campaign_report', reportData)
				return
		}
	})

	// new topic topic_name.account.${acocunt_id}.campaign.${campaign_id}
	//realtime.subscribe(['outbound_call_entry_updated', 'outbound_call_entry_deleted']) // ignore result

	me.subscribeOutboundCallTopics = (topics) => {
		realtime.subscribe(topics)
	}

	me.matchOutboundCallEntry = (id) => {
		if (!id || id === '-') return undefined
		return entrydb.match(id)
	}

	let entrydb = NewObjectStore(
		api.getAccountId(),
		realtime,
		pubsub,
		'call_entry',
		async (entries) => {
			let ids = lo.map(entries, 'id')
			let campgId = ''
			let userids = []
			lo.each(ids, (id) => {
				let campUserId = id.split('%')
				userids.push(campUserId[1])
				campgId = campUserId[0]
			})

			let last_modifieds = lo.map(entries, (entry) => entry.updated || 0)
			let {body, code, error} = await api.list_outbound_call_entries({
				user_ids: userids,
				last_modifieds,
				campaign_id: campgId,
			})
			if (error) return {error}
			let out = lo.get(body, 'entries', [])

			last_modifieds = lo.map(out, (entry) => entry.updated || 0)
			ids = lo.map(out, 'id')
			var ps = []
			lo.map(out, (entry) => {
				let i = lo.findIndex(ids, (id) => entry.id == id)
				if (i == -1) return
				if (lo.size(entry) < 2) delete entry.id // no updates, must delete id to tell object store
				ps[i] = entry
			})

			return {data: ps}
		},
		[],
		true,
	)

	me.listOutboundCallEntries = async (query) => {
		let out = await api.list_outbound_call_entries(query)
		return out
	}

	me.fetchOutboundCallEntries = (ids, force) => entrydb.fetch(ids, force)
	me.updateOutboundCallEntry = async (entry) => {
		var {body, error} = await api.update_outbound_call_entry(entry)
		if (error) return {error}
		//if (!body || !body.id) return {error: 'invalid user id'}
		let newentry = body
		await entrydb.put(newentry.id, newentry)
		pubsub.publish('outbound_call_entry', newentry)
		return newentry
	}
	me.getOutboundCallEntryDetail = api.get_outbound_call_entry_detail
	me.getNextCall = api.get_next_call
	me.reportOutboundCallEntry = api.report_outbound_call_entries
	me.importCallEntries = api.import_call_entries
	me.deleteCallEntry = api.delete_call_entry
	me.reassignCallEntries = api.reassign_call_entries

	me.onCallEntry = (o, cb) => pubsub.on2(o, 'outbound_call_entry', cb)
	me.onCampaignReport = (o, cb) => pubsub.on2(o, 'campaign_report', cb)
	return me
}

export default CallEntryStore
