let GLOBAL_OBJ = {}
if (typeof window !== 'undefined') GLOBAL_OBJ = window
const CONSOLE_LEVELS = ['debug', 'info', 'warn', 'error', 'log', 'assert', 'trace']

if (typeof window !== 'undefined') window.internalLogs = window.internalLogs || ['thanh']

const handlers = {}
const instrumented = {}
const originalConsoleMethods = {}
const SENTRY_XHR_DATA_KEY = '__sentry_xhr_v2__'

/**
 * Add handler that will be called when given type of instrumentation triggers.
 * Use at your own risk, this might break without changelog notice, only used internally.
 * @hidden
 */
export function addInstrumentationHandler(type, callback) {
	handlers[type] = handlers[type] || []
	handlers[type].push(callback)

	if (instrumented[type]) return

	instrumented[type] = true
	if (type == 'console') instrumentConsole()
	if (type == 'xhr') instrumentXHR()
	if (type == 'fetch') {
	} //instrumentFetch();
	if (type == 'history') {
	} //instrumentHistory();
	if (type == 'error') instrumentError()
	if (type == 'unhandledrejection') {
	} //instrumentUnhandledRejection();
}

/**
 * Reset all instrumentation handlers.
 * This can be used by tests to ensure we have a clean slate of instrumentation handlers.
 */
export function resetInstrumentationHandlers() {
	Object.keys(handlers).forEach((key) => {
		handlers[key] = undefined
	})
}

function instrumentConsole() {
	if (!('console' in GLOBAL_OBJ)) return
	CONSOLE_LEVELS.forEach(function (level) {
		if (!(level in GLOBAL_OBJ.console)) return

		fill(GLOBAL_OBJ.console, level, function (originalConsoleMethod) {
			originalConsoleMethods[level] = originalConsoleMethod
			return function (...args) {
				triggerHandlers('console', {args, level})
				const originalLogger = originalConsoleMethods[level]
				originalLogger && originalLogger.apply(GLOBAL_OBJ.console, args)
			}
		})
	})
}

export function instrumentXHR() {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	if (!GLOBAL_OBJ.XMLHttpRequest) {
		return
	}

	const xhrproto = XMLHttpRequest.prototype

	fill(xhrproto, 'open', function (originalOpen) {
		return function (...args) {
			const url = args[1]
			const xhrInfo = (this[SENTRY_XHR_DATA_KEY] = {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				method: isString(args[0]) ? args[0].toUpperCase() : args[0],
				url: args[1],
				request_headers: {},
			})

			// if Sentry key appears in URL, don't capture it as a request
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			if (isString(url) && xhrInfo.method === 'POST' && url.match(/sentry_key/)) {
				this.__sentry_own_request__ = true
			}

			const onreadystatechangeHandler = () => {
				// For whatever reason, this is not the same instance here as from the outer method
				const xhrInfo = this[SENTRY_XHR_DATA_KEY]

				if (!xhrInfo) {
					return
				}

				if (this.readyState === 4) {
					try {
						// touching statusCode in some platforms throws
						// an exception
						xhrInfo.status_code = this.status
					} catch (e) {
						/* do nothing */
					}

					triggerHandlers('xhr', {
						args: args,
						endTimestamp: Date.now(),
						startTimestamp: Date.now(),
						xhr: this,
					})
				}
			}

			if ('onreadystatechange' in this && typeof this.onreadystatechange === 'function') {
				fill(this, 'onreadystatechange', function (original) {
					return function (...readyStateArgs) {
						onreadystatechangeHandler()
						return original.apply(this, readyStateArgs)
					}
				})
			} else {
				this.addEventListener('readystatechange', onreadystatechangeHandler)
			}

			// Intercepting `setRequestHeader` to access the request headers of XHR instance.
			// This will only work for user/library defined headers, not for the default/browser-assigned headers.
			// Request cookies are also unavailable for XHR, as `Cookie` header can't be defined by `setRequestHeader`.
			fill(this, 'setRequestHeader', function (original) {
				return function (...setRequestHeaderArgs) {
					const [header, value] = setRequestHeaderArgs

					const xhrInfo = this[SENTRY_XHR_DATA_KEY]

					if (xhrInfo) {
						xhrInfo.request_headers[header.toLowerCase()] = value
					}

					return original.apply(this, setRequestHeaderArgs)
				}
			})

			return originalOpen.apply(this, args)
		}
	})

	fill(xhrproto, 'send', function (originalSend) {
		return function (...args) {
			const sentryXhrData = this[SENTRY_XHR_DATA_KEY]
			if (sentryXhrData && args[0] !== undefined) {
				sentryXhrData.body = args[0]
			}

			triggerHandlers('xhr', {
				args,
				startTimestamp: Date.now(),
				xhr: this,
			})

			return originalSend.apply(this, args)
		}
	})
}

let _oldOnErrorHandler = null
function instrumentError() {
	_oldOnErrorHandler = GLOBAL_OBJ.onerror

	GLOBAL_OBJ.onerror = function (msg, url, line, column, error) {
		triggerHandlers('error', {
			column,
			error,
			line,
			msg,
			url,
		})

		if (_oldOnErrorHandler) {
			// eslint-disable-next-line prefer-rest-params
			return _oldOnErrorHandler.apply(this, arguments)
		}

		return false
	}

	GLOBAL_OBJ.onerror.__SENTRY_INSTRUMENTED__ = true
}

function triggerHandlers(type, data) {
	if (!type || !handlers[type]) return

	for (const handler of handlers[type] || []) {
		try {
			handler(data)
		} catch (e) {}
	}
}

function isString(wat) {
	return typeof wat === 'string'
}

/**
 * Replace a method in an object with a wrapped version of itself.
 *
 * @param source An object that contains a method to be wrapped.
 * @param name The name of the method to be wrapped.
 * @param replacementFactory A higher-order function that takes the original version of the given method and returns a
 * wrapped version. Note: The function returned by `replacementFactory` needs to be a non-arrow function, in order to
 * preserve the correct value of `this`, and the original method must be called using `origMethod.call(this, <other
 * args>)` or `origMethod.apply(this, [<other args>])` (rather than being called directly), again to preserve `this`.
 * @returns void
 */
export function fill(source, name, replacementFactory) {
	if (!(name in source)) return

	const original = source[name]
	const wrapped = replacementFactory(original)

	// Make sure it's a function first, as we need to attach an empty prototype for `defineProperties` to work
	// otherwise it'll throw "TypeError: Object.defineProperties called on non-object"
	if (typeof wrapped === 'function') {
		markFunctionWrapped(wrapped, original)
	}

	source[name] = wrapped
}

/**
 * Remembers the original function on the wrapped function and
 * patches up the prototype.
 *
 * @param wrapped the wrapper function
 * @param original the original function that gets wrapped
 */
export function markFunctionWrapped(wrapped, original) {
	try {
		const proto = original.prototype || {}
		wrapped.prototype = original.prototype = proto
		addNonEnumerableProperty(wrapped, '__sentry_original__', original)
	} catch (o_O) {} // eslint-disable-line no-empty
}

/**
 * Defines a non-enumerable property on the given object.
 *
 * @param obj The object on which to set the property
 * @param name The name of the property to be set
 * @param value The value to which to set the property
 */
export function addNonEnumerableProperty(obj, name, value) {
	try {
		Object.defineProperty(obj, name, {
			// enumerable: false, // the default, so we can save on bundle size by not explicitly setting it
			value: value,
			writable: true,
			configurable: true,
		})
	} catch (o_O) {}
}
