import React from 'react'
import {
	IconAlertTriangle,
	IconLoader,
	IconAt,
	IconHelpSquare,
	IconArrowLeft,
	IconUserCircle,
	IconHelp,
	IconTrash,
	IconLock,
	IconGlobe,
	IconUser,
	IconPhone,
	IconWorld,
	IconCircleCheckFilled,
	IconCircleCheck,
	IconAlertCircle,
	IconAlertCircleFilled,
	IconListDetails,
  IconFaceIdError,
} from '@tabler/icons-react-native'
import {space} from '@/base'
export function Icon({size, filled, strokeWidth, ...rest}) {
	let thesize = space.lg
	let thestrokeWidth = filled ? 0 : 2
	if (strokeWidth !== undefined) thestrokeWidth = strokeWidth
	if (size == 'md') thesize = space.md
	if (size == 'lg') thesize = space.lg
	if (size == 'xl') thesize = space.xl
	if (typeof size === 'number') thesize = size
	let props = {...rest}

	props.size = thesize
	props.strokeWidth = thestrokeWidth
	switch (props.name) {
		case 'list-details':
			return <IconListDetails {...props} />
		case 'circle-check':
			if (filled) return <IconCircleCheckFilled {...props} />
			return <IconCircleCheck {...props} />

		case 'alert-circle':
		case 'circle-alert':
			if (filled) return <IconAlertCircleFilled {...props} />
			return <IconAlertCircle {...props} />

		case 'at':
			return <IconAt {...props} />
		case 'alert-triangle':
			return <IconAlertTriangle {...props} />
		case 'loader':
			return <IconLoader {...props} />
		case 'user-circle':
			return <IconUserCircle {...props} />
		case 'arrow-left':
			return <IconArrowLeft {...props} />
		case 'help-square':
			return <IconHelpSquare {...props} />
		case 'help':
			return <IconHelp {...props} />
		case 'trash':
			return <IconTrash {...props} />
		case 'lock':
			return <IconLock {...props} />
		case 'phone':
			return <IconPhone {...props} />
		case 'globe':
			return <IconGlobe {...props} />
		case 'world':
			return <IconWorld {...props} />
		case 'user':
			return <IconUser {...props} />
		case 'circle-check-filled':
			return <IconCircleCheckFilled {...props} />
		case 'circle-check':
			return <IconCircleCheck {...props} />
		case 'face-id-error':
			return <IconFaceIdError {...props} />
		default:
			return <IconHelpSquare {...props} />
	}
}
