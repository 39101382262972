const sb = require('@sb/util')
const flow = require('@subiz/flow')
import InMemKV from './inmem_kv.js'
var api = require('./api.js')

// parent {api, realtime, insync, pubsub}
// like kv store, but support match and pubsub
// require api:
//   api.update_{name}
//   api.create_{name}
//   api.match_{name}
//   api.list_{name}
//   api.delete_{name}
// require realtime
//   type {name}_updated // lowercase
//   type {name}_deleted

// name should contains - (rather than _) for  example: ai-data-entry, ticket-view
function NewStandardStore(realtime, pubsub, name, conf) {
	conf = conf || {}
	let idkey = conf.id_key || 'id'
	let modifiedkey = conf.modified_key || 'modified'

	let snackName = sb.snackCase(name)
	let pluralName = sb.snackCase(sb.plural(name))
	let capitalName = sb.snakeCaseToCapitalCamelCase(snackName)
	let kv = new InMemKV()
	kv.init()

	// expires time in ms
	// must re-fetch data if expires
	let expires = {}

	let isExpired = (key) => (expires[key] || 0) < Date.now()

	var me = {}

	let updateTopic = snackName + '_updated'
	let createTopic = snackName + '_created'
	let removeTopic = snackName + '_deleted'
	let topics = [updateTopic, createTopic, removeTopic]
  // We dont want subscribe user and convo because it will response too much
  // Temporary use this login in FE
	if (lo.size(topics) && !['user', 'convo', 'conversation'].includes(name)) realtime.subscribe(topics)

	realtime.onEvent((ev) => {
		if (ev.type != updateTopic && ev.type != createTopic && ev.type != removeTopic) return
		let obj = lo.get(ev, ['data', name])
		if (!obj) return

		if (ev.type == updateTopic || ev.type == createTopic) return me.put(obj)
		if (ev.type === removeTopic) {
			let id = lo.get(ev, ['data', snackName, 'id'])
			kv.del(id)
			pubsub.publish(name, {id: id, status: 'deleted', deleted: 1})
		}
	})
	realtime.onInterrupted(() => {
		syncStatus = 'offline'
	})

	let _isAllRecord = false
	let list_cache = {}
	let list_lock = false

	me.list = async (params, force) => {
		if (!params) params = {}
		let useCacheAndLock = !force && !lo.size(params)

		if (useCacheAndLock) {
			if (list_lock) {
				await sb.sleep(100)
				return await me.list(params, force)
			}

			list_lock = true
			if (_isAllRecord) {
				list_lock = false
				return list_cache
			}
		}

		// if (!force && syncStatus === 'online') return kv.match(name)

		let anchor = params.anchor || ''
		let limit = params.limit || 100
		let res = await api['list_' + snackName](params)
		list_lock = false
		syncStatus = res.error ? 'offline' : 'online'
		if (res.error) {
			_isAllRecord = false
			return res
		}

		res.body.items = res.body[pluralName]
		_isAllRecord = !lo.size(params) && lo.get(res, 'body.is_all')

		lo.map(res.body.items, (item) => {
			kv.put(item)
		})
		let out = Object.assign({}, res.body)
		out.body = res.body
		list_cache = out
		return out // backward compatible
		// delete (res.body, pluralName)
		// return res.body
	}

	me.put = (key, value) => {
		let ret = kv.put(key, value)
		if (!Array.isArray(ret)) ret = [ret]
		let itemM = {}
		lo.map(ret, (item) => {
			if (item && item[idkey]) itemM[item[idkey]] = item
		})
		if (lo.size(itemM) === 0) return
		if (me.onchange) me.onchange(itemM)
		if (me.beforePublish) pubsub.publish(name, me.beforePublish(itemM))
		else pubsub.publish(name, itemM)
		lo.map(itemM, (item) => pubsub.publish(name, item))
	}

	me.has = (key) => {
		if (!key) return false
		return !!kv.match(key)
	}

	var _lastRead = {}
	setTimeout(async () => {
		for (;;) {
			await sb.sleep(5000)
			for (let key in _lastRead) if (isExpired(key)) fetchQueue.push(key)
			_lastRead = {}
		}
	})

	// update an object, return the object updated, if error return {error: ...}
	me.update = async (params, fields) => {
		let res = await api['update_' + snackName](params, fields)
		if (res.error) return res.body

		let obj = lo.get(res.body, snackName)
		if (!obj) obj = lo.get(res, 'body')
		await me.put(obj)
		pubsub.publish('standard_object', {
			action: 'update',
			object_type: name,
			data: obj,
		})
		return obj
	}

	// create an object, return the object updated, if error return {error: ...}
	me.create = async (params) => {
		let res = await api['create_' + snackName](params)
		if (res.error) return res
		let obj = lo.get(res.body, snackName)
		// create ticket endpoint still return { id: ... }, should wrap { ticket: { id: ..... } }
		if (!obj) obj = lo.get(res, 'body')
		await me.put(obj)
		pubsub.publish('standard_object', {
			action: 'create',
			object_type: name,
			data: obj,
		})
		return obj
	}

	me.match = (key) => {
		if (syncStatus !== 'online') listQueue.push()
		if (!key || key == '*') return kv.all()
		_lastRead[key] = true
		return kv.match(key)
	}

	me.fetch = async (keys, force) => {
		if (!keys) return {}
		if (typeof keys === 'string') keys = [keys]
		if (!Array.isArray(keys)) throw new Error('keys must be an array or a string')
		let unsyncids = lo.filter(keys, (k) => !!k)
		if (!force) unsyncids = unsyncids.filter((k) => isExpired(k))
		else {
			unsyncids.map((k) => {
				expires[k] = 0
			})
		}
		let lastid = unsyncids.pop()
		lo.map(unsyncids, (id) => fetchQueue.push(id))
		if (lastid) await fetchQueue.push(lastid)
		return lo.map(keys, (k) => kv.match(k))
	}

	me.delete = async (id) => {
		let {error: err, body, code} = await api['delete_' + snackName](id)
		if (err) return {error: err, code, body}
		kv.del(id)
		pubsub.publish(snackName + '_deleted', {id: id})
		pubsub.publish('standard_object', {
			action: 'delete',
			object_type: name,
			data: {id},
		})
		return {error: err, body}
	}

	let matcher = async (items) => {
		let ids = lo.map(items, idkey)
		let last_modifieds = lo.map(items, (item) => item[modifiedkey] || 0)
		let {body, code, error} = await api['match_' + snackName](ids, last_modifieds)
		if (error || code !== 200) return {error}
		let out = lo.get(body, pluralName, [])

		let os = []
		// reorder
		lo.map(out, (item) => {
			let i = lo.findIndex(ids, (id) => item[idkey] == id)
			if (i == -1) return
			if (lo.size(item) < 2) delete item[idkey] // no updates, must delete id to tell object store
			os[i] = item
		})

		return os
	}

	let syncStatus = 'offline' // 'online'
	let restinterval = 2000 // 60 sec
	let listQueue = new flow.batch(2000, restinterval, async () => {
		await me.list()
		return []
	})

	let fetchQueue = new flow.batch(100, 1, async (ids) => {
		let suberr
		if (lo.size(topics) > 0) {
			let {error} = await realtime.subscribe(topics)
			suberr = error
		}
		// ids = ids.filter((key) => key.startsWith('cs'))
		ids = lo.uniq(ids)
		// filter items that must be fetched
		ids = ids.filter((id, i) => {
			if (!id) return false
			if (!isExpired(id)) return false
			return true
		})
		let items = ids.map((id) => {
			let item = kv.match(id) || {}
			item[idkey] = id
			return item
		})

		if (items.length === 0) return []

		let newitems = await matcher(items)
		if (newitems.error) {
			console.log('ERR', newitems.error)
			return []
		}

		newitems = newitems || {}
		let itemM = {}
		ids.map((id, i) => {
			let newitem = newitems[i] || {}
			if (newitem[idkey]) itemM[id] = newitem // server does return new data
		})

		// add 1 more expire days if subscribe success
		if (!suberr) {
			ids.map((id) => {
				expires[id] = Date.now() + 120000 // 2 mins
			})
		}

		kv.put(lo.map(itemM))
		ids.map((id) => {
			expires[id] = Date.now() + 30000 // should retry in 30 sec
		})
		if (me.onchange) me.onchange(itemM)
		if (me.beforePublish) pubsub.publish(snackName, me.beforePublish(itemM))
		pubsub.publish(snackName, itemM)
		lo.map(itemM, (item) => pubsub.publish(name + '.' + item[idkey], item))
	})

	return me
}

export default NewStandardStore
