// Register global component, so we dont have to import it manually

import {
	ActivityIndicator,
	Platform,
	View,
	StatusBar,
	FlatList,
	AppState,
	AppRegistry,
	NativeModules,
	Modal,
	TouchableOpacity,
	Linking,
} from 'react-native'

import {Link, H1, H2, H3, H4, H5, H6, Text, LabelText} from '@/base'
window.__DEV__ = true
// Global components
window.View = View
window.ActivityIndicator = ActivityIndicator
window.StatusBar = StatusBar
window.H1 = H1
window.H2 = H2
window.H3 = H3
window.H4 = H4
window.H5 = H5
window.H6 = H6
window.LabelText = LabelText
// window.Image = Image
window.Text = Text
window.Link = Link
// window.Icon = com.Icon
// window.Button = com.Button
window.FlatList = FlatList
// window.TextInput = com.TextInput
// window.KeyboardAvoid = com.KeyboardAvoid
