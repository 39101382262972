var {randomString} = require('./common.js')

const DELI = '-[/]-'

class Pubsub {
	constructor() {
		this.listeners = []
		this.destroyed = false
	}

	destroy() {
		this.destroyed = true
	}

	publish(topic, payload, payload2, payload3) {
		if (this.destroyed) return
		lo.map(this.listeners[topic], (cb) => cb && cb(payload, payload2, payload3))
	}

	only(topic, cb) {
		if (this.destroyed) return
		var subid = topic + DELI + randomString(20)
		if (!this.listeners[topic]) this.listeners[topic] = {}
		this.listeners[topic] = {subid: cb}
		return subid
	}

	count(topic) {
		return lo.size(this.listeners[topic])
	}

	on2(obj, topic, cb) {
		if (this.destroyed) return () => {}
		if (!this.listeners[topic]) this.listeners[topic] = {}
		let subid = obj && obj._uid //vue
		if (!subid) subid = randomString(16) //react class or hook
		this.listeners[topic][subid] = cb
		let cleanF = () => {
			delete this.listeners[topic][subid]
		}
		//vue
		if (obj && obj.$once) obj.$once('hook:beforeDestroy', cleanF)
		//react class
		else if (obj && obj.componentWillUnmount) {
			let old = obj.componentWillUnmount.bind(obj)
			obj.componentWillUnmount = () => {
				old()
				cleanF()
			}
		}

		return cleanF // for react hook
	}

	on3(obj, topic) {
		if (this.destroyed) return
		if (!this.listeners[topic]) this.listeners[topic] = {}
		let cb = () => obj.$forceUpdate()
		if (this.listeners[topic][obj._uid]) return // already subscribed
		this.listeners[topic][obj._uid] = cb
		obj.$once('hook:beforeDestroy', () => {
			delete this.listeners[topic][obj._uid]
		})
	}

	on(topic, cb) {
		if (this.destroyed) return
		var subid = topic + DELI + randomString(20)
		if (!this.listeners[topic]) this.listeners[topic] = {}
		this.listeners[topic][subid] = cb
		return subid
	}

	un(subid) {
		if (this.destroyed) return
		if (!subid) return
		var topic = subid.split(DELI)[0]
		if (!this.listeners[topic]) return
		delete this.listeners[topic][subid]
	}
}

module.exports = Pubsub
