const sb = require('@sb/util')
import Avatar from './avatar.js'

export default {
	name: 'avatar-group',
	props: ['users', 'size', 'bys', 'agents', 'md', 'xxs', 'xs', 'sm', 'lg', 'expanded', 'rounded', 'tooltip_suffix'],
	methods: {
		isAgent(obj) {
			if (obj.avatar_url) return true
			return obj.id && obj.type
		},

		renderItemsGroup(items, mode, size = 3) {
			return lo.map(items, (item, i) => {
				if (i > size - 1) return null
				if (i === size - 1 && lo.size(items) > size) return null
				let $class = 'avatar_group__item'
				if (!this.expanded) $class += ' avatar_group__item_overlap avatar_group__item_overlap' + '__' + mode
				if (this.rounded) $class += ' rounded'
				if (this.isAgent(item)) {
					return (
						<Avatar
							class={$class}
							agent={item}
							size={mode}
							rounded={this.rounded}
							tooltip_suffix={this.tooltip_suffix}
						/>
					)
				}
				return (
					<Avatar class={$class} user={item} size={mode} rounded={this.rounded} tooltip_suffix={this.tooltip_suffix} />
				)
			})
		},

		renderBysGroup(bys, mode, size = 3) {
			return lo.map(bys, (by, i) => {
				if (i > size - 1) return null
				if (i === size - 1 && lo.size(bys) > size) return null
				let $class = 'avatar_group__item'
				if (!this.expanded) $class += ' avatar_group__item_overlap avatar_group__item_overlap' + '__' + mode
				if (this.rounded) $class += ' rounded'
				return <Avatar class={$class} by={by} size={mode} rounded={this.rounded} tooltip_suffix={this.tooltip_suffix} />
			})
		},

		renderCollapse(items, mode, size = 3) {
			let $collapse = null
			if (lo.size(items) > size) {
				let style = `width: ${mode}px; height: ${mode}px; line-height: ${mode}px;`
				if (this.rounded) style += 'border-radius: 50%; box-shadow: none;'
				if (mode === 20) style += 'border: none; background: #e5e5e5'
				$collapse = (
					<div class={'avatar_group__item_collapse avatar_group__item_collapse__' + mode} style={style}>
						<span>+{lo.size(items) - size + 1}</span>
					</div>
				)
			}
			return $collapse
		},
	},

	render() {
		let mode = lo.find(['md', 'xxs', 'xs', 'sm', 'lg'], (m) => this.$props[m])
		switch (mode) {
			case 'xxs':
				mode = 16
				break
			case 'xs':
				mode = 20
				break
			case 'sm':
				mode = 24
				break
			case 'md':
				mode = 40
				break
			case 'lg':
				mode = 60
				break
		}
		let agents = lo.filter(this.agents, (agent) => agent)
		let users = lo.map(this.users, (user) => user)

		let items = lo.concat(users, agents)
		items = [].concat(items).reverse()

		let $items = []
		if (this.bys) $items = this.renderBysGroup(this.bys, mode, this.size)
		else $items = this.renderItemsGroup(items, mode, this.size)

		return (
			<div class='avatar_group'>
				<div class='avatar_group__items'>{$items}</div>
				{this.renderCollapse(items, mode, this.size)}
			</div>
		)
	},
}
