
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: ["icon icon-tabler icons-tabler-filled icon-tabler-message-chatbot",classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"currentColor"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M18 3a4 4 0 014 4v8a4 4 0 01-4 4h-4.724l-4.762 2.857a1 1 0 01-1.508-.743L7 21v-2H6a4 4 0 01-3.995-3.8L2 15V7a4 4 0 014-4zm-2.8 9.286a1 1 0 00-1.414.014 2.5 2.5 0 01-3.572 0 1 1 0 00-1.428 1.4 4.5 4.5 0 006.428 0 1 1 0 00-.014-1.414M9.51 8H9.5a1 1 0 100 2h.01a1 1 0 000-2m5 0h-.01a1 1 0 000 2h.01a1 1 0 000-2"}})])
          )
        }
      }
    