import store from '@sb/store'
import {getAttributeName, UNUSED_USER_ATTRIBUTES} from '../common.js'
const sb = require('@sb/util')
import {format} from 'date-fns'
import UserMoreInfoModal from './user_more_fields_modal'
import {HoverDropdown} from '@sb/com'
import ZnsTemplateModal from './zns_template_modal.js'
import EditUserPrimaryAttributeModal from './edit_user_primary_attribute_modal.js'

import UserProfileButton from './user_profile_button.js'

export default {
	name: 'user-info',
	props: ['user', 'refresh', 'true_user_id', 'call_entry'],

	data() {
		return {
			showFull: false,
			itemLoading: {},

			banState: 'normal', //wait, confirm
			displayFields: [],
			filterInputValue: '',
			isShowMoreDropdownOpen: false,
			saving: false,
			editAttributes: [],
			mouseHoldTimer: 0,

			isEditLeadOwnersModalOpenned: false,
			inviteAgentLoadings: {},
			isZnsModalOpenned: false,

			isNameEditting: false,

			// custom triggrer hover dropdown
			isEmailCTAOpened: false,
			isPhoneCTAOpened: false,

			detectEmail: '',

			// edit primary attr modal
			editPrimaryAttr: {},
			isEditPrimaryAttrModalOpened: false,

			// true user profile
			isShowTrueUserDetail: false,
		}
	},

	mounted() {
		this.displayFields = lo.get(store.me(), 'dashboard_setting.user_info_fields', [])
		this.fetchZnsTemplates()
	},

	watch: {
		true_user_id(newid, oldid) {
			if (oldid !== newid) {
				this.isShowTrueUserDetail = false
			}
		},
	},

	methods: {
		async fetchZnsTemplates() {
			let res = await store.fetchZnsTemplate()
			this.$forceUpdate()
		},

		renderBan() {
			if (this.banState === 'wait') return <Spinner size='16' static class='ml-4' mode='dark' />

			if (this.banState === 'confirm') {
				return (
					<div class='user_info__clickable user_info__clickable__danger' vOn:click={this.banUser}>
						{this.$t('click_to_comfirm')}
					</div>
				)
			}

			if (sb.isUserBanned(this.user)) {
				return (
					<div class='user_info__clickable text__sm font__sm' vOn:click={this.unbanUser}>
						{this.$t('title_unblock')}
					</div>
				)
			}

			return (
				<div class='user_info__clickable text__sm' vOn:click={this.confirmBan}>
					{this.$t('block_user')}
				</div>
			)
		},

		renderOnline() {
			var seenms = new Date(sb.getUserDateAttr(this.user, 'seen')).getTime()
			let last_message_sent = new Date(sb.getUserDateAttr(this.user, 'last_message_sent')).getTime()
			if (sb.isUserOnline(this.user)) {
				return <div class='user_avatar_online_dot' />
			}

			let cls = 'user_avatar_offline_badge'
			let lastTimeOnline = lo.max([seenms, last_message_sent])
			if (Date.now() - lastTimeOnline > 1 * 3600 * 1000) cls += ' grey' // 1 hours
			return (
				<div class={cls} v-tooltip={this.$t('last_time_online')}>
					<Time time={lastTimeOnline} ago notooltip />
				</div>
			)
		},

		toogleShowFull() {
			this.showFull = !this.showFull
		},

		confirmBan() {
			if (this.banState !== 'normal') return
			this.banState = 'confirm'
			setTimeout(() => {
				if (this.banState === 'confirm') this.banState = 'normal'
			}, 3000)
		},

		async banUser() {
			let cf = await this.$confirm({
				ok: this.$t('blacklist_settings_block'),
				title: this.$t('block_user'),
				description: this.$t('are_you_sure'),
				style: 'danger',
			})
			if (!cf) return
			let {error} = await store.banUser(this.true_user_id)

			if (error) return this.$showError(error)
			this.$showSuccess(this.$t('banned'), 1500)
		},

		async unbanUser() {
			let cf = await this.$confirm({
				ok: this.$t('unblock'),
				title: this.$t('title_unblock'),
				description: this.$t('unblock_user_confirm_message'),
				style: 'primary',
			})
			if (!cf) return
			let {error} = await store.unbanUser(this.true_user_id)

			if (error) return this.$showError(error)
			this.$showSuccess(this.$t('unbanned'), 1500)
		},

		renderDateField(attr) {
			let attrdef = lo.find(store.matchUserAttribute(), (a) => a.key.toLowerCase() === attr.key.toLowerCase()) || {}
			if (attrdef.type !== 'datetime') return null

			let dateFormat = attrdef.is_date_only ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm:ss"
			let date = attr.datetime ? sb.format(new Date(attr.datetime), dateFormat) : ''

			return (
				<input
					class='user_info_date_input'
					type={attrdef.is_date_only ? 'date' : 'datetime-local'}
					value={date}
					vOn:change={(e) => {
						if (!e.target.value) return
						let date = new Date(e.target.value)
						this.saveAttribute(attr, {key: attr.key, datetime: date ? date.toISOString() : ''})
					}}
				/>
			)
		},

		onClickFileInput(attr) {
			if (this.uploading) return
			this.$refs[`${attr.key}_file_input`].click()
		},

		async onUploadImage(e, attr) {
			let file = lo.get(e, 'target.files.0')
			if (!file) return
			if (this.uploading) return
			this.uploading = true
			let res = await store.uploadLocalFile(file)
			this.uploading = false
			if (res.error) {
				this.$showError(res.error)
			}
			this.saveAttribute(attr, {key: attr.key, text: res.url})
		},

		convertListFieldToText(attr) {
			let list = attr.list || []
			let [text, ...remain] = list
			return {
				key: attr.key,
				text: text || '',
				other_values: remain,
			}
		},

		renderTextField(attr) {
			if (attr.type === 'list') attr = this.convertListFieldToText(attr)
			let attrdef = lo.find(store.matchUserAttribute(), (a) => a.key.toLowerCase() === attr.key.toLowerCase()) || {}

			if (attrdef.type !== 'text' && attrdef.type !== 'list') return null

			if (attrdef.is_image) {
				let $img = (
					<div class='input2'>
						<div class='input2__placeholder' vOn:click={(_) => this.onClickFileInput(attr)}>
							{this.$t('add_image')}
						</div>
					</div>
				)
				if (attr.text) {
					$img = (
						<Fragment>
							<img2 src={attr.text} style='width: 22px; height: 22px' clickable />
							<div class='link action link__secondary ml-3 mr-2' vOn:click={(_) => this.onClickFileInput(attr)}>
								<small>{this.$t('change')}</small>
							</div>
							{this.uploading && <Spinner size='12' mode='dark' />}
						</Fragment>
					)
				}
				return (
					<div class='d-flex align-items-center'>
						{$img}
						<input
							type='file'
							ref={`${attr.key}_file_input`}
							style='display: none'
							vOn:click={(e) => (e.target.value = '')}
							vOn:change={(e) => this.onUploadImage(e, attr)}
						/>
					</div>
				)
			}

			if (attrdef.select === 'checkbox') {
				let values = attr.text ? lo.split(attr.text, ',') : []

				let missingItems = []
				lo.filter(values, (val) => {
					let found = lo.find(attrdef.items, (item) => val == item.value)
					if (!found) {
						missingItems.push({value: val, label: val})
					}
				})

				let itemcls = 'form-check user_attr_cb_wrapper grid-2'
				let $content = lo.map(missingItems.concat(attrdef.items), (item) => {
					return (
						<div class={itemcls}>
							<input
								type='checkbox'
								id={`${attr.key}_checkbox_${item.value}`}
								class='form-check-input form-check-input--light'
								name={`${attr.key}_checkbox`}
								checked={lo.includes(values, item.value)}
								vOn:change={(e) => this.onToggleCheckbox(attr, item.value)}
							/>
							<label
								title={item.label}
								class='form-check-label text__truncate'
								for={`${attr.key}_checkbox_${item.value}`}
							>
								{item.label}
							</label>
						</div>
					)
				})

				return <div class='d-flex flex-wrap'>{$content}</div>
			}

			if (attrdef.select === 'dropdown' || attrdef.select === 'radio') {
				let items = lo.map(attrdef.items, (item) => ({id: item.value, label: item.label}))
				if (attr.text) items.unshift({id: '', label: this.$t('unselect'), extra_cls: 'text__italic'})

				let cls = 'dropdown_input__small dropdown_input__no_border'
				if (attr.key === 'lifecycle_stage') {
					cls += ' text__uppercase text__xs text__semibold'
					if (attr.text === 'customer') cls += ' dropdown_input__success'
					else if (attr.text) cls += ' dropdown_input__light dropdown_input__primary'
				} else {
					cls += ' form-control form-control__light'
				}
				return (
					<Dropdown2
						items={items}
						mode='input'
						extra_cls={cls}
						dropdown_width={170}
						selected={attr.text}
						placeholder={this.$t('select')}
						vOn:select={(item) => this.saveAttribute(attr, {key: attr.key, type: 'text', text: item.id})}
					/>
				)
			}

			if (attrdef.multiple_line) {
				return (
					<Input2
						type='textarea'
						value={attr.text}
						vOn:change={(newval) => this.saveAttribute(attr, {key: attr.key, type: 'text', text: newval})}
						loading={attr.saving}
					/>
				)
			}

			return (
				<Input2
					value={attr.text}
					vOn:change={(newval) => this.saveAttribute(attr, {key: attr.key, type: 'text', text: newval})}
					loading={attr.saving}
				/>
			)
		},

		onToggleCheckbox(attr, value) {
			let values = attr.text ? lo.split(attr.text, ',') : []
			if (values.includes(value)) {
				values = lo.filter(values, (v) => v !== value)
			} else {
				values.push(value)
			}

			this.saveAttribute(attr, {key: attr.key, type: 'text', text: values.join(',')})
		},

		async saveTrueUserAttribute(attr, newattr) {
			attr.saving = true
			this.$forceUpdate()
			if (!newattr.text) {
				newattr = lo.cloneDeep(newattr)
				newattr.action = 'delete'
			}
			let {error} = await store.updateUser({id: this.true_user_id, attributes: [newattr]})
			attr.saving = false
			this.$forceUpdate()

			if (error) this.$showError(error)
		},

		async saveAttribute(attr, newattr) {
			attr.saving = true
			this.$forceUpdate()
			newattr = lo.cloneDeep(newattr)
			newattr.text = lo.trim(newattr.text)
			if (newattr.type === 'text' && !newattr.text) {
				newattr.action = 'delete'
			}
			let {error} = await store.updateUser({id: this.user.id, attributes: [newattr]})
			attr.saving = false
			this.$forceUpdate()

			if (error) this.$showError(error)
		},

		renderNumberField(attr) {
			let attributeM = store.matchUserAttribute()
			let type = lo.get(attributeM, [attr.key.toLowerCase(), 'type'])
			if (type !== 'number') return null
			return (
				<Input2
					value={attr.number}
					vOn:change={(newval) => this.saveAttribute(attr, {key: attr.key, number: parseFloat(newval)})}
					easy
					type='number'
					loading={attr.saving}
				/>
			)
		},

		renderBooleanField(attr) {
			let attributeM = store.matchUserAttribute()
			let type = lo.get(attributeM, [attr.key.toLowerCase(), 'type'])
			if (type !== 'boolean') return null

			let val = attr.boolean
			return (
				<Sw green checked={val} vOn:change={(checked) => this.saveAttribute(attr, {key: attr.key, boolean: checked})} />
			)
		},

		renderTotalOrdersField() {
			return null // temporary hide field, because noone use sale and order
			let attributes = lo.get(this.user, 'attributes', [])
			let sales = lo.find(attributes, (uattr) => uattr.key === 'total_order_value') || {}
			sales = sales.number || 0
			let orders = lo.find(attributes, (uattr) => uattr.key === 'num_orders') || {}
			orders = orders.number || 0

			if (!orders && !sales) return null
			return (
				<div class='mt-4 ml-4 mr-4'>
					<div class='text__semibold mb-2'>{this.$t('total_orders')}</div>
					{sales && orders ? (
						<div>
							{sb.displayMoney(sales, lo.get(store.me(), 'account.currency'))} ({orders}{' '}
							{this.$t('orders').toLowerCase()})
						</div>
					) : (
						<div class='text__muted'>{this.$t('no_orders')}</div>
					)}
				</div>
			)
		},

		async inviteLeadOwnersAgent(agentid) {
			this.inviteAgentLoadings[agentid] = true
			this.$forceUpdate()
			let agentids = lo.get(this.user, 'lead_owners', [])
			agentids.push(agentid)
			await store.updateUser({...this.user, lead_owners: agentids})
			this.inviteAgentLoadings[agentid] = false
			this.$forceUpdate()
		},

		setToMainAssignee(agentid) {
			this.saveAttribute({key: 'owner'}, {key: 'owner', text: agentid})
		},

		renderLeadOwenersEditModal() {
			let cls = 'modal'
			if (!this.isEditLeadOwnersModalOpenned) cls += ' modal__hide'

			let agentids = lo.get(this.user, 'lead_owners', [])
			let agents = lo.map(agentids, (id) => store.matchAgent()[id] || {})
			agents = lo.filter(agents, (agent) => agent.type === 'agent')

			let mainOwner = sb.getUserTextAttr(this.user, 'owner')
			let $agents = lo.map(agents, (agent) => {
				return (
					<div class='assign_agent__member'>
						<Avatar class='assign_agent__member_avatar' agent={agent} size='md' nodot />
						<div class='assign_agent__member_info flex__1'>
							<div class='assign_agent__member_name'>{sb.getAgentDisplayName(agent, store.me())}</div>
							<div class='assign_agent__member_email'>{agent.email}</div>
						</div>
						{mainOwner === agent.id ? (
							<Icon
								name='star'
								stroke-width='2'
								size='16'
								class='ml-2 primary_star_icon active'
								v-tooltip={this.$t('main_assignee')}
							/>
						) : (
							<Icon
								name='star'
								stroke-width='2'
								size='16'
								class='ml-2 primary_star_icon'
								v-tooltip={this.$t('set_as_main_assignee')}
								vOn:click={() => this.setToMainAssignee(agent.id)}
							/>
						)}
					</div>
				)
			})
			let uninvitedAgents = lo.filter(
				store.matchAgent(),
				(agent) => !agentids.includes(agent.id) && agent.state === 'active',
			)
			uninvitedAgents = lo.filter(uninvitedAgents, (ag) => ag.type === 'agent')
			let $invite = (
				<div class='mt-4'>
					<div class='text__muted mb-3 text__semibold'>{this.$t('invite_more_agents')}</div>
					{lo.map(uninvitedAgents, (agent) => {
						let $inviteAgentBtn = (
							<button
								class='btn btn__sm btn__primary'
								vOn:click={(_) => this.inviteLeadOwnersAgent(agent.id)}
								style='width: 72px'
							>
								<Icon name='plus' stroke-width={2} size='16' /> {this.$t('add')}
							</button>
						)
						if (this.inviteAgentLoadings[agent.id]) {
							$inviteAgentBtn = (
								<button class='btn btn__sm btn__primary' disabled style='width: 72px'>
									<Spinner class='mr-2' size={16} mode='blue' />
								</button>
							)
						}

						return (
							<div class='assign_agent__member'>
								<Avatar class='assign_agent__member_avatar' agent={agent} size='sm' nodot />
								<div class='assign_agent__member_info'>
									<div class='assign_agent__member_name'>{sb.getAgentDisplayName(agent, store.me())}</div>
								</div>
								{$inviteAgentBtn}
							</div>
						)
					})}
				</div>
			)
			if (!lo.size(uninvitedAgents)) $invite = null

			return (
				<div class={cls}>
					<div class='modal__overlay' vOn:click={() => (this.isEditLeadOwnersModalOpenned = false)} />
					<div class='modal__container'>
						<div class='modal__background'>
							<div
								class='modal_content'
								style='width: 350px; max-height: 600px; overflow: hidden; display: flex; flex-direction: column'
							>
								<div class='modal_content__header'>
									<div class='d-flex align-items-center'>
										<div>{this.$t('assignee')}</div>
										<icon class='btn__close ml-auto' vOn:click={() => (this.isEditLeadOwnersModalOpenned = false)} />
									</div>
								</div>
								<div class='modal_content__main' style=' overflow: auto; flex: 1'>
									{$agents}
									{$invite}
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		},

		renderLeadOwenersField() {
			let agents = lo.get(this.user, 'lead_owners', [])
			agents = lo.map(agents, (id) => store.matchAgent()[id] || {})
			agents = lo.filter(agents, (agent) => agent.type === 'agent')

			let mainOwner = sb.getUserTextAttr(this.user, 'owner')
			if (!lo.size(agents) && !mainOwner) return null
			let $avatars = <AvatarGroup agents={agents} size={7} xs rounded />
			if (mainOwner) {
				mainOwner = store.matchAgent()[mainOwner] || {id: mainOwner, type: 'agent'}
				$avatars = <AvatarGroup agents={[mainOwner]} size={7} xs rounded />
			}

			return (
				<div class='user_info__field' key={'lead_owner'}>
					<div class='user_info__field_label'>{this.$t('assignee')}</div>
					{lo.size(agents) > 0 ? (
						<div
							class='d-flex align-items-center'
							vOn:click={() => (this.isEditLeadOwnersModalOpenned = true)}
							style='cursor: pointer'
						>
							{$avatars}
						</div>
					) : (
						<div
							class='d-flex align-items-center'
							vOn:click={() => (this.isEditLeadOwnersModalOpenned = true)}
							style='cursor: pointer'
						>
							<em class='text__muted'>{this.$t('none')}</em>
						</div>
					)}
				</div>
			)

			return (
				<div class='mt-4 ml-4 mr-4'>
					<div class='text__semibold mb-2'>{this.$t('assignee')}</div>
				</div>
			)
		},

		renderFieldSkeleton(height) {
			if (!height) height = 22.5
			return (
				<div class='user_info__field'>
					<div
						class='user_info__field_label'
						style={`height: ${height}px; background: whitesmoke; border-radius: 8px`}
					></div>
					<div style={`height: ${height}px ; width: 100%; background: whitesmoke; border-radius: 8px`}></div>
				</div>
			)
		},

		onSelectPhoneAction(action, phone, attr) {
			if (action === 'edit') return this.openEditPrimaryAttrModal(attr)
			if (action === 'call') return this.makeCall(phone)
			if (action === 'copy') {
				sb.copyToClipboard(phone)
				this.$showSuccess(this.$t('copied'))
				return
			}
			if (action === 'send_email') {
				this.$root.$emit('show_global_email', {to: phone})
				//this.onSendMessageToUser({
				//id: this.user.id,
				//channel: 'email',
				//channel_source: 'email',
				//profile_id: phone,
				//})
				return
			}
			if (action === 'send_zns') {
				this.isZnsModalOpenned = true
				return
			}
		},

		renderPrimaryAttrField(attr) {
			let attrdef =
				lo.find(store.matchUserAttribute(), (a) => {
					if (!a) return null
					if (!a.key) return null
					if (!attr.key) return null
					return a.key.toLowerCase() === attr.key.toLowerCase()
				}) || {}

			let attrname = getAttributeName(attrdef) || attrdef.label
			let $label = (
				<div class='user_info__field_label' title={this.$t('attrname')}>
					{this.$t(attrname)}
				</div>
			)
			if (attr.key === 'emails') {
				$label = (
					<div class='user_info__field_label primary_attr_label'>
						<svg width='14' height='14' viewBox='0 0 16 13' fill='none' class='user_info__field_label_icon'>
							<path
								d='M13 1H2.5C1.67157 1 1 1.67157 1 2.5V10C1 10.8284 1.67157 11.5 2.5 11.5H13C13.8284 11.5 14.5 10.8284 14.5 10V2.5C14.5 1.67157 13.8284 1 13 1Z'
								fill='currentColor'
								stroke='currentColor'
								stroke-width='1.75'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M1 2.5L7.75 7L14.5 2.5'
								stroke='white'
								stroke-width='1.75'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>
						{this.$t('Email')}
					</div>
				)
			}
			if (attr.key === 'phones') {
				$label = (
					<div class='user_info__field_label primary_attr_label'>
						<svg
							width='14'
							height='14'
							viewBox='0 0 13 13'
							fill='none'
							class='user_info__field_label_icon'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M1.5 0H4.5L6 3.75L4.125 4.875C4.92822 6.50365 6.24635 7.82178 7.875 8.625L9 6.75L12.75 8.25V11.25C12.75 11.6478 12.592 12.0294 12.3107 12.3107C12.0294 12.592 11.6478 12.75 11.25 12.75C8.32445 12.5722 5.56512 11.3299 3.49262 9.25738C1.42013 7.18489 0.177787 4.42555 0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0Z'
								fill='currentColor'
							/>
						</svg>
						{this.$t('phn')}
					</div>
				)
			}

			let values = []
			if (attr.text) {
				values.push(attr.text)
			}
			let otherValues = attr.other_values
			lo.each(otherValues, (value) => {
				values.push(value)
			})
			if (attr.key === 'phones') {
				values = lo.map(values, (value) => sb.displayUserPhoneNumber(value))
			}
			let $values
			if (lo.size(values)) {
				$values = (
					<div class='flex__1' style='overflow: hidden'>
						{lo.map(values, (value, idx) => {
							let items = []
							if (attr.key === 'phones') {
								items = [
									{
										id: 'edit',
										label: this.$t('edit'),
										icon: <Icon name='edit' size='18' class='text__muted' />,
									},
									{
										id: 'call',
										label: this.$t('call_short') + ' ' + value,
										icon: <Icon name='phone' size='18' class='text__muted' />,
									},
								]
								let hasZns = lo.find(
									store.matchIntegration(),
									(inte) => inte.connector_type === 'zalo' && inte.state === 'activated',
								)
								hasZns = hasZns && lo.size(store.matchZnsTemplate()) > 0

								if (hasZns) {
									items.push({
										id: 'send_zns',
										label: this.$t('send_zns'),
										icon: <Icon name='letter-z' size='18' class='text__muted' />,
									})
								}
								items.push({
									id: 'copy',
									label: this.$t('copy'),
									icon: <Icon name='copy' size='18' class='text__muted' />,
								})
							} else if (attr.key === 'emails') {
								items = [
									{
										id: 'edit',
										label: this.$t('edit'),
										icon: <Icon name='edit' size='18' class='text__muted' />,
									},
								]
								let canSendEmail = lo.find(
									sb.getAllEmail(),
									(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
								)

								if (canSendEmail) {
									items.push({
										id: 'send_email',
										label: this.$t('send_email'),
										icon: <Icon name='mail' size='18' class='text__muted' />,
									})
								}
								items.push({
									id: 'copy',
									label: this.$t('copy'),
									icon: <Icon name='copy' size='18' class='text__muted' />,
								})
							}
							return (
								<Fragment>
									{lo.size(items) > 0 ? (
										<Dropdown
											mode='custom'
											items={items}
											dropdown_width={200}
											vOn:select={(item) => this.onSelectPhoneAction(item.id, value, attr)}
										>
											<div class='user_info__field_primary_input text__truncate' title={value}>
												{value}
											</div>
										</Dropdown>
									) : (
										<div
											class='user_info__field_primary_input text__truncate'
											title={value}
											vOn:click={() => this.openEditPrimaryAttrModal(attr)}
										>
											{value}
										</div>
									)}
								</Fragment>
							)
						})}
					</div>
				)
			} else {
				$values = (
					<div class='input2' vOn:click={() => this.openEditPrimaryAttrModal(attr)}>
						<div class='input2__text'>
							<div class='input2__placeholder'>{this.$t('click_to_edit')}</div>
						</div>
					</div>
				)
			}
			return (
				<div class='user_info__field' key={attr.key}>
					{$label}
					{$values}
				</div>
			)
		},

		openEditPrimaryAttrModal(attr) {
			this.editPrimaryAttr = attr
			this.isEditPrimaryAttrModalOpened = true
		},

		renderField(attr) {
			if (!attr) return null

			let attrdef =
				lo.find(store.matchUserAttribute(), (a) => {
					if (!a) return null
					if (!a.key) return null
					if (!attr.key) return null
					return a.key.toLowerCase() === attr.key.toLowerCase()
				}) || {}

			if (attrdef.archived) return null
			if (!attrdef.type) return null

			let attrname = getAttributeName(attrdef) || attrdef.label
			if (!attrname) return null

			let namestyle = ''
			if (attrdef.type === 'text') {
				if (attrdef.multiple_line || attrdef.select === 'checkbox' || attrdef.select === 'radio') {
					namestyle = 'align-self: flex-start'
				}
			}
			let $name = (
				<div class='user_info__field_label' v-tooltip={this.$t(attrname)} style={namestyle}>
					{this.$t(attrname)}
				</div>
			)

			if (attr.key === 'emails') {
				$name = (
					<div class='user_info__field_label'>
						<svg width='14' height='14' viewBox='0 0 16 13' fill='none' class='user_info__field_label_icon'>
							<path
								d='M13 1H2.5C1.67157 1 1 1.67157 1 2.5V10C1 10.8284 1.67157 11.5 2.5 11.5H13C13.8284 11.5 14.5 10.8284 14.5 10V2.5C14.5 1.67157 13.8284 1 13 1Z'
								fill='currentColor'
								stroke='currentColor'
								stroke-width='1.75'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
							<path
								d='M1 2.5L7.75 7L14.5 2.5'
								stroke='white'
								stroke-width='1.75'
								stroke-linecap='round'
								stroke-linejoin='round'
							/>
						</svg>
						{this.$t('Email')}
					</div>
				)
			} else if (attr.key === 'phones') {
				$name = (
					<div class='user_info__field_label'>
						<svg
							width='14'
							height='14'
							viewBox='0 0 13 13'
							fill='none'
							class='user_info__field_label_icon'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M1.5 0H4.5L6 3.75L4.125 4.875C4.92822 6.50365 6.24635 7.82178 7.875 8.625L9 6.75L12.75 8.25V11.25C12.75 11.6478 12.592 12.0294 12.3107 12.3107C12.0294 12.592 11.6478 12.75 11.25 12.75C8.32445 12.5722 5.56512 11.3299 3.49262 9.25738C1.42013 7.18489 0.177787 4.42555 0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0Z'
								fill='currentColor'
							/>
						</svg>
						{this.$t('phn')}
					</div>
				)
			} else if (attr.key === 'lifecycle_stage' && store.isUserStageDisplayed()) {
				$name = (
					<div class='user_info__field_label' v-tooltip={this.$t('lifecycle_stage')}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							class='user_info__field_label_icon'
							width='24'
							height='24'
							viewBox='0 0 22 22'
							stroke-width='2'
							stroke='currentColor'
							fill='none'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path stroke='none' d='M0 0h24v24H0z' />
							<line x1='6' y1='21' x2='14' y2='21' />
							<line x1='10' y1='21' x2='10' y2='3' />
							<path d='M10 4l9 4l-9 4' fill='currentColor' />
						</svg>
						{this.$t('usr_stage')}
					</div>
				)
			}

			return (
				<div class='user_info__field' key={attr.key}>
					{$name}
					{this.renderTextField(attr)}
					{this.renderNumberField(attr)}
					{this.renderBooleanField(attr)}
					{this.renderDateField(attr)}
				</div>
			)
		},

		onMousedownField(field) {
			this.mouseHoldTimer = Date.now()
		},

		onMouseupField(field) {
			let dur = Date.now() - this.mouseHoldTimer

			if (dur < 200) {
				// is click
				this.toggleEditAttributeModal(field)
			}
		},

		async toggleEditAttributeModal(field) {
			this.isShowMoreDropdownOpen = true
			await this.$nextTick()

			this.$refs.edit_modal.FocusField(field)
		},

		openShowMoreAttributes() {
			this.isShowMoreDropdownOpen = true
			this.editAttributes = lo.get(this.user, 'attributes', [])
		},

		isUnknownVisitor() {
			return !lo.get(this.user, 'type')
		},

		renderFields() {
			if (this.isUnknownVisitor()) return null
			let userattrs = lo.get(this.user, 'attributes', [])

			let $attrs = lo.map(this.displayFields, (field) => {
				let attr = lo.find(userattrs, (attr) => attr.key.toLowerCase() === field.toLowerCase())
				if (attr) return this.renderField(attr)

				return this.renderField({
					key: lo.get(store.matchUserAttribute(), `${field}.key`),
					type: lo.get(store.matchUserAttribute(), `${field}.type`),
					field: field,
				})
			})

			return <Fragment>{$attrs}</Fragment>
		},

		onChangeAttribute(attr, value) {
			let newattr = lo.cloneDeep(attr)
			let editAttributes = lo.cloneDeep(this.editAttributes)
			newattr[attr.type] = value

			let idx = lo.findIndex(editAttributes, (eattr) => eattr.key === newattr.key)
			if (idx > -1) {
				lo.set(editAttributes, `${idx}.${attr.type}`, value)
			} else {
				editAttributes.push(newattr)
			}

			this.editAttributes = editAttributes
		},

		async saveEditAttributes() {
			if (this.saving) return
			this.saving = true

			let {error} = await store.updateUser({id: this.user.id, attributes: this.editAttributes})
			this.saving = false
			this.isShowMoreDropdownOpen = false
			if (error) this.$showError(error)
		},

		goToAttributes() {
			this.$router.push({path: '/settings/user-attributes', query: {addAttr: '1'}})
		},

		async onSendMessageToUser(user) {
			let channel = user.channel
			let source = user.channel_source
			let id = user.profile_id
			if (user.channel == 'account') {
				channel = 'subiz'
				source = 'web'
				id = user.id
			}

			this.$emit('showNewConvo', {
				user_id: user.id,
				touchpoint: {channel, source, id},
				focus_editor_when_open_convo: true,
			})
			let $dom = this.$refs[`user_contact_dropdown_${user.id}`]
			$dom && $dom.HideDropdown()
		},

		async unLinkUser(user) {
			let field = ''
			let mergeValue = ''
			let mergeReason = user.merged_reason || ''
			if (mergeReason.startsWith('same_email')) {
				field = 'email'
				mergeValue = mergeReason.slice(10)
			} else if (mergeReason.startsWith('same_phone')) {
				field = this.$t('phn')
				mergeValue = mergeReason.slice(10)
			} else if (mergeReason.startsWith('same_record_id')) {
				field = 'record_id'
				mergeValue = mergeReason.slice(14)
			}

			let cf = await this.$confirm({
				title: this.$t('unlink_profile'),
				description: this.$t('unlink_profile_desc', [field, mergeValue]),
				style: 'danger',
				cancel: this.$t('skip'),
			})
			if (!cf) return

			if (this.unlinking) return
			this.unlinking = true
			let {error} = await store.unLinkUser(user.id === lo.get(this.user, 'id') ? this.true_user_id : user.id)
			this.unlinking = false
			if (error) return this.$showError(error)

			this.$forceUpdate()
			this.$emit('unlink')
		},

		renderSubizProfile() {
			let profiles = lo.get(this.user, 'secondaries', [])
			let user = lo.find(profiles, (profile) => profile.id === this.true_user_id)

			// check only subiz user
			let isOnlyUser = this.true_user_id === lo.get(this.user, 'id')
			let isOnlySubiz = lo.get(this.user, 'channel') === 'subiz' || lo.get(this.user, 'channel') === 'account'
			if (isOnlyUser && isOnlySubiz) {
				user = lo.cloneDeep(this.user)
			}

			if (!user) return
			if (user.channel !== 'subiz' && user.channel !== 'account') return
			return (
				<UserProfileButton
					subiz
					profile={user}
					vOn:sendMessage={this.onSendMessageToUser}
					vOn:unlink={this.unLinkUser}
					true_user_id={this.true_user_id}
				/>
			)
		},

		renderProfile(user, idx) {
			return (
				<UserProfileButton
					profile={user}
					vOn:zns={() => (this.isZnsModalOpenned = true)}
					vOn:sendMessage={this.onSendMessageToUser}
					vOn:unlink={this.unLinkUser}
					true_user_id={this.true_user_id}
				/>
			)
		},

		renderUserProfiles() {
			// skeleton
			let trueUser = store.matchUser(this.true_user_id, true)
			if (!this.user || !trueUser || lo.size(trueUser.attributes) < 2) {
				return (
					<div class='d-flex mt-2'>
						<div class='contact_profile_btn_wrapper'>
							<div class='contact_profile_btn' style='background: whitesmoke' />
						</div>
						<div class='contact_profile_btn_wrapper'>
							<div class='contact_profile_btn' style='background: whitesmoke' />
						</div>
					</div>
				)
			}

			let $phone = null
			let $email = null

			let attributes = lo.get(this.user, 'attributes', [])
			let phones = [sb.getUserTextAttr(this.user, 'phones')]
			let emails = [sb.getUserTextAttr(this.user, 'emails')]
			let phoneattr = lo.find(attributes, (attr) => attr.key === 'phones')
			let emailattr = lo.find(attributes, (attr) => attr.key === 'emails')
			phones = phones.concat(lo.get(phoneattr, 'other_values', []))
			emails = emails.concat(lo.get(emailattr, 'other_values', []))
			phones = lo.uniq(phones)
			emails = lo.uniq(emails)
			emails = lo.filter(emails, (email) => lo.trim(email))
			phones = lo.filter(phones, (phone) => lo.trim(phone))
			phones = lo.map(phones, (phone) => sb.displayUserPhoneNumber(phone))
			//phones = ['8487814392', '8487814393']

			if (lo.size(phones) > 0) {
				$phone = (
					<div class='contact_profile_btn_wrapper'>
						<img
							class='contact_profile_btn clickable'
							src={require('../assets/img/phone_icon.svg')}
							vOn:click={() => this.makeCall(phones[0])}
							v-tooltip={this.$t('call_short') + ' ' + phones[0]}
						/>
					</div>
				)

				if (lo.size(phones) > 1) {
					let $phoneDropdown = []
					lo.map(phones, (phone) => {
						$phoneDropdown.push(
							<div style='padding: 10px; width: 300px' class='d-flex align-items-center'>
								<div class='text__semibold text__truncate ml-3 flex__1' style='font-size: 16px;'>
									{phone}
								</div>
								<button type='button' class='btn btn__sm btn__success ml-5' vOn:click={() => this.makeCall(phone)}>
									<Icon name='phone' class='phone_fill_icon' stroke-width='2' size='18' style='margin-top: -3px;' />
									<span style='font-style: normal; font-weight: 500; font-size: 16px; line-height: 19px; color: #FFFFFF; margin-left: 5px;'>
										{this.$t('call_short')}
									</span>
								</button>
							</div>,
						)
					})
					$phone = (
						<HoverDropdown dropdown_content={$phoneDropdown} class='contact_profile_btn_wrapper'>
							<img
								class='contact_profile_btn'
								v-tooltip={this.$t('call')}
								src={require('../assets/img/phone_icon.svg')}
							/>
						</HoverDropdown>
					)
				}
			}

			if (lo.size(emails) > 0) {
				$email = (
					<div class='contact_profile_btn_wrapper'>
						<img
							class='contact_profile_btn clickable'
							src={require('../assets/img/email_channel.svg')}
							v-tooltip={this.$t('send_email') + ' ' + this.$t('to').toLowerCase() + ' ' + emails[0]}
							vOn:click={(_) => this.$root.$emit('show_global_email', {to: emails[0]})}
						/>
					</div>
				)

				if (lo.size(emails) > 1) {
					let $emailDropdown = []

					lo.map(emails, (email) => {
						email = lo.trim(email)
						$emailDropdown.push(
							<div style='padding: 10px; width: 350px' class='d-flex align-items-center'>
								<div class='ml-3 mr-5 text__semibold text__truncate flex__1' style='font-size: 16px' title={email}>
									{email}
								</div>
								<button
									type='button'
									class='btn btn__sm btn__light'
									vOn:click={() => {
										sb.copyToClipboard(email)
										this.$showSuccess(this.$t('copied'))
									}}
								>
									<Icon size='22' name='clipboard' class='' />
								</button>

								<button
									type='button'
									class='btn btn__sm btn__primary ml-2'
									vOn:click={(_) => this.$root.$emit('show_global_email', {to: email})}
								>
									{this.$t('send_email')}
								</button>
							</div>,
						)
					})
					$email = (
						<HoverDropdown dropdown_content={$emailDropdown} class='contact_profile_btn_wrapper'>
							<img class='contact_profile_btn' src={require('../assets/img/email_channel.svg')} />
						</HoverDropdown>
					)
				}
			}

			let availabelEmail = lo.filter(
				sb.getAllEmail(),
				(email) => !email.outbound_disabled && email.verification_status === 'SUCCESS',
			)
			if (!lo.size(availabelEmail) && lo.size(emails) > 0) {
				let clsDetectEmail = `detect_user_info_from_message__container detect_user_info_from_message__container__user`
				if (this.detectEmail === 'email') {
					clsDetectEmail +=
						' detect_user_info_from_message__container__visible detect_user_info_from_message__container__' +
						(this.popupgoup ? 'up' : 'down')
				}
				$email = (
					<a
						class='detect_user_info_from_message'
						vOn:mouseover={(e) => this.onDetectInfoMouseOver('email', e)}
						vOn:mouseleave={(_) => this.onDetectInfoMouseLeave()}
					>
						<div class='contact_profile_btn_wrapper' style='position: relative;'>
							<img class='contact_profile_btn' src={require('../assets/img/email_channel.svg')} style='opacity: 0.7' />
							<div class='webphone_disconnected' style='z-index: 0; transform: rotate(0deg); top: -8px; right: -8px'>
								!
							</div>
						</div>
						<div class={clsDetectEmail} style='background: rgb(54, 60, 66)'>
							<p style='margin-bottom: 0;'>{this.$t('error_no_business_email_address_short')}</p>
							<p style=''>{this.$t('solution_no_business_email_address')}</p>
							<div class='d-flex justify-content-end'>
								<button class='btn btn__sm btn__primary'>
									<router-link style='color: white;' to={{name: 'settings.email'}}>
										{this.$t('set_up_now')}
									</router-link>
								</button>
							</div>
						</div>
					</a>
				)
			}

			let profiles = [this.user]
			lo.each(this.user.secondaries, (user) => {
				profiles.push(user)
			})
			profiles = lo.orderBy(profiles, ['id'], ['asc'])
			profiles = lo.take(profiles, 10)
			return (
				<div class='d-flex mt-2 flex-wrap'>
					{$email}
					{$phone}
					{this.renderSubizProfile()}
					{lo.map(profiles, this.renderProfile)}
					{this.renderMoreAction()}
				</div>
			)
		},

		makeCall(phonenumber) {
			if (!this.call_entry) {
				this.$root.$emit('show_call_modal', {call_to: phonenumber, call_immediately: true})
				return
			}

			let entryNumbers = lo.get(this.call_entry, 'number', '')
			let phones = lo.split(entryNumbers, ',')
			phones = lo.map(phones, (phone) => lo.trim(phone))

			if (lo.includes(phones, phonenumber)) {
				this.$root.$emit('show_call_modal', {
					call_to: phonenumber,
					call_immediately: true,
					campaign_id: lo.get(this.call_entry, 'campaign_id'),
					outbound_call_entry_id: lo.get(this.call_entry, 'user_id'),
				})
			} else {
				this.$root.$emit('show_call_modal', {call_to: phonenumber, call_immediately: true})
			}
		},

		onCallZalo(item) {
			let [_, phone, oaid] = item.id.split('_')
			this.$root.$emit('show_call_modal', {from_number: oaid, call_to: phone, call_immediately: true})
		},

		onDetectInfoMouseOver(evId, e) {
			if (document.body.scrollHeight - e.clientY < 200) {
				this.popupgoup = true
			} else {
				this.popupgoup = false
			}
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectEmail = evId
			}, 500)
		},

		onDetectInfoMouseLeave() {
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectEmail = ''
			}, 250)
		},

		onSelectSendMessage(action) {
			if (action === 'send_zns') {
				this.isZnsModalOpenned = true
				return
			}
			this.onSendMessageToUser(this.user)
		},

		renderMoreAction() {
			let items = [
				{
					id: 'ban_user',
					label: this.$t('block_user'),
					icon: <Icon name='circle-off' size='15' class='text__muted' />,
				},
			]

			let trueUser = store.matchUser(this.true_user_id, true) || {}
			if (sb.isUserBanned(trueUser)) {
				items = [
					{
						id: 'unban_user',
						label: this.$t('title_unblock'),
						icon: <Icon name='lock-open' size='15' class='text__muted' />,
					},
				]
			}

			return (
				<Dropdown
					mode='custom'
					dropdown_width={180}
					items={items}
					vOn:select={this.onSelectMoreAction}
					class='contact_profile_btn_wrapper'
				>
					<button
						class='btn btn__light btn__sm align-items-center justify-content-center contact_profile_btn'
						style='padding: 0; line-height: 1'
					>
						<Icon name='dots' stroke-width='1.5' size='12' />
					</button>
				</Dropdown>
			)
		},

		onSelectMoreAction(item) {
			if (item.id === 'ban_user') return this.banUser()
			if (item.id === 'unban_user') return this.unbanUser()
		},

		renderUserLabelIcon() {
			return (
				<svg
					width='16'
					height='16'
					viewBox='0 0 16 16'
					fill='none'
					class='user_info__field_label_icon'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M2 4.11762V6.84159C2 7.22064 2.15035 7.58416 2.41858 7.8524L8.14745 13.5813C8.28018 13.714 8.43777 13.8193 8.61121 13.8912C8.78464 13.963 8.97053 14 9.15826 14C9.34599 14 9.53188 13.963 9.70532 13.8912C9.87875 13.8193 10.0363 13.714 10.1691 13.5813L13.5813 10.1691C13.714 10.0363 13.8193 9.87875 13.8912 9.70532C13.963 9.53188 14 9.34599 14 9.15826C14 8.97053 13.963 8.78464 13.8912 8.61121C13.8193 8.43777 13.714 8.28018 13.5813 8.14745L7.85169 2.41858C7.58377 2.1507 7.22045 2.00015 6.84159 2H4.11762C3.55599 2 3.01737 2.22311 2.62024 2.62024C2.22311 3.01737 2 3.55599 2 4.11762Z'
						fill='#D9D9D9'
						stroke='#DADADA'
						stroke-width='2.75'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
					<path
						d='M5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z'
						fill='white'
					/>
				</svg>
			)
		},

		renderSkeleton() {
			return (
				<div class='user_info'>
					<div class='user_info__body' style='position: relative'>
						<div class='user_info__avatar_section'>
							<div style='position: relative; width: 70px'>
								<Avatar class='user_info__avatar' skeleton size='70' />
							</div>
							<div class='ml-4'>
								<div class='d-flex justify-content-center'>
									<div style='height: 30px; background: whitesmoke; border-radius: 5px; width: 200px'></div>
								</div>
								{this.renderUserProfiles()}
							</div>
						</div>
						{this.renderFieldSkeleton()}
						{this.renderFieldSkeleton()}
						{this.renderFieldSkeleton(32)}

						{lo.map(this.displayFields, (_) => this.renderFieldSkeleton())}
					</div>
				</div>
			)
		},

		renderTrueUserProfile() {
			if (!this.user) return
			if (this.user.id === this.true_user_id) return null

			let user = store.matchUser(this.true_user_id, true)
			return (
				<div class='true_user_profile_section'>
					<div class='text__semibold mb-2'>{this.$t('user_chat_profile')}</div>
					<div class='true_user_profile_card'>
						<div class='true_user_profile_card_header'>
							<div class='d-flex align-items-center w_100' style='overflow: hidden'>
								<Avatar user={user} size='24' is_profile />
								<div style='margin-left: 8px' class='text__truncate mr-4 flex__1' title={sb.getUserDisplayName(user)}>
									{sb.getUserDisplayName(user)}
								</div>
								{/*
								<button type='button' class='btn btn__white btn__sm no-shrink' vOn:click={() => this.unLinkUser(user)}>
									{this.$t('cancel_link')}
								</button>
          */}
								<div
									class='ml-3 text__sm link d-flex align-items-center no-shrink'
									vOn:click={() => (this.isShowTrueUserDetail = !this.isShowTrueUserDetail)}
								>
									{this.$t('detail')}
									<Icon name={this.isShowTrueUserDetail ? 'chevron-up' : 'chevron-down'} size='14' class='ml-1' />
								</div>
							</div>
						</div>
						{this.renderTrueUserProfileDetail(user)}
					</div>
				</div>
			)
		},

		renderTrueUserProfileDetail(user) {
			if (!user) return
			if (!this.isShowTrueUserDetail) return

			let created = sb.getUserDateAttr(user, 'created')
			created = created ? new Date(created).getTime() : 0
			let lastMessageSent = sb.getUserDateAttr(user, 'last_message_sent')
			lastMessageSent = lastMessageSent ? new Date(lastMessageSent).getTime() : 0

			let pageUrl = lo.get(user, 'start_content_view.by.device.page_url', '')
			let domain = sb.getDomainFromUrl(pageUrl)
			let prefix = pageUrl.split('://')
			prefix = prefix[0] || ''
			let isFollowed = sb.getUserBooleanAttr(user, 'is_followed')

			let $extra = null
			let inte = {}
			if (user.channel === 'subiz' || user.channel === 'account') {
				$extra = (
					<div class='d-flex mt-2'>
						<div class='contact_profile_info_label'>{this.$t('Website')}</div>
						{domain ? (
							<a target='_blank' href={prefix + '://' + domain}>
								{domain}
							</a>
						) : (
							<em>{this.$t('no_information')}</em>
						)}
					</div>
				)
			} else if (user.channel === 'facebook') {
				let inteid = `${store.me().account_id}.${user.channel_source}.fabikon`
				inte = store.matchIntegration()[inteid]
				$extra = (
					<div class='d-flex mt-2'>
						<div class='contact_profile_info_label'>{this.$t('Fanpage')}</div>
						{inte ? (
							<a target='_blank' href={`https://facebook.com/${user.channel_source}`}>
								{lo.get(inte, 'name')}
							</a>
						) : (
							<em>{this.$t('no_information')}</em>
						)}
					</div>
				)
			} else if (user.channel === 'zalo') {
				let inteid = `${store.me().account_id}.${user.channel_source}.zalokon`
				inte = store.matchIntegration()[inteid]
				$extra = (
					<Fragment>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('OA')}</div>
							{inte ? (
								<a target='_blank' href={`https://zalo.me/${user.channel_source}`}>
									{lo.get(inte, 'name')}
								</a>
							) : (
								<em>{this.$t('no_information')}</em>
							)}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('zalo_follow')}</div>
							{isFollowed ? <div>{this.$t('zalo_followed')}</div> : <div>{this.$t('zalo_unfollowed')}</div>}
						</div>
					</Fragment>
				)
			} else if (user.channel === 'instagram') {
				let inteid = `${store.me().account_id}.instagram_${user.channel_source}.fabikon`
				inte = store.matchIntegration()[inteid]
				$extra = (
					<div class='d-flex mt-2'>
						<div class='contact_profile_info_label'>{this.$t('Instagram')}</div>
						{inte ? (
							<a target='_blank' href={`https://instagram.com/${inte.username}`}>
								{lo.get(inte, 'name')}
							</a>
						) : (
							<em>{this.$t('no_information')}</em>
						)}
					</div>
				)
			}

			return (
				<div class='true_user_profile_card_body'>
					<div class='d-flex'>
						<div class='contact_profile_info_label'>{this.$t('created_date')}</div>
						{created ? <Time time={created} /> : <em>{this.$t('no_information')}</em>}
					</div>
					{$extra}
					<div class='d-flex mt-2'>
						<div class='contact_profile_info_label'>{this.$t('last_message')}</div>
						{lastMessageSent ? <Time time={lastMessageSent} /> : <em>{this.$t('no_information')}</em>}
					</div>
				</div>
			)
		},

		renderContactBadge() {
			if (this.isNameEditting) return
			if (lo.get(this.user, 'type') !== 'contact') return

			let created = sb.getUserDateAttr(this.user, 'created')
			created = created ? new Date(created).getTime() : 0
			let lastMessageSent = sb.getUserDateAttr(this.user, 'last_message_sent')
			lastMessageSent = lastMessageSent ? new Date(lastMessageSent).getTime() : 0

			let contactname = sb.getUserTextAttr(this.user, 'display_name') || sb.getUserDisplayName(this.user)
			let $hoverContent = (
				<div class='contact_profile_dropdown' style='width: 360px'>
					<div class='contact_profile_dropdown_body'>
						<div class='text__muted text__uppercase text__semibold' style='margin-bottom: 15px'>
							{this.$t('customer_profile')}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('customer_id_short')}</div>
							<div>{sb.getUserTextAttr(this.user, 'record_id') || this.user.id}</div>
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('name')}</div>
							<div class='text__truncate flex__1'>
								<Input2
									value={contactname}
									vOn:change={(newval) => this.saveAttribute({}, {key: 'display_name', text: newval})}
								/>
							</div>
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('created_date')}</div>
							{created ? <Time time={created} /> : <em>{this.$t('no_information')}</em>}
						</div>
						<div class='d-flex mt-2'>
							<div class='contact_profile_info_label'>{this.$t('last_message')}</div>
							{lastMessageSent ? <Time time={lastMessageSent} /> : <em>{this.$t('no_information')}</em>}
						</div>

						<div class='mt-3'>
							<a href='javascript:;' class='link link__danger' vOn:click={() => this.unLinkUser(this.user)}>
								{this.$t('remove_from_this_profile')}
							</a>
						</div>
					</div>
				</div>
			)
			return (
				<HoverDropdown class='ml-2 no-shrink' style='line-height: 1' dropdown_content={$hoverContent}>
					<div
						class='badge align-items-center'
						style='background: #6c757d; line-height: 1; padding: 5px 5px; max-width: 100px; display: flex; color: #fff; font-size: 10px; border-radius: 4px;'
					>
						<svg
							class='no-shrink'
							style='width: 12px; height: 10px; fill: currentColor; user-select: none; outline: none;'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 448 512'
						>
							<path d='M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z' />
						</svg>
					</div>
				</HoverDropdown>
			)
		},
	},

	render() {
		if (this.refresh) lo.noop()
		let trueUser = store.matchUser(this.true_user_id, true)
		if (!this.user || !trueUser || lo.size(trueUser.attributes) < 2) return this.renderSkeleton()
		let username = sb.getUserDisplayName(this.user)
		let $avatar = <Avatar user={this.user} size='lg' />

		let attrs = lo.get(this.user, 'attributes', [])
		let trueAttrs = lo.get(trueUser, 'attributes', [])
		let trueUserName = sb.getUserDisplayName(trueUser)

		let isDisplayPrimaryFullname = lo.get(trueUser, 'channel') === 'call' || lo.get(trueUser, 'channel') === 'email'

		let fullnameattr = lo.find(trueAttrs, (attr) => attr.key === 'fullname')
		if (isDisplayPrimaryFullname) fullnameattr = lo.find(attrs, (attr) => attr.key === 'fullname')
		// default name
		if (!lo.get(fullnameattr, 'text'))
			fullnameattr = {key: 'fullname', text: isDisplayPrimaryFullname ? username : trueUserName}
		fullnameattr = lo.clone(fullnameattr)
		let emailattr = lo.find(attrs, (attr) => attr.key === 'emails') || {key: 'emails', text: ''}
		let phoneattr = lo.find(attrs, (attr) => attr.key === 'phones') || {key: 'phones', text: ''}
		let stageattr = lo.find(attrs, (attr) => attr.key === 'lifecycle_stage') || {key: 'lifecycle_stage', text: ''}

		let $stage = null
		if (stageattr.text && !this.isNameEditting) {
			//let items = lo.get(store.matchUserAttribute(), ['lifecycle_stage', 'items'], [])
			//let item = lo.find(items, (item) => item.value === stageattr.text)
			//let text = lo.get(item, 'label')
			//let cls =
			//'ml-3 dropdown_input dropdown_input__small text__uppercase text__xs text__semibold no-shrink dropdown_input__no_border'
			//if (stageattr.text === 'customer') {
			//cls += ' dropdown_input__success'
			//} else {
			//cls += ' dropdown_input__primary dropdown_input__light'
			//}
			//$stage = (
			//<div
			//class={cls}
			//style='padding-right: 6px; background-image: unset; width: auto; cursor: auto'
			//v-tooltip={text}
			//>
			//{sb.getFirstCharacterOfEachWords(text)}
			//</div>
			//)
			//$stage = (
			//<div
			//class='badge ml-2 no-shrink'
			//style='background: #dfdfdf; line-height: 1; padding: 3px 4px'
			//v-tooltip={this.$t('user_has_contact')}
			//>
			//<svg
			//style='width: 10px; height: 9px; fill: #6c6c6c; user-select: none; outline: none;'
			//xmlns='http://www.w3.org/2000/svg'
			//viewBox='0 0 448 512'
			//>
			//<path d='M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z' />
			//</svg>
			//</div>
			//)
		}

		return (
			<div class='user_info' key={this.user.id}>
				<div class='user_info__body' style='position: relative'>
					<div class='user_info__avatar_section'>
						<div class={{user_info__avatar_wrapper: true}} style='position: relative; width: 70px'>
							<Avatar
								style='display: block'
								class='user_info__avatar'
								user={trueUser}
								size='70'
								notooltip
								nodot
								is_profile={!isDisplayPrimaryFullname}
							/>
							{this.renderOnline()}
						</div>

						<div class='ml-4 flex__1' style='overflow: hidden'>
							<div class='flex__1 d-flex align-items-center' style='overflow: hidden;'>
								<div
									class={{
										text__truncate: true,
										text__semibold: !this.isUnknownVisitor(),
										w_100: this.isNameEditting,
										text__muted: this.isUnknownVisitor(),
									}}
									style='font-size: 20px;'
									title={fullnameattr.text}
								>
									<Input2
										value={fullnameattr.text}
										vOn:change={(newval) =>
											isDisplayPrimaryFullname
												? this.saveAttribute(fullnameattr, {key: 'fullname', text: newval})
												: this.saveTrueUserAttribute(fullnameattr, {key: 'fullname', text: newval})
										}
										vOn:toggleEdit={(val) => (this.isNameEditting = val)}
										loading={fullnameattr.saving}
										ref='name_input2'
									/>
								</div>
								{this.renderContactBadge()}
							</div>
							{this.renderUserProfiles()}
						</div>
					</div>
					{this.renderPrimaryAttrField(emailattr)}
					{this.renderPrimaryAttrField(phoneattr)}
					{store.isUserStageDisplayed() && !this.isUnknownVisitor() && this.renderField(stageattr)}
					{this.renderFields()}
					{this.renderTotalOrdersField()}
					{this.isUnknownVisitor() ? (
						<div
							class='mt-3 mr-4 ml-4 mb-3'
							vOn:click={() => this.saveAttribute({key: 'lifecycle_stage'}, {key: 'lifecycle_stage', text: 'lead'})}
						>
							<div class='btn btn__light w_100'>{this.$t('mark_as_lead')}</div>
						</div>
					) : (
						<div v-clickaway={(_) => (this.isShowMoreDropdownOpen = false)}>
							<div
								class='link action'
								style='margin-left: 20px; margin-top: 10px; display: inline-block'
								vOn:click={this.openShowMoreAttributes}
							>
								{this.$t('show_more_user_info')}
							</div>
							<div class='ml-auto'>
								{this.isShowMoreDropdownOpen && (
									<UserMoreInfoModal
										ref='edit_modal'
										vOn:change={(v) => (this.displayFields = v)}
										vOn:close={(_) => (this.isShowMoreDropdownOpen = false)}
										displayFields={this.displayFields}
										user={this.user}
										y={100}
									/>
								)}
							</div>
						</div>
					)}
				</div>
				<ZnsTemplateModal
					open={this.isZnsModalOpenned}
					user={this.user}
					vOn:close={() => (this.isZnsModalOpenned = false)}
				/>
				<EditUserPrimaryAttributeModal
					uid={this.user.id}
					attr={this.editPrimaryAttr}
					open={this.isEditPrimaryAttrModalOpened}
					vOn:close={() => (this.isEditPrimaryAttrModalOpened = false)}
					vOn:success={() => this.$emit('merge')}
				/>
			</div>
		)
	},
}

function isConvoMatchedContact(convo, user) {
	let convoChannel = lo.get(convo, 'touchpoint.channel')
	if (user.channel === 'subiz') {
		return convoChannel === 'subiz' || !convoChannel
	}
	return user.channel === convoChannel
}

// {this.$t('first_income')}{' '}
// 				{this.renderLeadOwenersEditModal()}

// {this.renderBan()}
