import {Platform, StyleProp, StyleSheet, ViewStyle} from 'react-native'

import {ios, native, platform, web} from './util/platform'
import * as Layout from '@/constants/layout'

import {isAndroid} from '@sb/util'

export const TRACKING = isAndroid ? 0.1 : 0

export let color = {}

export const space = {
	_2xs: 2,
	xs: 4,
	sm: 8,
	md: 12,
	lg: 16,
	xl: 20,
	_2xl: 24,
	_3xl: 28,
	_4xl: 32,
	_5xl: 40,
}

export const fontSize = {
	_2xs: 10,
	xs: 12,
	sm: 14,
	md: 16,
	lg: 18,
	xl: 20,
	_2xl: 22,
	_3xl: 26,
	_4xl: 32,
	_5xl: 40,
}

export const lineHeight = {
	none: 1,
	normal: 1.25,
	relaxed: 1.625,
}

export const borderRadius = {
	_2xs: 2,
	xs: 4,
	sm: 8,
	md: 12,
	full: 999,
}

/**
 * These correspond to Inter font files we actually load.
 */
export const fontWeight = {
	normal: '400',
	semibold: '500',
	bold: '600',
	heavy: '800',
}

export const gradients = {
	primary: {
		values: [
			[0, '#054CFF'],
			[0.4, '#1085FE'],
			[0.6, '#1085FE'],
			[1, '#59B9FF'],
		],
		hover_value: '#1085FE',
	},
	sky: {
		values: [
			[0, '#0A7AFF'],
			[1, '#59B9FF'],
		],
		hover_value: '#0A7AFF',
	},
	midnight: {
		values: [
			[0, '#022C5E'],
			[1, '#4079BC'],
		],
		hover_value: '#022C5E',
	},
	sunrise: {
		values: [
			[0, '#4E90AE'],
			[0.4, '#AEA3AB'],
			[0.8, '#E6A98F'],
			[1, '#F3A84C'],
		],
		hover_value: '#AEA3AB',
	},
	sunset: {
		values: [
			[0, '#6772AF'],
			[0.6, '#B88BB6'],
			[1, '#FFA6AC'],
		],
		hover_value: '#B88BB6',
	},
	summer: {
		values: [
			[0, '#FF6A56'],
			[0.3, '#FF9156'],
			[1, '#FFDD87'],
		],
		hover_value: '#FF9156',
	},
	nordic: {
		values: [
			[0, '#083367'],
			[1, '#9EE8C1'],
		],
		hover_value: '#3A7085',
	},
	bonfire: {
		values: [
			[0, '#203E4E'],
			[0.4, '#755B62'],
			[0.8, '#CD7765'],
			[1, '#EF956E'],
		],
		hover_value: '#755B62',
	},
}

console.log('PALTFORM', Platform)
export let atoms = {
	debug: {
		borderColor: 'red',
		borderWidth: 1,
	},

	/*
	 * Positioning
	 */
	fixed: {
		position: Platform.select({web: 'fixed', native: 'absolute'}),
	},
	absolute: {
		position: 'absolute',
	},
	relative: {
		position: 'relative',
	},
	sticky: web({
		position: 'sticky',
	}),
	inset_0: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	z_10: {
		zIndex: 10,
	},
	z_20: {
		zIndex: 20,
	},
	z_30: {
		zIndex: 30,
	},
	z_40: {
		zIndex: 40,
	},
	z_50: {
		zIndex: 50,
	},

	overflow_hidden: {
		overflow: 'hidden',
	},
	/**
	 * @platform web
	 */
	overflow_auto: web({
		overflow: 'auto',
	}),

	/*
	 * Width
	 */
	w_full: {
		width: '100%',
	},
	h_full: {
		height: '100%',
	},
	h_full_vh: web({
		height: '100vh',
	}),

	/**
	 * Used for the outermost components on screens, to ensure that they can fill
	 * the screen and extend beyond.
	 */
	util_screen_outer: [
		web({
			minHeight: '100vh',
		}),
		native({
			height: '100%',
		}),
	],

	/*
	 * Theme-independent bg colors
	 */
	bg_transparent: {
		backgroundColor: 'transparent',
	},

	/*
	 * Border radius
	 */
	rounded_2xs: {
		borderRadius: borderRadius._2xs,
	},
	rounded_xs: {
		borderRadius: borderRadius.xs,
	},
	rounded_sm: {
		borderRadius: borderRadius.sm,
	},
	rounded_md: {
		borderRadius: borderRadius.md,
	},
	rounded_full: {
		borderRadius: borderRadius.full,
	},

	/*
	 * Flex
	 */
	gap_0: {
		gap: 0,
	},
	gap_2xs: {
		gap: space._2xs,
	},
	gap_xs: {
		gap: space.xs,
	},
	gap_sm: {
		gap: space.sm,
	},
	gap_md: {
		gap: space.md,
	},
	gap_lg: {
		gap: space.lg,
	},
	gap_xl: {
		gap: space.xl,
	},
	gap_2xl: {
		gap: space._2xl,
	},
	gap_3xl: {
		gap: space._3xl,
	},
	gap_4xl: {
		gap: space._4xl,
	},
	gap_5xl: {
		gap: space._5xl,
	},
	flex: {
		display: 'flex',
		flexDirection: 'column',
	},
	flex_col: {
		display: 'flex',
		flexDirection: 'column',
	},
	flex_row: {
		display: 'flex',
		flexDirection: 'row',
	},
	flex_col_reverse: {
		display: 'flex',
		flexDirection: 'column-reverse',
	},
	flex_row_reverse: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	flex_wrap: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	flex_nowrap: {
		flexWrap: 'nowrap',
	},
	flex_0: {
		flex: web('0 0 auto') || native(0),
	},
	flex_1: {
		flex: 1,
	},
	flex_2: {
		flex: 2,
	},
	flex_grow: {
		flexGrow: 1,
	},
	flex_shrink: {
		flexShrink: 1,
	},
	flex_shrink_0: {
		flexShrink: 0,
	},
	justify_start: {
		justifyContent: 'flex-start',
	},
	justify_center: {
		justifyContent: 'center',
	},
	justify_between: {
		justifyContent: 'space-between',
	},
	justify_end: {
		justifyContent: 'flex-end',
	},
	align_center: {
		alignItems: 'center',
	},
	align_start: {
		alignItems: 'flex-start',
	},
	align_end: {
		alignItems: 'flex-end',
	},
	align_baseline: {
		alignItems: 'baseline',
	},
	align_stretch: {
		alignItems: 'stretch',
	},
	self_auto: {
		alignSelf: 'auto',
	},
	self_start: {
		alignSelf: 'flex-start',
	},
	self_end: {
		alignSelf: 'flex-end',
	},
	self_center: {
		alignSelf: 'center',
	},
	self_stretch: {alignSelf: 'stretch'},
	self_baseline: {alignSelf: 'baseline'},
	shrink: {flexShrink: 1},
	shrink_0: {flexShrink: 0},

	/*
	 * Text
	 */
	text_left: {
		textAlign: 'left',
	},
	text_center: {
		textAlign: 'center',
	},
	text_right: {
		textAlign: 'right',
	},
	text_2xs: {
		fontSize: fontSize._2xs,
		letterSpacing: TRACKING,
	},
	text_xs: {
		fontSize: fontSize.xs,
		letterSpacing: TRACKING,
	},
	text_sm: {
		fontSize: fontSize.sm,
		letterSpacing: TRACKING,
	},
	text_md: {
		fontSize: fontSize.md,
		letterSpacing: TRACKING,
	},
	text_lg: {
		fontSize: fontSize.lg,
		letterSpacing: TRACKING,
	},
	text_xl: {
		fontSize: fontSize.xl,
		letterSpacing: TRACKING,
	},
	text_2xl: {
		fontSize: fontSize._2xl,
		letterSpacing: TRACKING,
	},
	text_3xl: {
		fontSize: fontSize._3xl,
		letterSpacing: TRACKING,
	},
	text_4xl: {
		fontSize: fontSize._4xl,
		letterSpacing: TRACKING,
	},
	text_5xl: {
		fontSize: fontSize._5xl,
		letterSpacing: TRACKING,
	},
	leading_tight: {
		lineHeight: 1.15,
	},
	leading_snug: {
		lineHeight: 1.3,
	},
	leading_normal: {
		lineHeight: 1.5,
	},
	tracking_normal: {
		letterSpacing: TRACKING,
	},
	font_normal: {
		fontWeight: fontWeight.normal,
	},
	font_semibold: {
		fontWeight: fontWeight.semibold,
	},
	font_bold: {
		fontWeight: fontWeight.bold,
	},
	font_heavy: {
		fontWeight: fontWeight.heavy,
	},
	italic: {
		fontStyle: 'italic',
	},

	/*
	 * Border
	 */
	border_0: {
		borderWidth: 0,
	},
	border_t_0: {
		borderTopWidth: 0,
	},
	border_b_0: {
		borderBottomWidth: 0,
	},
	border_l_0: {
		borderLeftWidth: 0,
	},
	border_r_0: {
		borderRightWidth: 0,
	},
	border: {
		borderWidth: StyleSheet.hairlineWidth,
	},
	border_t: {
		borderTopWidth: StyleSheet.hairlineWidth,
	},
	border_b: {
		borderBottomWidth: StyleSheet.hairlineWidth,
	},
	border_l: {
		borderLeftWidth: StyleSheet.hairlineWidth,
	},
	border_r: {
		borderRightWidth: StyleSheet.hairlineWidth,
	},
	curve_circular: ios({
		borderCurve: 'circular',
	}),
	curve_continuous: ios({
		borderCurve: 'continuous',
	}),

	cursor_pointer: web({
		cursor: 'pointer',
	}),

	/*
	 * Shadow
	 */
	shadow_sm: {
		shadowRadius: 8,
		shadowOpacity: 0.1,
		elevation: 8,
	},
	shadow_md: {
		shadowRadius: 16,
		shadowOpacity: 0.1,
		elevation: 16,
	},
	shadow_lg: {
		shadowRadius: 32,
		shadowOpacity: 0.1,
		elevation: 24,
	},

	/*
	 * Padding
	 */
	p_0: {
		padding: 0,
	},
	p_2xs: {
		padding: space._2xs,
	},
	p_xs: {
		padding: space.xs,
	},
	p_sm: {
		padding: space.sm,
	},
	p_md: {
		padding: space.md,
	},
	p_lg: {
		padding: space.lg,
	},
	p_xl: {
		padding: space.xl,
	},
	p_2xl: {
		padding: space._2xl,
	},
	p_3xl: {
		padding: space._3xl,
	},
	p_4xl: {
		padding: space._4xl,
	},
	p_5xl: {
		padding: space._5xl,
	},
	px_0: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	px_2xs: {
		paddingLeft: space._2xs,
		paddingRight: space._2xs,
	},
	px_xs: {
		paddingLeft: space.xs,
		paddingRight: space.xs,
	},
	px_sm: {
		paddingLeft: space.sm,
		paddingRight: space.sm,
	},
	px_md: {
		paddingLeft: space.md,
		paddingRight: space.md,
	},
	px_lg: {
		paddingLeft: space.lg,
		paddingRight: space.lg,
	},
	px_xl: {
		paddingLeft: space.xl,
		paddingRight: space.xl,
	},
	px_2xl: {
		paddingLeft: space._2xl,
		paddingRight: space._2xl,
	},
	px_3xl: {
		paddingLeft: space._3xl,
		paddingRight: space._3xl,
	},
	px_4xl: {
		paddingLeft: space._4xl,
		paddingRight: space._4xl,
	},
	px_5xl: {
		paddingLeft: space._5xl,
		paddingRight: space._5xl,
	},
	py_0: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	py_2xs: {
		paddingTop: space._2xs,
		paddingBottom: space._2xs,
	},
	py_xs: {
		paddingTop: space.xs,
		paddingBottom: space.xs,
	},
	py_sm: {
		paddingTop: space.sm,
		paddingBottom: space.sm,
	},
	py_md: {
		paddingTop: space.md,
		paddingBottom: space.md,
	},
	py_lg: {
		paddingTop: space.lg,
		paddingBottom: space.lg,
	},
	py_xl: {
		paddingTop: space.xl,
		paddingBottom: space.xl,
	},
	py_2xl: {
		paddingTop: space._2xl,
		paddingBottom: space._2xl,
	},
	py_3xl: {
		paddingTop: space._3xl,
		paddingBottom: space._3xl,
	},
	py_4xl: {
		paddingTop: space._4xl,
		paddingBottom: space._4xl,
	},
	py_5xl: {
		paddingTop: space._5xl,
		paddingBottom: space._5xl,
	},
	pt_0: {
		paddingTop: 0,
	},
	pt_2xs: {
		paddingTop: space._2xs,
	},
	pt_xs: {
		paddingTop: space.xs,
	},
	pt_sm: {
		paddingTop: space.sm,
	},
	pt_md: {
		paddingTop: space.md,
	},
	pt_lg: {
		paddingTop: space.lg,
	},
	pt_xl: {
		paddingTop: space.xl,
	},
	pt_2xl: {
		paddingTop: space._2xl,
	},
	pt_3xl: {
		paddingTop: space._3xl,
	},
	pt_4xl: {
		paddingTop: space._4xl,
	},
	pt_5xl: {
		paddingTop: space._5xl,
	},
	pb_0: {
		paddingBottom: 0,
	},
	pb_2xs: {
		paddingBottom: space._2xs,
	},
	pb_xs: {
		paddingBottom: space.xs,
	},
	pb_sm: {
		paddingBottom: space.sm,
	},
	pb_md: {
		paddingBottom: space.md,
	},
	pb_lg: {
		paddingBottom: space.lg,
	},
	pb_xl: {
		paddingBottom: space.xl,
	},
	pb_2xl: {
		paddingBottom: space._2xl,
	},
	pb_3xl: {
		paddingBottom: space._3xl,
	},
	pb_4xl: {
		paddingBottom: space._4xl,
	},
	pb_5xl: {
		paddingBottom: space._5xl,
	},
	pl_0: {
		paddingLeft: 0,
	},
	pl_2xs: {
		paddingLeft: space._2xs,
	},
	pl_xs: {
		paddingLeft: space.xs,
	},
	pl_sm: {
		paddingLeft: space.sm,
	},
	pl_md: {
		paddingLeft: space.md,
	},
	pl_lg: {
		paddingLeft: space.lg,
	},
	pl_xl: {
		paddingLeft: space.xl,
	},
	pl_2xl: {
		paddingLeft: space._2xl,
	},
	pl_3xl: {
		paddingLeft: space._3xl,
	},
	pl_4xl: {
		paddingLeft: space._4xl,
	},
	pl_5xl: {
		paddingLeft: space._5xl,
	},
	pr_0: {
		paddingRight: 0,
	},
	pr_2xs: {
		paddingRight: space._2xs,
	},
	pr_xs: {
		paddingRight: space.xs,
	},
	pr_sm: {
		paddingRight: space.sm,
	},
	pr_md: {
		paddingRight: space.md,
	},
	pr_lg: {
		paddingRight: space.lg,
	},
	pr_xl: {
		paddingRight: space.xl,
	},
	pr_2xl: {
		paddingRight: space._2xl,
	},
	pr_3xl: {
		paddingRight: space._3xl,
	},
	pr_4xl: {
		paddingRight: space._4xl,
	},
	pr_5xl: {
		paddingRight: space._5xl,
	},

	/*
	 * Margin
	 */
	m_0: {
		margin: 0,
	},
	m_2xs: {
		margin: space._2xs,
	},
	m_xs: {
		margin: space.xs,
	},
	m_sm: {
		margin: space.sm,
	},
	m_md: {
		margin: space.md,
	},
	m_lg: {
		margin: space.lg,
	},
	m_xl: {
		margin: space.xl,
	},
	m_2xl: {
		margin: space._2xl,
	},
	m_3xl: {
		margin: space._3xl,
	},
	m_4xl: {
		margin: space._4xl,
	},
	m_5xl: {
		margin: space._5xl,
	},
	m_auto: {
		margin: 'auto',
	},
	mx_0: {
		marginLeft: 0,
		marginRight: 0,
	},
	mx_2xs: {
		marginLeft: space._2xs,
		marginRight: space._2xs,
	},
	mx_xs: {
		marginLeft: space.xs,
		marginRight: space.xs,
	},
	mx_sm: {
		marginLeft: space.sm,
		marginRight: space.sm,
	},
	mx_md: {
		marginLeft: space.md,
		marginRight: space.md,
	},
	mx_lg: {
		marginLeft: space.lg,
		marginRight: space.lg,
	},
	mx_xl: {
		marginLeft: space.xl,
		marginRight: space.xl,
	},
	mx_2xl: {
		marginLeft: space._2xl,
		marginRight: space._2xl,
	},
	mx_3xl: {
		marginLeft: space._3xl,
		marginRight: space._3xl,
	},
	mx_4xl: {
		marginLeft: space._4xl,
		marginRight: space._4xl,
	},
	mx_5xl: {
		marginLeft: space._5xl,
		marginRight: space._5xl,
	},
	mx_auto: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	my_0: {
		marginTop: 0,
		marginBottom: 0,
	},
	my_2xs: {
		marginTop: space._2xs,
		marginBottom: space._2xs,
	},
	my_xs: {
		marginTop: space.xs,
		marginBottom: space.xs,
	},
	my_sm: {
		marginTop: space.sm,
		marginBottom: space.sm,
	},
	my_md: {
		marginTop: space.md,
		marginBottom: space.md,
	},
	my_lg: {
		marginTop: space.lg,
		marginBottom: space.lg,
	},
	my_xl: {
		marginTop: space.xl,
		marginBottom: space.xl,
	},
	my_2xl: {
		marginTop: space._2xl,
		marginBottom: space._2xl,
	},
	my_3xl: {
		marginTop: space._3xl,
		marginBottom: space._3xl,
	},
	my_4xl: {
		marginTop: space._4xl,
		marginBottom: space._4xl,
	},
	my_5xl: {
		marginTop: space._5xl,
		marginBottom: space._5xl,
	},
	my_auto: {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	mt_0: {
		marginTop: 0,
	},
	mt_2xs: {
		marginTop: space._2xs,
	},
	mt_xs: {
		marginTop: space.xs,
	},
	mt_sm: {
		marginTop: space.sm,
	},
	mt_md: {
		marginTop: space.md,
	},
	mt_lg: {
		marginTop: space.lg,
	},
	mt_xl: {
		marginTop: space.xl,
	},
	mt_2xl: {
		marginTop: space._2xl,
	},
	mt_3xl: {
		marginTop: space._3xl,
	},
	mt_4xl: {
		marginTop: space._4xl,
	},
	mt_5xl: {
		marginTop: space._5xl,
	},
	mt_auto: {
		marginTop: 'auto',
	},
	mb_0: {
		marginBottom: 0,
	},
	mb_2xs: {
		marginBottom: space._2xs,
	},
	mb_xs: {
		marginBottom: space.xs,
	},
	mb_sm: {
		marginBottom: space.sm,
	},
	mb_md: {
		marginBottom: space.md,
	},
	mb_lg: {
		marginBottom: space.lg,
	},
	mb_xl: {
		marginBottom: space.xl,
	},
	mb_2xl: {
		marginBottom: space._2xl,
	},
	mb_3xl: {
		marginBottom: space._3xl,
	},
	mb_4xl: {
		marginBottom: space._4xl,
	},
	mb_5xl: {
		marginBottom: space._5xl,
	},
	mb_auto: {
		marginBottom: 'auto',
	},
	ml_0: {
		marginLeft: 0,
	},
	ml_2xs: {
		marginLeft: space._2xs,
	},
	ml_xs: {
		marginLeft: space.xs,
	},
	ml_sm: {
		marginLeft: space.sm,
	},
	ml_md: {
		marginLeft: space.md,
	},
	ml_lg: {
		marginLeft: space.lg,
	},
	ml_xl: {
		marginLeft: space.xl,
	},
	ml_2xl: {
		marginLeft: space._2xl,
	},
	ml_3xl: {
		marginLeft: space._3xl,
	},
	ml_4xl: {
		marginLeft: space._4xl,
	},
	ml_5xl: {
		marginLeft: space._5xl,
	},
	ml_auto: {
		marginLeft: 'auto',
	},
	mr_0: {
		marginRight: 0,
	},
	mr_2xs: {
		marginRight: space._2xs,
	},
	mr_xs: {
		marginRight: space.xs,
	},
	mr_sm: {
		marginRight: space.sm,
	},
	mr_md: {
		marginRight: space.md,
	},
	mr_lg: {
		marginRight: space.lg,
	},
	mr_xl: {
		marginRight: space.xl,
	},
	mr_2xl: {
		marginRight: space._2xl,
	},
	mr_3xl: {
		marginRight: space._3xl,
	},
	mr_4xl: {
		marginRight: space._4xl,
	},
	mr_5xl: {
		marginRight: space._5xl,
	},
	mr_auto: {
		marginRight: 'auto',
	},

	/*
	 * Pointer events & user select
	 */
	pointer_events_none: {
		pointerEvents: 'none',
	},
	pointer_events_auto: {
		pointerEvents: 'auto',
	},
	user_select_none: {
		userSelect: 'none',
	},
	user_select_text: {
		userSelect: 'text',
	},
	user_select_all: {
		userSelect: 'all',
	},
	outline_inset_1: {
		outlineOffset: '-1px',
	},

	/*
	 * Text decoration
	 */
	underline: {
		textDecorationLine: 'underline',
	},
	strike_through: {
		textDecorationLine: 'line-through',
	},

	/*
	 * Display
	 */
	hidden: {
		display: 'none',
	},

	/*
	 * Transition
	 */
	transition_none: web({
		transitionProperty: 'none',
	}),
	transition_all: web({
		transitionProperty: 'all',
		transitionTimingFunction: 'cubic-bezier(0.17, 0.73, 0.14, 1)',
		transitionDuration: '100ms',
	}),
	transition_color: web({
		transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke',
		transitionTimingFunction: 'cubic-bezier(0.17, 0.73, 0.14, 1)',
		transitionDuration: '100ms',
	}),
	transition_opacity: web({
		transitionProperty: 'opacity',
		transitionTimingFunction: 'cubic-bezier(0.17, 0.73, 0.14, 1)',
		transitionDuration: '100ms',
	}),
	transition_transform: web({
		transitionProperty: 'transform',
		transitionTimingFunction: 'cubic-bezier(0.17, 0.73, 0.14, 1)',
		transitionDuration: '100ms',
	}),
	transition_delay_50ms: web({
		transitionDelay: '50ms',
	}),

	/*
	 * Animaations
	 */
	fade_in: web({
		animation: 'fadeIn ease-out 0.15s',
	}),
	fade_out: web({
		animation: 'fadeOut ease-out 0.15s',
	}),
	zoom_in: web({
		animation: 'zoomIn ease-out 0.1s',
	}),
	zoom_out: web({
		animation: 'zoomOut ease-out 0.1s',
	}),
	slide_in_left: web({
		// exponential easing function
		animation: 'slideInLeft cubic-bezier(0.16, 1, 0.3, 1) 0.5s',
	}),
	slide_out_left: web({
		animation: 'slideOutLeft ease-in 0.15s',
		animationFillMode: 'forwards',
	}),
	// special composite animation for dialogs
	zoom_fade_in: web({
		animation: 'zoomIn ease-out 0.1s, fadeIn ease-out 0.1s',
	}),

	/**
	 * {@link Layout.SCROLLBAR_OFFSET}
	 */
	scrollbar_offset: platform({
		web: {
			transform: [
				{
					translateX: Layout.SCROLLBAR_OFFSET,
				},
			],
		},
		native: {
			transform: [],
		},
	}),
}

export const BLUE_HUE = 211
export const RED_HUE = 3
export const GREEN_HUE = 135

/**
 * Smooth progression of lightness "stops" for generating HSL colors.
 */
export const COLOR_STOPS = [0, 0.05, 0.1, 0.15, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.85, 0.9, 0.95, 1] // 15

export function generateScale(start, end) {
	const range = end - start
	return COLOR_STOPS.map((stop) => {
		return start + range * stop
	})
}

export const defaultScale = generateScale(6, 100)
// dim shifted 6% lighter
export const dimScale = generateScale(12, 100)

const themes = createThemes({
	hues: {
		primary: BLUE_HUE,
		negative: RED_HUE,
		positive: GREEN_HUE,
	},
})

export function createThemes({hues}) {
	const color = {
		like: '#ec4899',
		trueBlack: '#000000',

		gray_0: `hsl(${hues.primary}, 12%, ${defaultScale[14]}%)`,
		gray_25: `hsl(${hues.primary}, 20%, ${defaultScale[13]}%)`,
		gray_50: `hsl(${hues.primary}, 20%, ${defaultScale[13]}%)`,
		gray_100: `hsl(${hues.primary}, 20%, ${defaultScale[12]}%)`,
		gray_200: `hsl(${hues.primary}, 20%, ${defaultScale[11]}%)`,
		gray_300: `hsl(${hues.primary}, 20%, ${defaultScale[10]}%)`,
		gray_400: `hsl(${hues.primary}, 20%, ${defaultScale[9]}%)`,
		gray_500: `hsl(${hues.primary}, 20%, ${defaultScale[8]}%)`,
		gray_600: `hsl(${hues.primary}, 24%, ${defaultScale[7]}%)`,
		gray_700: `hsl(${hues.primary}, 24%, ${defaultScale[6]}%)`,
		gray_800: `hsl(${hues.primary}, 28%, ${defaultScale[5]}%)`,
		gray_900: `hsl(${hues.primary}, 28%, ${defaultScale[4]}%)`,
		gray_950: `hsl(${hues.primary}, 28%, ${defaultScale[3]}%)`,
		gray_975: `hsl(${hues.primary}, 28%, ${defaultScale[2]}%)`,
		gray_1000: `hsl(${hues.primary}, 28%, ${defaultScale[1]}%)`,

		primary: `hsl(${hues.primary}, 99%, 53%)`,
		primary_25: `hsl(${hues.primary}, 99%, 97%)`,
		primary_50: `hsl(${hues.primary}, 99%, 95%)`,
		primary_100: `hsl(${hues.primary}, 99%, 90%)`,
		primary_200: `hsl(${hues.primary}, 99%, 80%)`,
		primary_300: `hsl(${hues.primary}, 99%, 70%)`,
		primary_400: `hsl(${hues.primary}, 99%, 60%)`,
		primary_500: `hsl(${hues.primary}, 99%, 53%)`,
		primary_600: `hsl(${hues.primary}, 99%, 42%)`,
		primary_700: `hsl(${hues.primary}, 99%, 34%)`,
		primary_800: `hsl(${hues.primary}, 99%, 26%)`,
		primary_900: `hsl(${hues.primary}, 99%, 18%)`,
		primary_950: `hsl(${hues.primary}, 99%, 10%)`,
		primary_975: `hsl(${hues.primary}, 99%, 7%)`,

		green_25: `hsl(${hues.positive},  70%, 97%)`,
		green_50: `hsl(${hues.positive},  70%, 95%)`,
		green_100: `hsl(${hues.positive}, 70%, 90%)`,
		green_200: `hsl(${hues.positive}, 70%, 80%)`,
		green_300: `hsl(${hues.positive}, 70%, 70%)`,
		green_400: `hsl(${hues.positive}, 70%, 60%)`,
		green_500: `hsl(${hues.positive}, 70%, 50%)`,
		green_600: `hsl(${hues.positive}, 70%, 42%)`,
		green_700: `hsl(${hues.positive}, 70%, 34%)`,
		green_800: `hsl(${hues.positive}, 70%, 26%)`,
		green_900: `hsl(${hues.positive}, 70%, 18%)`,
		green_950: `hsl(${hues.positive}, 70%, 10%)`,
		green_975: `hsl(${hues.positive}, 70%, 7%)`,

		red_25: `hsl(${hues.negative}, 91%, 97%)`,
		red_50: `hsl(${hues.negative}, 91%, 95%)`,
		red_100: `hsl(${hues.negative}, 91%, 90%)`,
		red_200: `hsl(${hues.negative}, 91%, 80%)`,
		red_300: `hsl(${hues.negative}, 91%, 70%)`,
		red_400: `hsl(${hues.negative}, 91%, 60%)`,
		red_500: `hsl(${hues.negative}, 91%, 50%)`,
		red_600: `hsl(${hues.negative}, 91%, 42%)`,
		red_700: `hsl(${hues.negative}, 91%, 34%)`,
		red_800: `hsl(${hues.negative}, 91%, 26%)`,
		red_900: `hsl(${hues.negative}, 91%, 18%)`,
		red_950: `hsl(${hues.negative}, 91%, 10%)`,
		red_975: `hsl(${hues.negative}, 91%, 7%)`,
	}

	const lightPalette = {
		white: color.gray_0,
		black: color.gray_1000,
		like: color.like,

		contrast_25: color.gray_25,
		contrast_50: color.gray_50,
		contrast_100: color.gray_100,
		contrast_200: color.gray_200,
		contrast_300: color.gray_300,
		contrast_400: color.gray_400,
		contrast_500: color.gray_500,
		contrast_600: color.gray_600,
		contrast_700: color.gray_700,
		contrast_800: color.gray_800,
		contrast_900: color.gray_900,
		contrast_950: color.gray_950,
		contrast_975: color.gray_975,

		primary: color.primary_500,
		primary_25: color.primary_25,
		primary_50: color.primary_50,
		primary_100: color.primary_100,
		primary_200: color.primary_200,
		primary_300: color.primary_300,
		primary_400: color.primary_400,
		primary_500: color.primary_500,
		primary_600: color.primary_600,
		primary_700: color.primary_700,
		primary_800: color.primary_800,
		primary_900: color.primary_900,
		primary_950: color.primary_950,
		primary_975: color.primary_975,

		positive_25: color.green_25,
		positive_50: color.green_50,
		positive_100: color.green_100,
		positive_200: color.green_200,
		positive_300: color.green_300,
		positive_400: color.green_400,
		positive_500: color.green_500,
		positive_600: color.green_600,
		positive_700: color.green_700,
		positive_800: color.green_800,
		positive_900: color.green_900,
		positive_950: color.green_950,
		positive_975: color.green_975,

		negative_25: color.red_25,
		negative_50: color.red_50,
		negative_100: color.red_100,
		negative_200: color.red_200,
		negative_300: color.red_300,
		negative_400: color.red_400,
		negative_500: color.red_500,
		negative_600: color.red_600,
		negative_700: color.red_700,
		negative_800: color.red_800,
		negative_900: color.red_900,
		negative_950: color.red_950,
		negative_975: color.red_975,
	}

	const darkPalette = {
		white: color.gray_25,
		black: color.trueBlack,
		like: color.like,

		contrast_25: color.gray_975,
		contrast_50: color.gray_950,
		contrast_100: color.gray_900,
		contrast_200: color.gray_800,
		contrast_300: color.gray_700,
		contrast_400: color.gray_600,
		contrast_500: color.gray_500,
		contrast_600: color.gray_400,
		contrast_700: color.gray_300,
		contrast_800: color.gray_200,
		contrast_900: color.gray_100,
		contrast_950: color.gray_50,
		contrast_975: color.gray_25,

		primary: color.primary_500,
		primary_25: color.primary_975,
		primary_50: color.primary_950,
		primary_100: color.primary_900,
		primary_200: color.primary_800,
		primary_300: color.primary_700,
		primary_400: color.primary_600,
		primary_500: color.primary_500,
		primary_600: color.primary_400,
		primary_700: color.primary_300,
		primary_800: color.primary_200,
		primary_900: color.primary_100,
		primary_950: color.primary_50,
		primary_975: color.primary_25,

		positive_25: color.green_975,
		positive_50: color.green_950,
		positive_100: color.green_900,
		positive_200: color.green_800,
		positive_300: color.green_700,
		positive_400: color.green_600,
		positive_500: color.green_500,
		positive_600: color.green_400,
		positive_700: color.green_300,
		positive_800: color.green_200,
		positive_900: color.green_100,
		positive_950: color.green_50,
		positive_975: color.green_25,

		negative_25: color.red_975,
		negative_50: color.red_950,
		negative_100: color.red_900,
		negative_200: color.red_800,
		negative_300: color.red_700,
		negative_400: color.red_600,
		negative_500: color.red_500,
		negative_600: color.red_400,
		negative_700: color.red_300,
		negative_800: color.red_200,
		negative_900: color.red_100,
		negative_950: color.red_50,
		negative_975: color.red_25,
	}

	const dimPalette = {
		...darkPalette,
		black: `hsl(${hues.primary}, 28%, ${dimScale[0]}%)`,
		like: color.like,

		contrast_25: `hsl(${hues.primary}, 28%, ${dimScale[1]}%)`,
		contrast_50: `hsl(${hues.primary}, 28%, ${dimScale[2]}%)`,
		contrast_100: `hsl(${hues.primary}, 28%, ${dimScale[3]}%)`,
		contrast_200: `hsl(${hues.primary}, 28%, ${dimScale[4]}%)`,
		contrast_300: `hsl(${hues.primary}, 24%, ${dimScale[5]}%)`,
		contrast_400: `hsl(${hues.primary}, 24%, ${dimScale[6]}%)`,
		contrast_500: `hsl(${hues.primary}, 20%, ${dimScale[7]}%)`,
		contrast_600: `hsl(${hues.primary}, 20%, ${dimScale[8]}%)`,
		contrast_700: `hsl(${hues.primary}, 20%, ${dimScale[9]}%)`,
		contrast_800: `hsl(${hues.primary}, 20%, ${dimScale[10]}%)`,
		contrast_900: `hsl(${hues.primary}, 20%, ${dimScale[11]}%)`,
		contrast_950: `hsl(${hues.primary}, 20%, ${dimScale[12]}%)`,
		contrast_975: `hsl(${hues.primary}, 20%, ${dimScale[13]}%)`,

		primary: `hsl(${hues.primary}, 99%, ${dimScale[7]}%)`,
		primary_25: `hsl(${hues.primary}, 50%, ${dimScale[1]}%)`,
		primary_50: `hsl(${hues.primary}, 60%, ${dimScale[2]}%)`,
		primary_100: `hsl(${hues.primary}, 70%, ${dimScale[3]}%)`,
		primary_200: `hsl(${hues.primary}, 82%, ${dimScale[4]}%)`,
		primary_300: `hsl(${hues.primary}, 90%, ${dimScale[5]}%)`,
		primary_400: `hsl(${hues.primary}, 95%, ${dimScale[6]}%)`,
		primary_500: `hsl(${hues.primary}, 99%, ${dimScale[7]}%)`,
		primary_600: `hsl(${hues.primary}, 99%, ${dimScale[8]}%)`,
		primary_700: `hsl(${hues.primary}, 99%, ${dimScale[9]}%)`,
		primary_800: `hsl(${hues.primary}, 99%, ${dimScale[10]}%)`,
		primary_900: `hsl(${hues.primary}, 99%, ${dimScale[11]}%)`,
		primary_950: `hsl(${hues.primary}, 99%, ${dimScale[12]}%)`,
		primary_975: `hsl(${hues.primary}, 99%, ${dimScale[13]}%)`,

		positive_25: `hsl(${hues.positive}, 50%, ${dimScale[1]}%)`,
		positive_50: `hsl(${hues.positive}, 60%, ${dimScale[2]}%)`,
		positive_100: `hsl(${hues.positive}, 70%, ${dimScale[3]}%)`,
		positive_200: `hsl(${hues.positive}, 82%, ${dimScale[4]}%)`,
		positive_300: `hsl(${hues.positive}, 82%, ${dimScale[5]}%)`,
		positive_400: `hsl(${hues.positive}, 82%, ${dimScale[6]}%)`,
		positive_500: `hsl(${hues.positive}, 82%, ${dimScale[7]}%)`,
		positive_600: `hsl(${hues.positive}, 82%, ${dimScale[8]}%)`,
		positive_700: `hsl(${hues.positive}, 82%, ${dimScale[9]}%)`,
		positive_800: `hsl(${hues.positive}, 82%, ${dimScale[10]}%)`,
		positive_900: `hsl(${hues.positive}, 82%, ${dimScale[11]}%)`,
		positive_950: `hsl(${hues.positive}, 82%, ${dimScale[12]}%)`,
		positive_975: `hsl(${hues.positive}, 82%, ${dimScale[13]}%)`,

		negative_25: `hsl(${hues.negative}, 70%, ${dimScale[1]}%)`,
		negative_50: `hsl(${hues.negative}, 80%, ${dimScale[2]}%)`,
		negative_100: `hsl(${hues.negative}, 84%, ${dimScale[3]}%)`,
		negative_200: `hsl(${hues.negative}, 88%, ${dimScale[4]}%)`,
		negative_300: `hsl(${hues.negative}, 91%, ${dimScale[5]}%)`,
		negative_400: `hsl(${hues.negative}, 91%, ${dimScale[6]}%)`,
		negative_500: `hsl(${hues.negative}, 91%, ${dimScale[7]}%)`,
		negative_600: `hsl(${hues.negative}, 91%, ${dimScale[8]}%)`,
		negative_700: `hsl(${hues.negative}, 91%, ${dimScale[9]}%)`,
		negative_800: `hsl(${hues.negative}, 91%, ${dimScale[10]}%)`,
		negative_900: `hsl(${hues.negative}, 91%, ${dimScale[11]}%)`,
		negative_950: `hsl(${hues.negative}, 91%, ${dimScale[12]}%)`,
		negative_975: `hsl(${hues.negative}, 91%, ${dimScale[13]}%)`,
	}

	// theme
	const light = {
		scheme: 'light',
		name: 'light',
		color: color,
		palette: lightPalette,
		atoms: {
			text: {
				color: lightPalette.black,
			},
			text_muted: {
				color: lightPalette.contrast_600,
			},
			text_primary: {
				color: lightPalette.primary_500,
			},
			text_contrast_low: {
				color: lightPalette.contrast_400,
			},
			text_contrast_medium: {
				color: lightPalette.contrast_700,
			},
			text_contrast_high: {
				color: lightPalette.contrast_900,
			},
			text_inverted: {
				color: lightPalette.white,
			},
			bg: {
				backgroundColor: lightPalette.white,
			},
			bg_contrast_25: {
				backgroundColor: lightPalette.contrast_25,
			},
			bg_contrast_50: {
				backgroundColor: lightPalette.contrast_50,
			},
			bg_contrast_100: {
				backgroundColor: lightPalette.contrast_100,
			},
			bg_contrast_200: {
				backgroundColor: lightPalette.contrast_200,
			},
			bg_contrast_300: {
				backgroundColor: lightPalette.contrast_300,
			},
			bg_contrast_400: {
				backgroundColor: lightPalette.contrast_400,
			},
			bg_contrast_500: {
				backgroundColor: lightPalette.contrast_500,
			},
			bg_contrast_600: {
				backgroundColor: lightPalette.contrast_600,
			},
			bg_contrast_700: {
				backgroundColor: lightPalette.contrast_700,
			},
			bg_contrast_800: {
				backgroundColor: lightPalette.contrast_800,
			},
			bg_contrast_900: {
				backgroundColor: lightPalette.contrast_900,
			},
			bg_contrast_950: {
				backgroundColor: lightPalette.contrast_950,
			},
			bg_contrast_975: {
				backgroundColor: lightPalette.contrast_975,
			},
			border_contrast_low: {
				borderColor: lightPalette.contrast_100,
			},
			border_contrast_medium: {
				borderColor: lightPalette.contrast_200,
			},
			border_contrast_high: {
				borderColor: lightPalette.contrast_300,
			},
			shadow_sm: {
				...atoms.shadow_sm,
				shadowColor: lightPalette.black,
			},
			shadow_md: {
				...atoms.shadow_md,
				shadowColor: lightPalette.black,
			},
			shadow_lg: {
				...atoms.shadow_lg,
				shadowColor: lightPalette.black,
			},
		},
	}

	const dark = {
		scheme: 'dark',
		name: 'dark',
		palette: darkPalette,
		atoms: {
			text: {
				color: darkPalette.white,
			},
			text_muted: {
				color: darkPalette.contrast_600,
			},
			text_primary: {
				color: darkPalette.primary_500,
			},
			text_contrast_low: {
				color: darkPalette.contrast_400,
			},
			text_contrast_medium: {
				color: darkPalette.contrast_600,
			},
			text_contrast_high: {
				color: darkPalette.contrast_900,
			},
			text_inverted: {
				color: darkPalette.black,
			},
			bg: {
				backgroundColor: darkPalette.black,
			},
			bg_contrast_25: {
				backgroundColor: darkPalette.contrast_25,
			},
			bg_contrast_50: {
				backgroundColor: darkPalette.contrast_50,
			},
			bg_contrast_100: {
				backgroundColor: darkPalette.contrast_100,
			},
			bg_contrast_200: {
				backgroundColor: darkPalette.contrast_200,
			},
			bg_contrast_300: {
				backgroundColor: darkPalette.contrast_300,
			},
			bg_contrast_400: {
				backgroundColor: darkPalette.contrast_400,
			},
			bg_contrast_500: {
				backgroundColor: darkPalette.contrast_500,
			},
			bg_contrast_600: {
				backgroundColor: darkPalette.contrast_600,
			},
			bg_contrast_700: {
				backgroundColor: darkPalette.contrast_700,
			},
			bg_contrast_800: {
				backgroundColor: darkPalette.contrast_800,
			},
			bg_contrast_900: {
				backgroundColor: darkPalette.contrast_900,
			},
			bg_contrast_950: {
				backgroundColor: darkPalette.contrast_950,
			},
			bg_contrast_975: {
				backgroundColor: darkPalette.contrast_975,
			},
			border_contrast_low: {
				borderColor: darkPalette.contrast_100,
			},
			border_contrast_medium: {
				borderColor: darkPalette.contrast_200,
			},
			border_contrast_high: {
				borderColor: darkPalette.contrast_300,
			},
			shadow_sm: {
				...atoms.shadow_sm,
				shadowOpacity: 0.7,
				shadowColor: color.trueBlack,
			},
			shadow_md: {
				...atoms.shadow_md,
				shadowOpacity: 0.7,
				shadowColor: color.trueBlack,
			},
			shadow_lg: {
				...atoms.shadow_lg,
				shadowOpacity: 0.7,
				shadowColor: color.trueBlack,
			},
		},
	}

	const dim = {
		...dark,
		scheme: 'dark',
		name: 'dim',
		palette: dimPalette,
		atoms: {
			...dark.atoms,
			text: {
				color: dimPalette.white,
			},
			text_muted: {
				color: dimPalette.contrast_600,
			},
			text_primary: {
				color: dimPalette.primary_500,
			},
			text_contrast_low: {
				color: dimPalette.contrast_400,
			},
			text_contrast_medium: {
				color: dimPalette.contrast_600,
			},
			text_contrast_high: {
				color: dimPalette.contrast_900,
			},
			text_inverted: {
				color: dimPalette.black,
			},
			bg: {
				backgroundColor: dimPalette.black,
			},
			bg_contrast_25: {
				backgroundColor: dimPalette.contrast_25,
			},
			bg_contrast_50: {
				backgroundColor: dimPalette.contrast_50,
			},
			bg_contrast_100: {
				backgroundColor: dimPalette.contrast_100,
			},
			bg_contrast_200: {
				backgroundColor: dimPalette.contrast_200,
			},
			bg_contrast_300: {
				backgroundColor: dimPalette.contrast_300,
			},
			bg_contrast_400: {
				backgroundColor: dimPalette.contrast_400,
			},
			bg_contrast_500: {
				backgroundColor: dimPalette.contrast_500,
			},
			bg_contrast_600: {
				backgroundColor: dimPalette.contrast_600,
			},
			bg_contrast_700: {
				backgroundColor: dimPalette.contrast_700,
			},
			bg_contrast_800: {
				backgroundColor: dimPalette.contrast_800,
			},
			bg_contrast_900: {
				backgroundColor: dimPalette.contrast_900,
			},
			bg_contrast_950: {
				backgroundColor: dimPalette.contrast_950,
			},
			bg_contrast_975: {
				backgroundColor: dimPalette.contrast_975,
			},
			border_contrast_low: {
				borderColor: dimPalette.contrast_100,
			},
			border_contrast_medium: {
				borderColor: dimPalette.contrast_200,
			},
			border_contrast_high: {
				borderColor: dimPalette.contrast_300,
			},
			shadow_sm: {
				...atoms.shadow_sm,
				shadowOpacity: 0.7,
				shadowColor: `hsl(${hues.primary}, 28%, 6%)`,
			},
			shadow_md: {
				...atoms.shadow_md,
				shadowOpacity: 0.7,
				shadowColor: `hsl(${hues.primary}, 28%, 6%)`,
			},
			shadow_lg: {
				...atoms.shadow_lg,
				shadowOpacity: 0.7,
				shadowColor: `hsl(${hues.primary}, 28%, 6%)`,
			},
		},
	}

	return {
		lightPalette, // palette
		darkPalette, // palette
		dimPalette, // palette
		light, // theme
		dark, // theme
		dim, // theme
	}
}

// name: light, dark, dim
export function changeTheme(name) {
	defaultTheme = themes[name] || themes.light
	Object.assign(atoms, defaultTheme.atoms)
	Object.assign(color, defaultTheme.color, defaultTheme.palette)
	console.log('COLOR', color)
}

var defaultTheme = themes.light
changeTheme('light')
