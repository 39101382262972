const ENUSLang = () => import(/*webpackChunkName: "en-US" */ '../subiz/lang/en-US.json')
const VIVNLang = () => import(/*webpackChunkName: "vi-VN" */ '../subiz/lang/vi-VN.json')

// import VIVNLang from '../lang/vi-VN.json'
// import ENUSLang from '../lang/en-US.json'
// donot import store or sb to this file

import {enGB, vi} from 'date-fns/locale'
let en = enGB

export const getDateFnsLocale = () => (currentLang === 'vi' ? vi : en)

var currentLang
export const getCurrentLanguage = () => currentLang

// changeLanguage update UI text language based on code
// Note: if ?lang= query is used, this function ignore the parameter
export const changeLanguage = async (code) => {
	// prefer ?lang= query parameter
	const urlParams = new URLSearchParams(window.location.search)
	let langquery = urlParams.get('lang')
	if (langquery === 'debug') {
		currentLang = 'debug'
		return
	}
	code = langquery || code
	if (!code) code = getCookieValue('s_cookie_lang')
	if (!code) code = navigator.language
	let lang = ['vi', 'en'].find((lang) => lang === code) || 'vi'

	if (lang === currentLang) return
	currentLang = lang

	let translation = {}
	if (lang === 'vi') translation = (await VIVNLang()) || {}
	if (lang === 'en') translation = (await ENUSLang()) || {}

	// avoid translate key "default" cause return an object instead of string
	// because await dynamic loading return a Module and Module.default is a method
	if (translation[Symbol.toStringTag] === 'Module') translation = translation.default
	await i18next.init({
		lng: lang,
		debug: false,
		resources: {[lang]: {translation: translation}},
	})
}

function i18nText(i18n_object, fallbackText, forceLang) {
	let lang = currentLang
	if (lang === 'vi') lang = 'vi_VN'
	if (lang === 'en') lang = 'en_US'
	if (forceLang) lang = forceLang

	if (!i18n_object) return fallbackText || ''
	return i18n_object[lang] || fallbackText || ''
}

function t(key, b, c, d) {
	if (!i18next) return key
	var val = i18next.t(key, b, c, d)
	if (val === undefined) {
		// falback to lowercase before giveup
		key = key || ''
		key = key.trim().toLowerCase()
		val = i18next.t(key, b, c, d)
	}
	if (val === undefined) return key
	return val
}
export const i18n = {t: t, i18nText}

// import Vue from 'vue'

if (typeof Vue !== 'undefined') {
	Vue.mixin({
		methods: {
			$t: function (key, b, c, d) {
				if (currentLang === 'debug') return '{{' + key + '}}'
				return t(key, b, c, d)
			},
			$i18nText: i18nText,
		},
	})
}

function getCookieValue(a) {
	var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)')
	return b ? b.pop() : ''
}

window.i18n = i18n
