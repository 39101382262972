const ajax = require('@subiz/ajax/src/ajax.js')
if (navigator.product !== 'ReactNative' && typeof window != 'undefined') {
	ajax.env.XMLHttpRequest = window.XMLHttpRequest
}

const config = require('@sb/config')
const sb = require('@sb/util')
let tabid = sb.randomString(30) // unique id for each tab
let _shouldlog = false

let kv = {
	data: {},
	match(key) {
		let inmem = this.data[key]
		if (inmem) return inmem
		let val = window.myLocalStorage.getItem(key)
		if (!val) return
		this.data[key] = JSON.parse(val)
		return this.data[key]
	},
	upsert(key, value) {
		this.data[key] = value
		window.myLocalStorage.setItem(key, JSON.stringify(value))
	},
}

let DESKTOP_APP_V = ''
if (process.env.ENV === 'desktop') {
	if (
		window.electronAPI &&
		window.electronAPI.onDesktopAppVersion &&
		typeof window.electronAPI.onDesktopAppVersion === 'function'
	) {
		console.log('onDesktopAppVersion 11111111111111')
		window.electronAPI.onDesktopAppVersion((v) => {
			console.log('onDesktopAppVersion 222222222222222', v)
			DESKTOP_APP_V = v
		})
	}
}

const plainapireq = ajax
	.setBaseUrl(config.ApiURL)
	.withCredentials(true)
	.setContentType('text/plain')
	.beforeHook(switchHost)
	.beforeHook(jsonifyTextPlain)
	.beforeHook(attachDesktopVersion)
	.setParser('json')
// .afterHook(receiveHostHint)
// log request
const logreq = ajax.setBaseUrl('https://track.subiz.net').withCredentials(true).setContentType('text/plain')

// authorized api request
// DEPRECATED: should use this.api41 instead
const apireq = ajax
	.setBaseUrl(config.ApiURL)
	.withCredentials(true)
	.setContentType('text/plain')
	.beforeHook(switchHost)
	.beforeHook(jsonifyTextPlain)
	.setParser('json')
	.beforeHook(logApiCall)
	.beforeHook(attachAccessToken)
	.beforeHook(attachUpdatedFields)
	.beforeHook(attachDesktopVersion)
	.afterHook(retryOnInvalidToken)
//.afterHook(receiveHostHint)

const widgetapireq = ajax
	.setBaseUrl('https://api.sbz.vn/4.0/')
	.setContentType('text/plain')
	.setParser('json')
	.beforeHook(jsonifyTextPlain)

// must refresh token before its expired
// do not access db.Account.me in this funciton
// DEPRECATED: should use this.api41 instead
function attachAccessToken(param, done) {
	var cred = kv.match('cred') || {}
	// we need to refresh access token if the access token is expired or about to expire
	// or we have no access token at all
	if (cred.access_token) {
		param.request = param.request.addQuery('x-access-token', cred.access_token)
	}

	let accid
	if (param.request.baseurl.indexOf('/accounts/') == -1) {
		accid = kv.match('account_id')
	} else {
		accid = param.request.baseurl.split('/accounts/')[1] || ''
		accid = lo.trim(accid.split('/')[0])
	}
	if (accid) param.request = param.request.addQuery('account-id', accid)
	return done(true)
}

function switchHost(param, done) {
	let accid
	if (param.request.baseurl.indexOf('/accounts/') == -1) {
		accid = kv.match('account_id')
	} else {
		accid = param.request.baseurl.split('/accounts/')[1] || ''
		accid = lo.trim(accid.split('/')[0])
	}

	if (accid != 'acpxkgumifuoofoosble') return done(true)

	if (param.request.baseurl.startsWith(config.ApiURL)) {
		param.request = param.request.setBaseUrl(
			param.request.baseurl.replace(config.ApiURL, 'https://api5.subiz.com.vn/4.0/', 1),
		)
	}
	if (param.request.baseurl.startsWith(config.ApiURL41)) {
		param.request = param.request.setBaseUrl(
			param.request.baseurl.replace(config.ApiURL41, 'https://api5.subiz.com.vn/4.1/', 1),
		)
	}
	return done(true)
}

function attachDesktopVersion(param, done) {
	if (process.env.ENV !== 'desktop') {
		return done(true)
	}

	param.request = param.request.addQuery('x-desktop-app-version', DESKTOP_APP_V)
	return done(true)
}

function attachUpdatedFields(param, done) {
	let body = lo.get(param, 'request.body')
	try {
		body = JSON.parse(body)
	} catch (err) {
		body = {}
	}
	let fields = body._update_fields || []
	fields = fields.join(',')
	if (fields) {
		param.request = param.request.addQuery('x-fields', fields)
	}
	return done(true)
}

function jsonifyTextPlain(param, done) {
	let req = param.request
	if (req.content_type === 'text/plain' && req.body !== undefined) req.body = JSON.stringify(req.body)
	done(true)
}

function retryOnInvalidToken(param, cb) {
	if (param.code !== 400) return cb()
	var body = {}
	try {
		body = JSON.parse(param.body)
	} catch (err) {}

	let errcode = body.code
	if (typeof errcode === 'string' && errcode.indexOf('access_token_expired') > -1) {
		apiObj.setExpiredTokenState(true)
	}
	//if (errcode === 'invalid_access_token' || errcode === 'invalid_credential') {
	//apiObj.onLogout && apiObj.onLogout()
	//cb()
	//}

	return cb()
}

function logApiCall(param, done) {
	if (!_shouldlog && !window._debug_log_mobile) return done(true)
	var req = param.request
	var cred = kv.match('cred') || {}
	console.log('🥝 ' + req.method + ' ' + req.baseurl)
	done(true)
}

function retryOnWrongHost(param, cb) {
	if (param.code !== 400) return cb()
	var body = {}
	try {
		body = JSON.parse(param.body)
	} catch (err) {}

	var errcode = body.code
	if (errcode === 'invalid_url') {
		cb()
	}

	return cb()
}

function Api() {
	this.makeAccessToken = async () => (kv.match('cred') || {}).access_token || ''
	this.attachAccessToken = (param, done) => {
		var cred = kv.match('cred') || {}
		// we need to refresh access token if the access token is expired or about to expire
		// or we have no access token at all
		if (cred.access_token) {
			param.request = param.request.addQuery('x-access-token', cred.access_token)
		}

		if (!param.request.query || param.request.query['account-id'] == undefined) {
			var accid = kv.match('account_id')
			if (accid) {
				param.request = param.request.addQuery('account-id', accid)
			}
		}
		return done(true)
	}
	// authorized api request
	this.api41 = ajax
		.setBaseUrl(config.ApiURL41)
		.withCredentials(true)
		.setContentType('text/plain')
		.beforeHook(switchHost)
		.beforeHook(jsonifyTextPlain)
		.beforeHook(this.attachAccessToken)
		.setParser('json')
		.beforeHook(logApiCall)
		.beforeHook(attachUpdatedFields)
		.beforeHook(attachDesktopVersion)
		.afterHook(retryOnInvalidToken)
	this.noauthapi = ajax
		.setBaseUrl(config.ApiURL41)
		.withCredentials(true)
		.setContentType('text/plain')
		.beforeHook(jsonifyTextPlain)
		.setParser('json')
		.beforeHook(logApiCall)
		.beforeHook(attachUpdatedFields)
		.beforeHook(attachDesktopVersion)

	this.logout = () => {
		// remove the token
		let token = lo.get(kv.match('cred'), 'access_token')
		this.isTokenExpired = false
		if (token) this.logoutSession(token)
		this.agentprofile = {}
		kv.upsert('account_id', '')
		kv.upsert('cred', {})
		accid = ''
	}

	this.isTokenExpired = false
	this.getTokenExpiredState = () => this.isTokenExpired
	this.setExpiredTokenState = (v) => {
		this.isTokenExpired = v
	}

	let accid = lo.get(kv.match('cred'), 'account_id') || kv.match('account_id') || ''
	let agentid = lo.get(kv.match('cred'), 'agent_id')

	this.getAccountId = () => {
		if (!accid) accid = kv.match('account_id')
		return accid || ''
	}

	this.getCred = () => kv.match('cred') || {}

	this.setAccountId = (accountId) => {
		accid = accountId
		kv.upsert('account_id', accid)
	}

	this.agentprofile = {}
	this.getAgentProfile = () => this.agentprofile
	this.setCred = async (cred) => {
		kv.upsert('account_id', '')
		kv.upsert('cred', cred)

		this.agentprofile = {}
		let ag = await this.fetchAgentProfile()
		if (ag.default_account_id) kv.upsert('account_id', ag.default_account_id)
		return ag
	}

	this.fetchAgentProfile = async () => {
		let cred = this.getCred() || {}
		if (!cred.agent_id) return {error: 'agent_not_found'}
		let {body, error, code} = await this.api41.get('/agents/' + cred.agent_id)
		if (error) return {error: lo.get(body, 'error', error), code}
		let ag = body.agent_profile
		this.agentprofile = ag
		return ag
	}

	this.login = async (username, password) => {
		username = lo.toLower(username)
		let {body, error, code} = await this.noauthapi.post('/tokens', {client_version: config.version, username, password})
		// if (error) return {error: lo.get(body, 'error', error), code}
		if (error) return {body, error, code}

		let cred = lo.get(body, 'login_session', {})
		let out = await this.setCred(cred)
		if (out && out.error) return {error: out.error}
		this.isTokenExpired = false
		return {cred}
	}

	this.list_account = async () => {
		let {body: me, error} = await apireq.get('/me')
		if (error) return {error, body: me}
		if (!me || !me.account) return {error: 'account not found'}
		return {body: [me.account]}
	}
	this.update_account = (acc) => apireq.post(`accounts/${accid}`, acc)

	this.fetchWidget = () => plainapireq.get('accounts/acpxkgumifuoofoosble/widgets/-')

	this.list_convo_setting = async () => {
		let {body: setting, error} = await apireq.get(`accounts/${accid}/conversation-settings`)
		if (error) return {error}
		return {body: [setting]}
	}
	this.update_convo_setting = (setting) => apireq.post(`accounts/${accid}/conversation-settings`, setting)

	this.createUserAccessToken = () => apireq.post('/user-access-tokens')

	this.count_touchpoint = (req) => apireq.post(`accounts/${accid}/user-touchpoints-count`, req)
	this.send_typing = (cid) => apireq.post(`conversations/${cid}/typing`)

	this.list_credit = async () => {
		let {body, error} = await apireq.get(`accounts/${accid}/credits`)
		if (error) return {error}
		return {body: body.credits}
	}

	this.report_credit = (credit_id, unit, from_time, limit) => {
		return apireq.post(`accounts/${accid}/credits/${credit_id}/reports`, {
			credit_id,
			unit,
			from_time,
			limit,
		})
	}

	this.list_subscription = async () => {
		let {body: sub, error} = await apireq.get(`accounts/${accid}/subscriptions`)
		if (error) return {error}
		return {body: [sub]}
	}
	this.update_subscription = (sub) => apireq.post(`accounts/${accid}/subscriptions`, sub)
	this.upgrade_subscription = (sub) => apireq.post(`subscriptions/${accid}/purchase`, sub)

	this.upgrade_sub = async (accid, sub) => apireq.post(`/subscriptions/${accid}/purchase`, sub)

	this.update_sub = (sub) => apireq.post(`/subscriptions/${sub.account_id}/`, sub)

	this.seenNotis = (category) =>
		apireq
			.addQuery('category', category)
			.post(`accounts/${accid}/agents/${kv.match('cred').agent_id}/notifications/*/seen`)
	this.listNotis = (category, anchor) =>
		apireq
			.addQuery('limit', 100)
			.addQuery('category', category)
			.addQuery('anchor', anchor)
			.get(`accounts/${accid}/agents/${kv.match('cred').agent_id}/notifications`)
	this.readNoti = (category, checkpoint, topic) =>
		apireq
			.addQuery('category', category)
			.addQuery('checkpoint', category)
			.post(`accounts/${accid}/agents/${kv.match('cred').agent_id}/notifications/${topic}/read`)

	this.markReadNoti = (id) => this.api41.post(`/agents/${kv.match('cred').agent_id}/notifications/${id}/read`)

	this.list_agent = (cb) => apireq.get('/agents', cb)
	this.get_agent = (id) => this.api41.get('/accounts/' + accid + '/agents/' + id)
	this.get_range_version_supported = (platform) => this.api41.addQuery('plageform', platform).get('/mobile-versions')
	this.create_agent = async (ag) => {
		let {body: agent, error: error1} = await apireq.post(`accounts/${accid}/agents`, ag)
		if (error1) return {error: error1, body: agent}
		return {body: agent}
	}
	this.delete_agent = (agid) => apireq.post(`accounts/${accid}/agents/${agid}/delete`)
	this.update_agent = async (ag, fields) => {
		let {body: agent, error: error1} = await apireq
			.setQuery({'x-fields': (fields || []).join(',')})
			.post(`accounts/${accid || ag.account_id}/agents/${ag.id}`, ag)
		if (error1) return {error: error1}
		return {body: agent}
	}
	this.acc_update_agent_state = (ag) =>
		this.apireq.setQuery({'x-fields': 'state'}).post(`/accounts/${ag.account_id}/agents/${ag.id}`, ag)

	this.acc_bank_transfer = ({accid, credit_id, amount, description, currency}) =>
		this.api41.post(`/accounts/${accid}/bank-transfers`, {credit_id, amount, description, currency})

	this.acc_fetch_invoice = (accid, creditid) =>
		this.api41.setQuery({credit_id: creditid, account_id: accid}).get(`/accounts/${accid}/invoices`)

	this.convert_invoice_html = (accid, invoice) => this.api41.post(`/accounts/${accid}/convertInvoiceHtml`, invoice)

	this.download_invoice = (accid, invoiceid) => this.api41.post(`/accounts/${accid}/invoices/${invoiceid}/export`)

	this.list_invoice_comments = (accid, topic) =>
		this.api41.setQuery({id: topic, account_id: accid}).get(`payment-comments`)
	this.post_invoice_comment = (accid, topic, content) =>
		this.api41.post(`payment-comments`, {
			author_email: kv.match('cred').agent_id,
			content: content,
			account_id: accid,
			topic_id: topic,
		})

	this.fetch_payment_logs = (accid) => this.api41.get(`/accounts/${accid}/payment-logs`)
	this.acc_fetch_inte = (accid) => this.api41.get(`/accounts/${accid}/integrations`)

	// lead view
	this.list_user_view = async (cb) => {
		let out = await apireq.get(`/accounts/${accid}/user-views`, cb)
		if (out.error) return out
		views = lo.filter(out.body.views, (view) => !view.is_system)
		return {body: views}
	}

	this.update_user_view = (uv) => apireq.post(`accounts/${accid}/user-views/${uv.id}`, uv)
	this.create_user_view = (uv) => apireq.post(`accounts/${accid}/user-views`, uv)
	this.delete_user_view = (id) => apireq.post(`accounts/${accid}/user-views/${id}/delete`)

	this.list_user = (query) => apireq.post(`accounts/${accid}/users-by-view`, query)

	// Site
	this.list_site = async (cb) => {
		let res = await apireq.get(`/accounts/${accid}/sites`, cb)
		if (lo.get(res, 'error')) return res
		let sites = lo.get(res, 'body.sites') || []
		return {body: sites}
	}
	this.create_site = (domain) => apireq.post(`accounts/${accid}/sites/${domain}`)
	this.delete_site = (domain) => apireq.post(`accounts/${accid}/sites/${domain}/delete`)

	this.update_agent_owner = (agid) => apireq.post(`accounts/${accid}/agents/${agid}/owner`)

	this.update_setting_notify = async (setting) => {
		let {body, error} = await apireq.post('/me/notification-setting', setting)
		if (error) return {error}
		body.id = 'setting'
		return {body}
	}
	this.list_setting_notify = async (_) => {
		let {body, error} = await apireq.get('/me/notification-setting')
		if (error) return {error}
		body.id = 'setting'
		return {body: [body]}
	}

	this.getUser = (id, cb) => apireq.get(`/users/${id}`, cb)
	this.lookupUserByEmail = (email) => apireq.setQuery({source: 'email', channel: 'email'}).get(`/users/${email}`)
	this.getUserByProfile = (channel, source, id) =>
		apireq.get(`/accounts/${accid}/contact-profiles/${channel}/sources/${source}/${id}`)
	this.updateUser = (user, cb) => apireq.post(`/users/${user.id}`, user, cb)
	this.checkExistedUser = (user) => apireq.addQuery('dryrun', 'true').post(`/users/${user.id}`, user)
	this.mergeUser = (users) => apireq.post(`/accounts/${accid}/user-merges`, {users})
	this.unLinkUser = (uid) => apireq.post(`/accounts/${accid}/user-merges/${uid}/delete`)
	this.createUser = (user, cb) => apireq.post('/users', user, cb)
	this.checkExistedCreatedUser = (user) => apireq.addQuery('dryrun', 'true').post(`/users`, user)
	this.banUser = (uid, cb) => apireq.post(`/accounts/${accid}/banned-users/${uid}`, {}, cb)
	this.unbanUser = (uid, cb) => apireq.post(`/accounts/${accid}/banned-users/${uid}/delete`, {}, cb)
	this.sendConvoEvent = (cid, ev) => apireq.post(`/accounts/${accid}/conversations/${cid}/events`, ev)
	this.sendMsgEvent = (ev) => apireq.post(`/messages`, ev)
	this.getFacebookLinkRef = (query) => apireq.setQuery(query).post(`/facebook/ref-links`)
	this.list_integration = (cb) => apireq.get(`/accounts/${accid}/integrations`, cb)
	this.update_integration = (inte) => apireq.post(`/accounts/${accid}/integrations/` + inte.id, inte)
	this.delete_integration = (id, cb) => apireq.post(`/accounts/${accid}/integrations/` + id + '/delete', cb)
	this.validate_email_integration = async (inte) => {
		let out
		if (inte.email_identity_type === 'EMAIL_ADDRESS') {
			out = await apireq.post(`/accounts/${accid}/integrations/${inte.id}/verifications`, inte)
		} else {
			out = await apireq.post(`/accounts/${accid}/integrations/${inte.id}/validates`, inte)
		}
		return out
	}
	this.getUserEvents = (userid, limit = -20, anchor, cb) =>
		apireq
			.addQuery('limit', limit)
			.addQuery('anchor', anchor || '')
			.get(`/users/${userid}/events`, cb)
	this.getUserEvents2 = (userid, start_hour, end_hour) =>
		apireq
			.addQuery('end_hour', end_hour)
			.addQuery('start_hour', start_hour)
			.addQuery('limit_hour', 24)
			.get(`/users/${userid}/events`)
	this.getConversationEvents = (conid, limit = -40, anchor, cb) =>
		apireq
			.addQuery('limit', limit)
			.addQuery('start_id', anchor || '')
			.get(`/conversations/${conid}/messages`, cb)

	this.geoip = (ip) => apireq.get(`/ips/${ip}`)

	this.listConvosOfTouchpoint = (touchpoint_channel, touchpoint_source, touchpoint_id) =>
		apireq.setQuery({touchpoint_channel, touchpoint_source, touchpoint_id}).get('/conversations')

	this.search = (col, keyword, anchor, limit, include_parts, doc_distinct) =>
		apireq.post(`/accounts/${accid}/search`, {
			collection: col,
			query: keyword,
			limit: limit || 12,
			account_id: accid,
			anchor: anchor,
			include_parts: include_parts,
			doc_distinct: doc_distinct,
		})

	this.searchUser = ({query, anchor, limit}) => apireq.setQuery({query, anchor, limit}).get(`/accounts/${accid}/users`)

	this.searchConvos = (req) =>
		apireq.post('conversation-searchs', Object.assign({agent_id: kv.match('cred').agent_id}, req))

	this.runBotForLeads = (p) => apireq.post(`/accounts/${accid}/bots/${p.bot_id}/run-acc-segment`, p)

	this.listConversationIds2 = (params = {}) => {
		return this.api41
			.setQuery({
				agent_id: kv.match('cred').agent_id,
				id_only: true,
				ticket_included: true,
				...params,
			})
			.get('/conversations')
	}

	this.seenConvo = (cid, messageId) => apireq.post(`/conversations/${cid}/messages/${messageId}/seen`)
	this.markReadTopic = (id) => this.api41.post(`/topics/${id}/read`)

	this.pongMessage = (cid, mid, pong) =>
		apireq.post(`/accounts/${accid}/conversations/${cid}/messages/${mid}/pongs`, pong)

	this.listUserConversations = (user_id, state, limit, cb) =>
		apireq.setQuery({limit, user_id, state}).get('/conversations', cb)
	this.getConversation = (id, cb) => apireq.get(`/conversations/${id}`, cb)
	this.updateConvo = (convo) => apireq.post(`/accounts/${accid}/conversations/${convo.id}`, convo)
	this.endConvo = (cid, cb) => apireq.post(`/conversations/${cid}/ended`, {}, cb)
	this.updateMember = (mem) =>
		apireq.post(`/accounts/${accid}/conversations/${mem.conversation_id}/members/${mem.id}`, mem)

	this.listUserRelatedProfiles = (userid) => apireq.get(`/accounts/${accid}/users/${userid}/relateds`)
	this.deleteUserRelatedProfile = (userid, profileid) =>
		apireq.post(`/accounts/${accid}/users/${userid}/relateds/${profileid}/delete`)

	this.pingPresence = (p) => this.api41.post('presences', Object.assign({}, p, {browser_tab_id: tabid}))

	this.mark_convo_spam = (cid) => apireq.post(`accounts/${accid}/conversations/${cid}/spam`)
	this.unmark_convo_spam = (cid) => apireq.post(`accounts/${accid}/conversations/${cid}/spam/delete`)
	this.startConvo = (convo) => apireq.post(`/conversations`, convo)
	this.getFileDetail = (fileid) => apireq.get(`/accounts/${accid}/files/${fileid}`)
	this.upload_file = (filedata, query) =>
		ajax
			.setBaseUrl(config.ApiURL41)
			.withCredentials(true)
			.setContentType('application/octet-stream')
			.setParser('json')
			.beforeHook(switchHost)
			.beforeHook(attachDesktopVersion)
			.afterHook(retryOnInvalidToken)
			.beforeHook(attachAccessToken)
			.setQuery(query)
			.post('/files', filedata)

	this.apply_message = async (ev) => {
		let out = await apireq.post(`accounts/${accid}/apply-messages`, ev)
		if (out.error) return ev
		return out.body
	}
	this.time = (cb) => plainapireq.get('time', cb)
	this.matchUsers = (data) => apireq.post(`match/users`, data)
	this.matchConversations = (data) => apireq.post(`match/conversations`, data)
	this.fetchConvoUsers = () => apireq.setQuery({ticket_included: true}).get(`accounts/${accid}/convo-users`)
	this.getConversation = (convoid) => apireq.get(`conversations/${convoid}`)

	this.confirmation = (token, accid) => apireq.post(`accounts/${accid}/confirmation`, {token})
	this.register = (body) => apireq.post('accounts', body)
	this.importBusinessHours = (body) => apireq.post('sync-public-holidays', body)

	this.listNotes = (uid, limit, anchor) =>
		apireq.addQuery('limit', limit).addQuery('anchor', anchor).get(`accounts/${accid}/users/${uid}/notes`)
	this.createNote = (uid, note) => apireq.post(`users/${uid}/notes`, note)
	this.updateNote = (uid, note) => apireq.post(`users/${uid}/notes/${note.id}`, note)

	this.list_contact_profile = (uid) =>
		apireq.addQuery('limit', 500).get(`accounts/${accid}/users/${uid}/contact-profiles`)

	this.removeNote = (uid, nid) => apireq.post(`users/${uid}/notes/${nid}/delete`)

	this.listPresences = () => apireq.post('presences', {})

	this.listLoginSession = () =>
		this.api41.addQuery('account-id', '').get(`agents/${kv.match('cred').agent_id}/sessions`)
	this.listApikey = () => this.api41.addQuery('account-id', '').get('apikeys')
	this.createApikey = (apikey) => this.api41.addQuery('account-id', '').post('apikeys', apikey)
	this.logoutSession = (id) =>
		this.api41.addQuery('account-id', '').post(`agents/${kv.match('cred').agent_id}/sessions/${id}/delete`)

	this.list_user_attribute = async () => {
		let {body, error} = await apireq.get(`accounts/${accid}/attribute-definitions`)
		if (error) return {error}
		if (!body) return {body: []}
		return {body: body.attributes || []}
	}
	this.create_user_attribute = (attr) => apireq.post(`accounts/${accid}/attribute-definitions/${attr.key}`, attr)
	this.update_user_attribute = (attr) => apireq.post(`accounts/${accid}/attribute-definitions/${attr.key}`, attr)
	this.delete_user_attribute = (key) => apireq.post(`accounts/${accid}/attribute-definitions/${key}/delete`)

	this.update_facebook_page = (accesstoken, pageids, comments, is_instagram) =>
		apireq.post(`/accounts/${accid}/facebook-comment-pages`, {
			access_token: accesstoken,
			page_ids: pageids,
			receive_comments: comments,
			is_instagram: is_instagram,
		})

	this.update_facebook_page_for_business = (accesstoken, is_instagram) =>
		apireq.post(`/accounts/${accid}/facebook-comment-pages-for-business`, {
			access_token: accesstoken,
			is_instagram: is_instagram,
		})

	this.create_agent_group = (group) => apireq.post(`accounts/${accid}/agent-groups`, group)
	this.update_agent_group = (group) => apireq.post(`accounts/${accid}/agent-groups/${group.id}`, group)
	this.list_agent_group = async (_) => {
		let {body, error} = await apireq.get(`accounts/${accid}/agent-groups`)
		if (error) return {error}

		let groups = body
		const DEFAULT_LOGO_URL = 'emoji://1f465'
		lo.each(groups, (group) => {
			if (!group.logo_url) group.logo_url = DEFAULT_LOGO_URL
		})
		return {body: groups}
	}
	this.delete_agent_group = (gid) => apireq.post(`accounts/${accid}/agent-groups/${gid}/delete`)
	this.detailGroup = (gId) => apireq.get(`/accounts/${accid}/agent-groups/${gId}`)

	this.getUrlUpload = (url) =>
		url && url.indexOf('http://') < 0 && url.indexOf('https://') < 0 ? config.FileUrl + url : url

	this.list_business_email_address = async () => {
		let out = await apireq.get(`/accounts/${accid}/business-email-addresses`)
		return {body: lo.get(out, 'body.business_email_addresses', []), error: out.error}
	}
	this.create_business_email_address = (p) => apireq.post(`/accounts/${accid}/business-email-addresses`, p)
	this.update_business_email_address = (p) => apireq.post(`/accounts/${accid}/business-email-addresses/${p.address}`, p)
	this.delete_business_email_address = (id) => apireq.post(`/accounts/${accid}/business-email-addresses/${id}/delete`)

	this.getInvitations = (token) => plainapireq.addQuery('token', token).get(`invitations`)
	this.invitations = (token, accid, new_password) =>
		plainapireq.post(`accounts/${accid}/invitations`, {token, new_password})

	this.updateAgentInvitation = (accid, aid, body) => apireq.post(`accounts/${accid}/agents/${aid}`, body)
	this.forgotPassword = (email, new_password) => apireq.post(`password/${email}`, {new_password})
	this.updatePassword = (email, old_password, new_password) =>
		apireq.post(`password/${email}`, {new_password, old_password})
	this.updatePasswordViaToken = (token, email, new_password) =>
		plainapireq.post(`password/${email}`, {token, new_password})
	this.resetPassword = (email) => apireq.post(`password/${email}/delete`)

	this.list_automation = (_) => apireq.get(`/accounts/${accid}/automations`)
	this.create_automation = (automation) => apireq.post(`/accounts/${accid}/automations`, automation)
	this.update_automation = (automation) => apireq.post(`/accounts/${accid}/automations/${automation.id}`, automation)
	this.delete_automation = (atmid) => apireq.post(`accounts/${accid}/automations/${atmid}/delete`)

	this.list_black_list_ip = () => apireq.get(`accounts/${accid}/blacklist-ips`)
	this.create_black_list_ip = (ip) => apireq.addQuery('ip', ip).post(`accounts/${accid}/blacklist-ips`)
	this.delete_black_list_ip = (ip) => apireq.addQuery('ip', ip).post(`accounts/${accid}/blacklist-ips/-/delete`)

	this.list_black_list_user = () => apireq.get(`accounts/${accid}/banned-users`)
	this.create_black_list_user = (user) => apireq.post(`accounts/${accid}/banned-users/${user}`)
	this.delete_black_list_user = (user) => apireq.post(`accounts/${accid}/banned-users/${user}/delete`)

	this.list_white_list_domain = (_) => apireq.get(`accounts/${accid}/whitelist-domains`)
	this.create_white_list_domain = (domain) => apireq.post(`accounts/${accid}/whitelist-domains/${domain}`)
	this.delete_white_list_domain = (domain) => apireq.post(`accounts/${accid}/whitelist-domains/${domain}/delete`)

	this.list_template = async (_) => {
		let templates = []
		let anchor = ''
		for (let i = 0; i < 20; i++) {
			let {body, error} = await this.api41.addQuery('anchor', anchor).addQuery('limit', 100).get(`/templates`)
			if (error) return {error}

			if (!body.next_anchor) break
			templates = templates.concat(body.templates)
			anchor = body.next_anchor
		}

		let anchor2 = ''
		for (let i = 0; i < 20; i++) {
			let {body, error} = await this.api41
				.addQuery('anchor', anchor2)
				.addQuery('limit', 100)
				.addQuery('is_template', 'true')
				.get(`/templates`)
			if (error) return {error}

			if (!body.next_anchor) break
			templates = templates.concat(body.templates)
			anchor2 = body.next_anchor
		}

		return {body: {templates, is_all: true}}
	}
	this.create_template = (mes) => this.api41.post(`/templates`, mes)
	this.update_template = (mes) => this.api41.post(`/templates/${mes.id}`, mes)
	this.delete_template = (id) => this.api41.post(`/templates/${id}/delete`)

	this.pickConversationModal = (cid, id, text, uid) =>
		apireq.post(`/accounts/${accid}/conversation_modals/${id}/pick`, {
			user_id: uid,
			conversation_id: cid,
			conversation_modal_id: id,
			text: text,
		})

	this.list_conversation_modal = async () => {
		let {body, error} = await apireq.addQuery('limit', 200).get(`/accounts/${accid}/conversation_modals`)
		return {body: body.conversation_modals || [], error}
	}
	this.create_conversation_modal = (p) => apireq.post(`accounts/${accid}/conversation_modals`, p)
	this.update_conversation_modal = (p) => apireq.post(`accounts/${accid}/conversation_modals/${p.id}`, p)
	this.delete_conversation_modal = (id) => apireq.post(`accounts/${accid}/conversation_modals/${id}/delete`)

	this.getCampaignReport = (campgId, params) => {
		let req = this.api41
		for (let k in params) {
			if (!params[k]) continue
			req = req.addQuery(k, params[k])
		}
		return req.get(`/campaigns/${campgId}/report`)
	}

	this.getConversionCampaignReport = (campgId, params) => {
		let req = this.api41
		for (let k in params) {
			if (!params[k]) continue
			req = req.addQuery(k, params[k])
		}
		return req.get(`/campaigns/${campgId}/conversions`)
	}

	this.exportConversionCampaignReport = (campgId, params) => {
		let req = this.api41
		for (let k in params) {
			if (!params[k]) continue
			req = req.addQuery(k, params[k])
		}
		return req.get(`/campaigns/${campgId}/conversions-export`)
	}

	this.summary_campaign = async (id) => {
		// get report from 14 days
		let now = Math.floor(Date.now() / 1000 / 3600)
		let toHour = now - 1
		let fromHour = now - 14 * 24
		return this.api41
			.addQuery('range', 'day')
			.addQuery('from_hour', fromHour)
			.addQuery('to_hour', toHour)
			.get(`/campaigns/${id}/report`)
	}

	this.get_webhook_delivery = (id, did) => apireq.get(`accounts/${accid}/webhooks/${id}/recent-deliveries/${did}`)
	this.list_webhook_deliveries = (id) =>
		apireq.addQuery('limit', 50).get(`accounts/${accid}/webhooks/${id}/recent-deliveries`)
	this.list_webhook = async (_) => {
		let {body, error} = await apireq.addQuery('limit', 200).get(`/accounts/${accid}/webhooks`)
		if (error) return {error}

		return {body: body.webhooks || []}
	}
	this.create_webhook = (webhook) => apireq.post(`accounts/${accid}/webhooks`, webhook)
	this.update_webhook = (webhook) => apireq.post(`accounts/${accid}/webhooks/${webhook.id}`, webhook)
	this.delete_webhook = (id) => apireq.post(`accounts/${accid}/webhooks/${id}/delete`)

	this.test_bot = async (p) => apireq.post(`/accounts/${accid}/bots/${p.bot_id}/execs`, p)
	this.test_bot_2 = async (p) => apireq.post(`/accounts/${accid}/bots/${p.bot_id}/guest-execs`, p)
	this.report_bot = async (p) => apireq.post(`/accounts/${accid}/bots/${p.bot_id}/reports`, p)
	this.report_bot_new = async (p) => apireq.setQuery(p).get(`/accounts/${accid}/bots/${p.bot_id}/report`)
	this.report_bot_actions = async (p) => apireq.setQuery(p).get(`/accounts/${accid}/bots/${p.bot_id}/report-action`)
	this.list_bot = async (_) => {
		let result = []
		let error = ''
		let anchor = ''
		for (let i = 0; i < 10; i++) {
			let {body, error} = await apireq.addQuery('anchor', anchor).addQuery('limit', 200).get(`/accounts/${accid}/bots`)

			if (error) {
				error = error
				break
			}
			let bots = body.bots || []
			result = result.concat(bots)
			anchor = body.next_anchor
			if (!body.anchor) {
				break
			}
			if (!lo.size(bots)) {
				break
			}
		}

		return {body: result, error: error}
	}
	this.create_bot = (campaign) => apireq.post(`accounts/${accid}/bots`, campaign)
	this.update_bot = (campaign) => apireq.post(`accounts/${accid}/bots/${campaign.id}`, campaign)
	this.delete_bot = (id) => apireq.post(`accounts/${accid}/bots/${id}/delete`)
	this.update_bot_state = (bot) => apireq.post(`accounts/${accid}/bots/${bot.id}/state`, bot)
	this.test_bot_action = (p) => apireq.post(`accounts/${accid}/bots/${p.bot_id}/try-action`, p)
	this.listBotConversations = async (p) => {
		let {body, error} = await apireq.setQuery(p).get(`/accounts/${accid}/bots/${p.bot_id}/conversations`)
		if (error) return {error}

		return {body}
	}

	this.list_uploaded_image = async () => {
		let {body, error} = await apireq.get(`accounts/${accid}/uploaded-images`)
		if (error) return {error}
		return {body: body.images || []}
	}
	this.create_uploaded_image = (url) => apireq.post(`accounts/${accid}/uploaded-images`, {url})
	this.delete_uploaded_image = (url) => apireq.post(`accounts/${accid}/uploaded-images/delete`, {url})

	this.list_tag = (_) => apireq.get(`accounts/${accid}/tags`)
	this.create_tag = (tag) => apireq.post(`accounts/${accid}/tags`, tag)
	this.delete_tag = (id) => apireq.post(`accounts/${accid}/tags/${id}/delete`)
	this.update_tag = (tag) => apireq.post(`accounts/${accid}/tags/${tag.id}`, tag)

	this.list_label = async (_) => {
		let {body, error} = await apireq.get(`accounts/${accid}/user-labels`)
		if (error) return {error}
		if (!body) return {body: []}
		return {body: body.labels || []}
	}
	this.delete_label2 = (id) => {
		return apireq.post(`accounts/${accid}/user-labels/${id}/delete`)
	}
	this.update_label = (label) => apireq.post(`accounts/${accid}/user-labels/${label.id}`, label)

	this.list_facebook_setting = async (_) => {
		let {body: setting, error} = await apireq.get('facebook-settings')
		if (error) return {error}
		return {body: setting.settings}
	}
	this.update_facebook_setting = (setting) => apireq.post(`facebook-settings/${setting.fanpage_id}`, setting)

	this.list_google_setting = async (_) => {
		let {body: setting, error} = await apireq.get('google-settings')
		if (error) return {error}
		return {body: setting.settings}
	}
	this.update_google_setting = (setting) => apireq.post(`google-settings/${setting.business_location_id}`, setting)

	this.list_rule = async (query) => {
		let res = await this.api41.setQuery(query).get(`rules`)
		if (res.error) return res
		let body = lo.get(res, 'body') || {}
		body.is_all = true
		return {body}
	}
	this.match_rule = (ids, last_modifieds) => this.api41.post(`match/rules`, {ids, last_modifieds})
	this.create_rule = (rule) => this.api41.post(`rules`, rule)
	this.update_rule = (rule) => this.api41.post(`rules/${rule.id}`, rule)
	this.delete_rule = (ruid) => this.api41.post(`rules/${ruid}/delete`)
	this.list_rule_order = async () => {
		let res = await this.api41.get('rule-orders')
		if (res.error) return res
		let rules = lo.get(res, 'body.rules') || []
		return {
			body: lo.map(rules, (id) => ({id})),
		}
	}
	this.update_rule_orders = (p) => this.api41.post('rule-orders', p)

	this.list_ticket_rule = async () => {
		let out = await apireq.get(`ticket-rules`)
		if (out.error) return out
		return {body: out.body.ticket_rules}
	}

	this.create_ticket_rule = async (rule) => {
		let out = await apireq.post(`ticket-rules`, rule)
		if (out.error) return out
		return {body: out.body.ticket_rule}
	}

	this.update_ticket_rule = async (rule) => {
		let out = await apireq.post(`ticket-rules/${rule.id}`, rule)
		if (out.error) return out
		return {body: out.body.ticket_rule}
	}
	this.delete_ticket_rule = (ruid) => apireq.post(`accounts/${accid}/ticket-rules/${ruid}/delete`)

	this.getTagReport = (params) => apireq.post(`reports/conversations/tags`, params)
	this.reportPerformanceAgent = (options) => {
		let req = apireq
		for (let k in options) {
			if (!options[k]) continue
			req = req.addQuery(k, options[k])
		}
		return req.get(`reports/tickets`)
	}

	this.reportConvoReplied = (body) => apireq.post(`accounts/${accid}/conversation-reports2`, body)
	this.reportAvailability = (body) => apireq.post(`accounts/${accid}/availibility-reports`, body)
	this.getConversationLists = (body) => apireq.post(`/conversation-lists`, body)

	// WEB-CHECK
	this.list_webcheck = async (_) => {
		let {body, error} = await apireq.addQuery('limit', 200).get(`/accounts/${accid}/webpages`)
		if (error) return {error}

		// get report for last 30 days

		return {body: body.webpages || []}
	}
	this.create_webcheck = (wc) => apireq.post(`accounts/${accid}/webpages`, wc)
	this.update_webcheck = (wc) => apireq.post(`accounts/${accid}/webpages/${wc.id}`, wc)
	this.delete_webcheck = (id) => apireq.post(`accounts/${accid}/webpages/${id}/delete`)
	this.get_detail_webcheck = (p) => apireq.post(`accounts/${accid}/webpages/${p.webpage_id}/reports`, p)
	this.get_summary_webcheck = (p) => apireq.setQuery(p).get(`accounts/${accid}/webpages/${p.webpage_id}/summary`)

	this.getPromotionCode = (code) => apireq.get(`promocodes/${code}`)
	this.subscriptionPay = (invoice) => apireq.post(`subscriptions/${invoice.account_id}/pay`, invoice)

	this.list_plan = async (cb) => {
		let plans = [
			{name: 'free', price: 0, fpv_unit_price_vnd: 0, fpv_unit_price_usd: 0},
			{name: 'trial', price: 0, fpv_unit_price_vnd: 0, fpv_unit_price_usd: 0},
			{name: 'standard', price: 11.99, fpv_unit_price_usd: 11_990_000, fpv_unit_price_vnd: 261_861_600_000},
			{
				name: 'standard_unlimited_agent',
				price: 15.24,
				fpv_unit_price_usd: 15_239_621,
				fpv_unit_price_vnd: 332_833_322_640,
			},
			{name: 'advanced', price: 21, fpv_unit_price_usd: 21_000_000, fpv_unit_price_vnd: 458_640_000_000},
		]

		//let res = await apireq.get(`accounts/${accid}/plans`)
		//if (res.error) {
		//return res
		//}
		let body = {body: plans}
		if (cb) cb(body)
		return body
	}

	// WEB_PLUGINS
	this.list_web_plugin = async (_) => {
		let {body, error} = await apireq.addQuery('limit', 200).get(`/accounts/${accid}/web-plugins`)
		if (error) return {error}

		return {body: body.plugins || []}
	}
	this.create_web_plugin = (plugin) => apireq.post(`accounts/${accid}/web-plugins`, plugin)
	this.update_web_plugin = (plugin) => apireq.post(`accounts/${accid}/web-plugins/${plugin.id}`, plugin)
	this.delete_web_plugin = (id) => apireq.post(`accounts/${accid}/web-plugins/${id}/delete`)
	this.report_web_plugin = (p) => apireq.setQuery(p).get(`/accounts/${accid}/web-plugins/${p.web_plugin_id}/report`)
	this.update_web_plugin_state = (plugin) => apireq.post(`accounts/${accid}/web-plugins/${plugin.id}/enabled`, plugin)
	this.get_web_plugin_conversions = (p) =>
		apireq.setQuery(p).get(`/accounts/${accid}/web-plugins/${p.web_plugin_id}/conversions`)
	this.list_web_plugin_x = async (accid) => {
		let {body, error} = await apireq.addQuery('limit', 200).get(`/accounts/${accid}/web-plugins`)
		if (error) return {error}
		return {body: body.plugins || []}
	}
	this.update_web_plugin_x = (p) => apireq.post(`/accounts/${p.account_id}/web-plugins/${p.id}`, p)

	// product
	this.create_product = (product) => apireq.post(`accounts/${accid}/products`, product)
	this.update_product = (product) => apireq.post(`accounts/${accid}/products/${product.id}`, product)
	this.upload_single_product = (product) => apireq.post(`accounts/${accid}/products/source_id`, product)
	this.upload_bulk_products = (p) => apireq.post(`accounts/${accid}/products-import`, p)
	this.upload_bulk_orders = (orders) => apireq.post(`accounts/${accid}/import-orders`, {orders})
	this.delete_product = (id) => apireq.post(`accounts/${accid}/products/${id}/delete`)

	this.list_product_categories = async () => {
		let {body, error} = await apireq.addQuery('limit', 1000).get(`/accounts/${accid}/product-categories`)
		if (error) return {error}
		return body
	}

	// WEB_PLUGINS_TEMPLATE
	this.list_web_plugin_template = async (_) => {
		let {body, error} = await apireq.addQuery('limit', 200).get(`/accounts/${accid}/web-plugin-templates`)
		if (error) return {error}
		return {body: body.plugins || []}
	}
	this.update_web_plugin_template = (plugin) =>
		apireq.post(`accounts/${accid}/web-plugin-templates/${plugin.id}`, plugin)
	this.delete_web_plugin_template = (id) => apireq.post(`accounts/${accid}/web-plugin-templates/${id}/delete`)

	this.list_notif_profiles = async (_) => {
		let {body, error} = await apireq.get(`accounts/${accid}/notification-profiles`)
		if (error) return {error}
		return {body: body.profiles || []}
	}

	// create token stripe
	this.list_invoice = async (_) => {
		let {body, error} = await this.api41.addQuery('limit', -40).get(`accounts/${accid}/invoices`)
		if (error) return {error}
		return {body: body.invoices}
	}
	this.genQrCode = ({
		bank_bin = '970436',
		account_number = '0451001477880',
		country_code = '704',
		currency = 'VND',
		fpv_amount,
		note,
	}) =>
		this.api41.post('/gen-qr-code', {
			bank_bin,
			account_number,
			country_code,
			currency,
			fpv_amount,
			note,
		})
	this.exportInvoice = ({id, account_id}, options = {is_gen_qr: ''}) =>
		this.api41.addQuery('is-gen-qr', options.is_gen_qr).post(`accounts/${account_id}/invoices/${id}/export`)
	this.exportInvoiceHtml = (inv, options = {is_gen_qr: ''}) =>
		this.api41
			.addQuery('is-gen-qr', options.is_gen_qr)
			.post(`accounts/${inv.account_id || accid}/convertInvoiceHtml`, inv)
	this.calculateInvoice = (sub) => this.api41.post(`draft-invoices`, sub)
	this.accmgr_calculate_invoice = (sub) => this.noauthapi.post(`draft-invoices`, sub)
	this.calculate_subscription_amount = ({old_subscription, new_subscription}) =>
		this.api41.post(`diff-subscriptions`, {
			old_subscription,
			new_subscription,
		})

	this.list_exchange_rate = async (_) => {
		let {body: exch, error} = await apireq.get('exchange-rate/USD/VND')
		if (error) return {error}
		exch.id = 'USD-VND'
		return {body: [exch]}
	}

	this.muteConversation = (convoid) => apireq.post(`/conversations/${convoid}/mute`)
	this.unmuteConversation = (convoid) => apireq.post(`/conversations/${convoid}/mute/delete`)
	this.unwatchConversation = (convoid) => apireq.post(`/conversations/${convoid}/watch/delete`)
	this.rewatchConversation = (convoid) => apireq.post(`/conversations/${convoid}/watch`)

	this.dismissConversation = (convoid) => this.api41.post(`/conversations/${convoid}/dismiss`)
	this.attentConversation = (convoid) => this.api41.post(`/conversations/${convoid}/attend`)

	this.tagConvo = (cid, tid) => apireq.post(`conversations/${cid}/tags/${tid}`)
	this.untagConvo = (cid, tid) => apireq.post(`conversations/${cid}/tags/${tid}/delete`)
	this.tagOrder = (orderid, tagid) => apireq.post(`accounts/${accid}/orders/${orderid}/tag`, {id: tagid})
	this.untagOrder = (orderid, tagid) => apireq.post(`accounts/${accid}/orders/${orderid}/untag`, {id: tagid})
	this.assignAgent = (cid, member) => apireq.post(`conversations/${cid}/members`, member)
	this.unassignAgent = (cid, member) =>
		apireq.post(`accounts/${accid}/conversations/${cid}/members/${member.id}/delete`, member)

	this.getConversions = (accId, campgId, params) => {
		let req = this.api41
		for (let k in params) {
			if (!params[k]) continue
			req = req.addQuery(k, params[k])
		}
		return req.send(`/campaigns/${campgId}/conversions`)
	}

	this.exportConversion = (accId, campgId, params) => {
		let req = this.api41
		for (let k in params) {
			if (!params[k]) continue
			req = req.addQuery(k, params[k])
		}
		return req.get(`/campaigns/${campgId}/conversions-export`)
	}
	this.searchLocations = (query) => apireq.addQuery('query', query).addQuery('limit', 10).get('locations')

	this.topLiveLocations = () => apireq.get('live/locations')
	this.topLivePages = () => apireq.get('live/pages')
	this.topLiveSources = () => apireq.get('live/sources')
	this.countLiveTotal = () => apireq.get('live/total')
	this.filterLiveUser = (filter) => apireq.post('live/users', filter)
	this.filterLiveUsers = (filter) => apireq.post('live-users', filter)
	this.searchImages = (query) => apireq.addQuery('query', query).get(`accounts/${accid}/images`)

	this.addUserLabel = (userid, key) => apireq.post(`accounts/${accid}/users/${userid}/labels/${key}`)
	this.removeUserLabel = (userid, key) => apireq.post(`accounts/${accid}/users/${userid}/labels/${key}/delete`)
	this.listBanks = () => this.api41.get('banks')

	this.getLanguage = (locale) => apireq.get(`accounts/${accid}/locales/${locale}`)
	this.setLanguageMessage = (mes) =>
		apireq.post(`accounts/${accid}/locales/${mes.locale}/messages/${mes.key}`, {message: mes.message})
	this.getDefLanguage = (locale) => this.api41.get(`/accounts/subiz/locales/${locale}`)
	this.setDefLanguageMessage = (mes) =>
		this.api41.post(`/accounts/subiz/locales/${mes.locale}/messages/${mes.key}`, {
			message: mes.message,
			public_state: mes.public_state,
			category: mes.category,
		})
	this.listCountryCodes = () => this.api41.get(`countries`)

	this.getUserReport = (p) => apireq.post('report/users', p)
	this.getCallReport = (p) => apireq.post('report/calls', p)
	this.getCallIds = (p) => apireq.post('report/call-ids', p)
	this.listRecentCalls = () => apireq.get(`/accounts/${accid}/recent-calls`)
	this.getConvoReport = (p) => apireq.post('report/conversations', p)
	this.getConvoReport2 = (p) => apireq.post('report/conversations2', p)
	this.getConvoIds = (p) => apireq.post('report/conversation-ids', p)
	this.getAgentReport = (p) => apireq.post('report/agents', p)
	this.getListTouchpoints = (p) => apireq.post('report/channel-touchpoints', p)
	this.get_order_report = (p) => apireq.post('report/orders', p)
	this.getShopSetting = () => apireq.get(`/accounts/${accid}/shop-settings`)
	this.updateShopSetting = (setting) => apireq.post(`/accounts/${accid}/shop-settings`, setting)
	this.getReport = (p) => apireq.post(`/report/users2`, p)

	this.list_pos = async () => {
		let out = await apireq.get(`/accounts/${accid}/shop-addresses`)
		return {body: lo.get(out.body, 'addresses', []), error: out.error}
	}
	this.update_pos = (pos) => apireq.post(`/accounts/${accid}/shop-addresses/${pos.id}`, pos)
	this.create_pos = (pos) => apireq.post(`/accounts/${accid}/shop-addresses`, pos)
	this.delete_pos = (id) => apireq.post(`/accounts/${accid}/shop-addresses/${id}/delete`)

	this.suggest_address = (p) => apireq.setQuery(p).get(`/accounts/${accid}/addresses`)
	this.get_address_detail = (id) => apireq.get(`/accounts/${accid}/addresses/${id}`)

	this.list_integrated_shipping = () => apireq.get(`/accounts/${accid}/integrated-shippings`)
	this.create_integrated_shipping = (p) => apireq.post(`/accounts/${accid}/integrated-shippings`, p)
	this.update_integrated_shipping = (p) => apireq.post(`/accounts/${accid}/integrated-shippings-update`, p)
	this.delete_integrated_shipping = (p) => apireq.post(`/accounts/${accid}/integrated-shippings-delete`, p)
	this.send_ghn_otp = (p) => apireq.post(`/accounts/${accid}/integrated-shippings/ghn/send-otp`, p)
	this.enter_ghn_otp = (p) => apireq.post(`/accounts/${accid}/integrated-shippings/ghn/enter-otp`, p)
	this.check_number_connection = (number) => apireq.post(`/accounts/${accid}/number-tests/${number}`)

	this.get_shipping_fee = (p) => apireq.post(`/accounts/${accid}/shipping-fee`, p)
	this.send_order_to_shipping_provider = (orderid) => apireq.post(`accounts/${accid}/orders/${orderid}/ship`)
	this.cancel_shipping_order = (orderid) => apireq.post(`accounts/${accid}/orders/${orderid}/cancel-shipping`)
	this.print_shipping_orders = (p) => apireq.post(`accounts/${accid}/shipping-orders-print`, p)
	this.seen_order = (orderid) => apireq.post(`accounts/${accid}/orders/${orderid}/seen`)

	this.list_tax = async () => {
		let out = await apireq.get(`/accounts/${accid}/taxes`)
		return {body: lo.get(out.body, 'taxes', []), error: out.error}
	}
	this.update_tax = (tax) => apireq.post(`/accounts/${accid}/taxes/${tax.id}`, tax)
	this.create_tax = (tax) => apireq.post(`/accounts/${accid}/taxes`, tax)
	this.delete_tax = (id) => apireq.post(`/accounts/${accid}/taxes/${id}/delete`)

	this.list_cancellation_code = async () => {
		let out = await apireq.get(`/accounts/${accid}/cancellation-codes`)
		return {body: lo.get(out.body, 'cancellation_codes', []), error: out.error}
	}
	this.update_cancellation_code = (cc) => apireq.post(`/accounts/${accid}/cancellation-codes/${cc.code}`, cc)
	this.create_cancellation_code = (cc) => apireq.post(`/accounts/${accid}/cancellation-codes`, cc)

	this.list_subiz_payment_method = async () => {
		let res = await this.api41.get(`subiz-payment-methods`)
		if (res.error) return res
		let methods = lo.get(res, 'body.subiz_payment_methods') || []
		return {body: methods}
	}
	this.update_subiz_payment_method = (pm) => this.api41.post(`subiz-payment-methods/${pm.id}`, pm)
	this.create_subiz_payment_method = (pm) => this.api41.post(`subiz-payment-methods`, pm)
	this.delete_subiz_payment_method = (id) => this.api41.post(`subiz-payment-methods/${id}/delete`)
	this.make_default_subiz_payment_method = (id) => this.api41.post(`subiz-payment-methods/${id}/default`)
	this.create_stripe_checkout_sessions = (options = {is_dev: false}) => {
		console.log('api', options)
		return this.api41.addQuery('id', options.is_dev ? 'dev' : '').post(`stripe-checkout-sessions`, {})
	}
	this.get_stripe_checkout_session = (id) => this.api41.get(`stripe-checkout-sessions/${id}`)

	this.list_payment_method = async () => {
		let out = await apireq.get(`/accounts/${accid}/payment-methods`)
		return {body: lo.get(out.body, 'payment_methods', []), error: out.error}
	}
	this.update_payment_method = (pm) => apireq.post(`/accounts/${accid}/payment-methods/${pm.id}`, pm)
	this.create_payment_method = (pm) => apireq.post(`/accounts/${accid}/payment-methods`, pm)
	this.delete_payment_method = (id) => apireq.post(`/accounts/${accid}/payment-methods/${id}/delete`)
	this.make_default_payment_method = (id) => apireq.post(`/accounts/${accid}/payment-methods/${id}/default`)

	this.create_order = (order) => apireq.post(`/accounts/${accid}/orders`, order)
	this.update_order = (order, fields) =>
		apireq.setQuery({'x-fields': (fields || []).join(',')}).post(`/accounts/${accid}/orders/${order.id}`, order)
	this.delete_order = (id) => apireq.post(`/accounts/${accid}/orders/${id}/delete`)
	this.get_order = (id) => apireq.get(`/accounts/${accid}/orders/${id}`)
	this.list_orders = (filter) => apireq.post(`/accounts/${accid}/orders-search`, filter)
	this.count_orders = (filter) => apireq.post(`/accounts/${accid}/orders-count`, filter)
	this.count_orders_2 = (query) => apireq.post(`/accounts/${accid}/orders-count2`, query)
	this.list_order_payments = (data) => apireq.post(`/accounts/${accid}/order-bills-list`, data)

	this.seen_task = (taskid) => apireq.post(`/accounts/${accid}/tasks/${taskid}/seen`)
	this.create_task = (task) => apireq.post(`/accounts/${accid}/tasks`, task)
	this.update_task = (task, fields) =>
		apireq.setQuery({'x-fields': (fields || []).join(',')}).post(`/accounts/${accid}/tasks/${task.id}`, task)
	this.delete_task = (id) => apireq.post(`/accounts/${accid}/tasks/${id}/delete`)
	this.get_task = (id) => apireq.get(`/accounts/${accid}/tasks/${id}`)
	this.list_tasks = (filter) => apireq.setQuery(filter).get(`/accounts/${accid}/tasks`)
	this.match_tasks = (ids, modifieds) =>
		apireq.post(`/accounts/${accid}/match/tasks`, {ids, last_modifieds: modifieds, limit: ids.length})
	this.close_task_reminder = (taskid) => apireq.post(`/accounts/${accid}/tasks/${taskid}/close-task-teminder`)

	this.list_task_comments = (taskid) => apireq.get(`/accounts/${accid}/tasks/${taskid}/comments`)
	this.add_task_comment = (taskid, ev) => apireq.post(`/accounts/${accid}/tasks/${taskid}/comments`, ev)

	this.list_pipeline = async () => {
		let out = await apireq.setQuery({limit: 200}).get(`/accounts/${accid}/pipelines`)
		return {body: lo.get(out.body, 'pipelines', []), error: out.error}
	}
	this.create_pipeline = (pipeline) => apireq.post(`/accounts/${accid}/pipelines`, pipeline)
	this.update_pipeline = (pipeline) => apireq.post(`/accounts/${accid}/pipelines/${pipeline.id}`, pipeline)
	this.delete_pipeline = (id) => apireq.post(`/accounts/${accid}/pipelines/${id}/delete`)
	this.make_default_pipeline = (id) => apireq.post(`/accounts/${accid}/pipelines/${id}/make-default`)
	this.delete_pipeline_stage = (pid, stage_id) =>
		apireq.post(`/accounts/${accid}/pipelines/${pid}/delete-pipeline-stage`, {id: stage_id})
	this.make_pipeline_preselect = (id) => apireq.post(`/accounts/${accid}/pipelines/${id}/preselect`)

	this.matchOrders = (ids, modifieds) =>
		apireq.post(`/accounts/${accid}/orders-search`, {ids, modifieds, limit: ids.length})

	this.list_shipping_address = async (uid) => {
		let out = await apireq.get(`/accounts/${accid}/users/${uid}/shipping-addresses`)
		if (out.error) return {error: out.error}
		return lo.get(out.body, 'shipping_addresses', [])
	}

	this.update_shipping_address = (addr) =>
		apireq.post(`/accounts/${accid}/users/${addr.user_id}/shipping-addresses/${addr.id}`, addr)
	this.create_shipping_address = (addr) =>
		apireq.post(`/accounts/${accid}/users/${addr.user_id}/shipping-addresses`, addr)
	this.delete_shipping_address = (userid, id) =>
		apireq.post(`/accounts/${accid}/users/${userid}/shipping-addresses/${id}/delete`)
	this.make_default_shipping_address = (userid, id) =>
		apireq.post(`/accounts/${accid}/users/${userid}/shipping-addresses/${id}/default`)

	this.list_phone_device = async () => {
		let out = await apireq.get(`/accounts/${accid}/phone-devices`)
		if (out.error) return {error: out.error}
		return {body: lo.get(out.body, 'phone_devices', []), error: out.error}
	}
	this.get_phone_device_detail = (id) => apireq.get(`/accounts/${accid}/phone-devices/${id}`)
	this.create_phone_device = (p) => apireq.post(`/accounts/${accid}/phone-devices`, p)
	this.update_phone_device = (p) => apireq.post(`/accounts/${accid}/phone-devices/${p.id}`, p)
	this.delete_phone_device = (id) => apireq.post(`/accounts/${accid}/phone-devices/${id}/delete`)

	this.text_to_speech = (p) => apireq.post(`/text-to-speech`, p)

	this.list_bank_account = async () => {
		let out = await this.api41.get(`/bank-accounts`)
		if (out.error) return {error: out.error}
		let banks = lo.get(out.body, 'bank_accounts', [])
		return {body: banks, error: out.error}
	}
	this.create_bank_account = async (p) => {
		let out = await this.api41.post(`/bank-accounts`, p)
		if (out.error) return out
		out.body = out.body.bank_account
		return out
	}
	this.update_bank_account = async (bank) => {
		let out = await this.api41.post(`/bank-accounts/${bank.id}`, bank)
		if (out.error) return out
		out.body = out.body.bank_account
		return out
	}
	this.delete_bank_account = (id) => this.api41.post(`/bank-accounts/${id}/delete`)

	this.list_greeting_audio = async () => {
		let out = await apireq.get(`/accounts/${accid}/greeting-audios`)
		if (out.error) return {error: out.error}
		return {body: lo.get(out.body, 'greeting_audios', []), error: out.error}
	}
	this.create_greeting_audio = (p) => apireq.post(`/accounts/${accid}/greeting-audios`, p)
	this.update_greeting_audio = (p) => apireq.post(`/accounts/${accid}/greeting-audios/${p.id}`, p)
	this.delete_greeting_audio = (id) => apireq.post(`/accounts/${accid}/greeting-audios/${id}/delete`)

	this.list_campaign = () => this.api41.get(`/campaigns`)
	this.match_campaign = (ids, last_modifieds) => this.api41.post(`/match/campaigns`, {ids, last_modifieds})
	this.send_test_campaign_message = (p) => this.api41.post(`accounts/${accid}/test-messages`, p)

	this.create_campaign = (p) => this.api41.post(`/campaigns`, p)
	this.update_campaign = (p) => this.api41.post(`/campaigns/${p.id}`, p)
	this.delete_campaign = (id) => this.api41.post(`/campaigns/${id}/delete`)
	this.get_campaign_report = (id) => this.api41.get(`/campaigns/${id}/broadcast-reports`)
	this.get_campaign_send_messages = (query) =>
		this.api41.setQuery(query).get(`/campaigns/${query.campaign_id}/list-send-messages`)

	this.list_outbound_call_entries = async (query) => {
		let out = await apireq.post(`/campaigns/${query.campaign_id}/outbound-call-entries`, query)
		if (out.error) return {error: out.error}
		let body = lo.get(out, 'body', {})
		let entries = lo.get(out, 'body.entries', [])
		lo.each(entries, (entry) => {
			if (!entry.campaign_id) {
				entry.campaign_id = query.campaign_id
			}
			entry.id = `${entry.campaign_id}%${entry.user_id}`
		})
		return {body: Object.assign(body, {entries}), error: out.error}
	}
	this.report_outbound_call_entries = (query) =>
		this.api41.setQuery(query).get(`/campaigns/${query.campaign_id}/outbound-call-reports`)

	this.update_outbound_call_entry = async (entry) => {
		if (!entry.user_id) return {error: 'invalid user_id'}
		if (!entry.campaign_id) return {error: 'invalid campaign_id'}
		let out = await apireq.post(`/campaigns/${entry.campaign_id}/outbound-call-entries/${entry.user_id}`, entry)
		if (out.error) return {error: out.error}
		let newentry = lo.get(out, 'body', {})
		newentry.id = `${entry.campaign_id}%${entry.user_id}`
		return {body: newentry}
	}
	this.import_call_entries = (params) =>
		apireq.post(`/campaigns/${params.campaign_id}/outbound-call-entries/-/import`, params)
	this.delete_call_entry = (campaign_id, user_id) =>
		apireq.post(`/campaigns/${campaign_id}/outbound-call-entries/${user_id}/delete`)
	this.reassign_call_entries = (params) =>
		apireq.post(`/campaigns/${params.campaign_id}/outbound-call-entries/-/reassign`, params)

	this.get_outbound_call_entry_detail = (campgId, userId) =>
		apireq.get(`/campaigns/${campgId}/outbound-call-entries/${userId}`)
	this.get_next_call = async (campgId) => {
		let res = await apireq.get(`/campaigns/${campgId}/outbound-call-entries/next`)
		//return {
		//body: {
		//number: '0987814392,0913021712',
		//campaign_id: campgId,
		//user_id: 'usrqrsmtiudezydnsdxov',
		//},
		//}
		if (res.error) return {error: res.error}
		return res
	}

	this.list_segment = async () => {
		let out = await apireq.get(`/accounts/${accid}/segments`)
		if (out.error) return {error: out.error}
		let segments = lo.get(out, 'body.segments', [])
		segments = lo.filter(segments, (segment) => segment.created)
		return {body: segments, error: out.error}
	}
	this.create_segment = (p) => apireq.post(`/accounts/${accid}/segments`, p)
	this.update_segment = (p) => apireq.post(`/accounts/${accid}/segments/${p.id}`, p)
	this.delete_segment = (id) => apireq.post(`/accounts/${accid}/segments/${id}/delete`)
	this.add_user_to_segment = (user_ids, sid) => apireq.post(`/accounts/${accid}/segments/${sid}/users`, {user_ids})
	this.remove_user_from_segment = (user_ids, sid) =>
		apireq.post(`/accounts/${accid}/segments/${sid}/users/-/delete`, {user_ids})

	this.remove_user = (uid) => apireq.post(`/accounts/${accid}/users/${uid}/delete`)
	this.restore_user = (uid) => apireq.post(`/accounts/${accid}/users/${uid}/restore`)
	this.request_invoice = (p) => apireq.post(`/accounts/${accid}/invoice-requests`, p)

	this.list_call_setting = async () => {
		let out = await apireq.get(`/accounts/${accid}/call-settings`)
		if (out.error) return {error: out.error}
		let out2 = await apireq.get(`/accounts/${accid}/integrations`)
		if (out2.error) return {error: out2.error}

		let settings = lo.get(out, 'body.call_settings', [])
		let intes = lo.get(out2, 'body', [])
		intes = lo.filter(intes, (inte) => inte.connector_type === 'call')
		let body = lo.map(intes, (inte) => {
			let number = lo.split(inte.id, '.')
			number = lo.get(number, '1')
			let setting = lo.find(settings, (s) => s.number === number)
			if (setting) return setting
			return {number}
		})

		return {body, error: out.error}
	}
	this.update_call_setting = (p) => apireq.post(`/accounts/${accid}/call-settings/${p.number}`, p)

	this.check_shipping_policy = (order) => apireq.post(`/accounts/${accid}/shipping-policy-checks`, order)
	this.update_shipping_policy = (p) => apireq.post(`/accounts/${accid}/shipping-policies/${p.id}`, p)
	this.create_shipping_policy = (p) => apireq.post(`/accounts/${accid}/shipping-policies`, p)
	this.delete_shipping_policy = (id) => apireq.post(`/accounts/${accid}/shipping-policies/${id}/delete`)

	this.make_order_payment = ({orderid, method, desc, amount, pic, paid_at}) => {
		return apireq.post(`/accounts/${accid}/orders/${orderid}/payments`, {
			method,
			picture_url: pic,
			description: desc,
			amount,
			paid_at,
		})
	}

	this.record_payment = ({currency, conversation_id, user_id, order_id, method, desc, amount, pic, paid_at}) => {
		let conversation_ids = undefined
		if (conversation_id != '') conversation_ids = conversation_id

		return apireq.post(`/payments`, {
			user_id,
			method,
			conversation_ids,
			picture_url: pic,
			currency,
			description: desc,
			amount,
			paid_at,
		})
	}

	this.make_call = (p) => apireq.post(`/accounts/${accid}/calls`, p)

	this.download_order = (order, template, locale, cd) =>
		apireq.post(`/accounts/${accid}/orders/${order.id}/downloads`, {order, template, locale, content_disposition: cd})
	this.comment_order = (orderid, ev) => apireq.post(`/accounts/${accid}/orders/${orderid}/comments`, ev)
	this.list_order_events = (orderid) => apireq.get(`/accounts/${accid}/orders/${orderid}/history-entries`)
	this.get_shopee_auth_url = () => apireq.get(`/accounts/${accid}/shopee-authorize-urls`)
	this.authorize_shopee = (p) => apireq.post(`/accounts/${accid}/authorize-shopee`, p)

	this.sync_shopee = (p) => apireq.post(`/accounts/${accid}/sync-shopee`, p)
	this.delete_shopee_shop = (shop_id) => apireq.post(`/accounts/${accid}/deauthorize-shopee`, {id: shop_id + ''})

	this.list_provices = () => apireq.get(`/accounts/${accid}/provinces`)
	this.list_districts = (p) => apireq.setQuery(p).get(`/accounts/${accid}/districts`)
	this.list_wards = (p) => apireq.setQuery(p).get(`/accounts/${accid}/wards`)

	this.get_zalo_challenge = () => apireq.get(`/accounts/${accid}/zalo-challenges`)

	this.load_screen = (url, device) => apireq.setQuery({url: url, device: device}).get(`/accounts/${accid}/screenshoots`)

	this.list_blocked_emails = async (id) => {
		let out = await apireq.addQuery('id', id).get(`/accounts/${accid}/blocked-emails`)
		return {body: lo.get(out.body, 'blocked_emails', []), error: out.error}
	}
	this.create_blocked_emails = async (p) => {
		let {body: out} = await apireq.post(`/accounts/${accid}/blocked-emails/${p.id}`, {email_type: p.email_type})
		return {body: out.blocked_email}
	}
	this.delete_blocked_emails = (id) => apireq.post(`/accounts/${accid}/blocked-emails/${id}/delete`)

	this.list_bounced_emails = async (id) => {
		let out = await apireq.addQuery('id', id).get(`/accounts/${accid}/bounced-emails`)
		return {body: lo.get(out.body, 'bounced_emails', []), error: out.error}
	}
	this.delete_bounced_emails = (id) => apireq.post(`/accounts/${accid}/bounced-emails/${id}/delete`)

	this.list_block_number = async () => {
		let out = await apireq.get(`/accounts/${accid}/blocked-numbers`)
		return {body: lo.get(out.body, 'blocked_numbers', []), error: out.error}
	}
	this.create_block_number = (p) => apireq.post(`/accounts/${accid}/blocked-numbers/${p.number}`)
	this.delete_block_number = (id) => apireq.post(`/accounts/${accid}/blocked-numbers/${id}/delete`)

	this.list_conversation_automation = async () => {
		let out = await apireq.get(`/accounts/${accid}/conversation-automations`)
		return {body: lo.get(out.body, 'conversation_automations', []), error: out.error}
	}
	this.send_zns = (p) => apireq.post(`/zalo/send-zns`, p)
	this.get_fields_by_view = (query) => apireq.post(`accounts/${accid}/fields-by-view`, query)
	this.import_leads = (p) => apireq.post(`accounts/${accid}/user-imports`, p)
	this.check_user_allow_zcc_call = ({user_id, oa_id}) =>
		apireq.setQuery({user_id, oa_id}).get(`/zalo/check-user-consent`)
	this.send_allow_zcc_call = (p) => apireq.post(`/zalo/request-user-consent`, p)

	this.list_products = (query) => apireq.post(`/accounts/${accid}/products-search`, query)
	this.list_bills = () => apireq.get(`/accounts/${accid}/bills`)
	this.list_credits = () => apireq.get(`/accounts/${accid}/credits`)
	this.fetch_credit_logs = (query) => this.api41.setQuery(query).get(`/credits/${query.credit_id}/logs`)
	this.fetch_credit_reports = (query) => this.api41.post(`/credits/${query.credit_id}/reports`, query)

	this.list_zns_template = async () => {
		let res = await this.api41.get('/zns-templates')
		if (res.error) return res

		let temps = lo.get(res, 'body.zns_templates') || []
		return {body: {zns_templates: temps, is_all: true}}
	}
	this.create_zns_template = (p) => this.api41.post('/zns-templates', p)
	this.update_zns_template = (template) => this.api41.post(`/zns-templates/${template.id}`, template)
	this.match_zns_template = async (ids, modifieds) => {
		let res = await this.api41.post(`match/zns-templates`, {ids, modifieds})
		if (res.error) return res

		let temps = lo.get(res, 'body.zns_templates') || []
		return {body: {zns_templates: temps}, code: res.code}
	}
	this.send_test_zns_template = (id, body) => this.api41.post(`/zns-templates/${id}/send`, body)
	this.send_test_subiz_zns_template = (p) => this.api41.post(`/test-zns`, p)

	this.list_zns_media = () => this.api41.get('/zns-medias')
	this.create_zns_media = (p) => this.api41.post('/zns-medias', p)
	this.delete_zns_media = (id) => this.api41.post(`/zns-medias/${id}/delete`)
	this.match_zns_media = (ids, modifieds) => this.api41.post(`/match/zns-medias`, {ids, modifieds})

	// product_collection
	this.create_product_collection = async (collection) => {
		let out = await apireq.post(`accounts/${accid}/product-collections`, collection)
		if (out.error) return {error}
		return out.body
	}

	this.update_product_collection = async (collection) => {
		let out = await apireq.post(`accounts/${accid}/product-collections/${collection.id}`, collection)
		if (out.error) return {error}
		return out.body
	}
	this.delete_product_collection = (id) => {
		return apireq.post(`accounts/${accid}/product-collections/${id}/delete`)
	}

	this.list_product_collection = async () => {
		let {body, error} = await apireq.addQuery('limit', 1000).get(`/accounts/${accid}/product-collections`)
		if (error) return {error}
		return {body: body.product_collections || []}
	}

	this.search_sub = async (keyword) => {
		let {body, error} = await apireq.addQuery('query', keyword).get(`/accountsubs`)
		if (error) return {error}
		return body.accsub
	}

	this.search_ref = async (keyword) => {
		let {body, error} = await apireq.addQuery('keyword', keyword).get(`/referrer-agents`)
		if (error) return {error}
		return body.referrer_agents
	}

	this.fetch_sub = async (accid) => {
		let {body, error} = await apireq.get(`/accountsubs/${accid}`)
		if (error) return {error}
		return body
	}
	this.acc_list_programs = (keyword) => this.api41.addQuery('keyword', keyword).get(`promograms`)
	this.acc_list_program_codes = (programid) => this.api41.get(`promograms/${programid}/promocodes`)
	this.acc_list_redeem_codes = (programid) => this.api41.get(`promograms/${programid}/invoices`)
	this.list_agent_promocodes = (agid) => this.api41.get(`agents/${agid}/promocodes`)
	this.acc_update_promocode = (programid, code) => this.api41.post(`promograms/${programid}/promocodes`, code)
	this.acc_delete_promocode = (programid, code) => this.api41.post(`promograms/${programid}/promocodes/${code}/delete`)
	this.check_promocode = (code, inv) => this.api41.post(`promocodes/${code}`, inv)
	this.acc_get_promocode = (code) => this.api41.get(`promograms/-/promocodes/${code}`)
	this.acc_apply_promocode = (code) => this.api41.post(`promocodes/${code}/redeem`)
	this.acc_list_program_invoices = (id) => this.api41.get(`promograms/${id}/invoices`)

	this.acc_delete_agent = (ag) => this.api41.post(`/accounts/${ag.account_id}/agents/${ag.id}/delete`)
	this.acc_fetch_sites = (accid) => this.api41.get(`/accounts/${accid}/sites`)
	this.acc_delete_account = (accid) => this.api41.post(`/accounts/${accid}/delete`)
	this.acc_update_invoice = (invoice) =>
		this.api41.post(`/accounts/${invoice.account_id}/invoices/${invoice.id}`, invoice)
	this.create_invoice = (accid, invoice) => this.api41.post(`accounts/${accid}/invoices`, invoice)
	this.update_invoice = (invoice) => this.api41.post(`/accounts/${accid}/invoices/${invoice.id}`, invoice)

	this.acc_update_credit = (credit) => this.api41.post(`/accounts/${credit.account_id}/credits/${credit.id}`, credit)
	this.acc_lock_login = (accid) => this.api41.post(`/accounts/${accid}/login-lock`)
	this.acc_unlock_login = (accid) => this.api41.post(`/accounts/${accid}/login-lock/delete`)
	this.acc_update_use_ticket = (accid, v) =>
		this.api41.setQuery({'x-fields': 'use_ticket'}).post(`/subscriptions/${accid}/`, {use_ticket: v ? Date.now() : 0})

	this.set_hellobar = (hellobar) => this.api41.post(`hello-bar`, hellobar)
	this.get_hellobar = (force) => this.api41.addQuery('force', force).get(`hello-bar`)

	this.updateFcmToken = (token, device_id, platform) =>
		this.api41.post(`notification-tokens/${token}`, {platform: platform ? platform : 'mobile', device_id})
	this.deleteFcmToken = (token, cb) => plainapireq.post(`notification-tokens/${token}/delete`, cb)
	this.shorten_payment = async (data) => {
		let res = await this.api41.post('/shorten2', data)
		if (res.error) return res

		let str = lo.get(res, 'body.str')
		return {body: str}
	}

	this.collect = (metric, value) => apireq.addQuery('metric', metric).addQuery('value', value).post('/collect')
	this.collectLog = (line) => logreq.post('/collect', line)
	this.startLog = () => {
		_shouldlog = true
	}

	this.create_subiz_ticket = (ticket) => this.noauthapi.post(`/accounts/acpxkgumifuoofoosble/tickets`, ticket)
	this.create_ticket = (ticket) => apireq.post(`/accounts/${accid}/tickets`, ticket)
	this.update_ticket = (ticket, fields) =>
		apireq
			.setQuery({'x-fields': (fields || []).join(',')})
			.post(`/accounts/${accid}/tickets/${ticket.id || '-'}`, ticket)
	this.delete_ticket = (id) => apireq.post(`/accounts/${accid}/tickets/${id}/delete`)
	this.restore_ticket = (id) => apireq.post(`/accounts/${accid}/tickets/${id}/recover`)
	this.get_ticket = (id) => apireq.get(`/accounts/${accid}/tickets/${id}`)
	this.list_ticket = (filter = {}) => apireq.post(`/accounts/${accid}/tickets-list`, filter)
	this.match_ticket = (ids, modifieds) =>
		apireq.post(`/accounts/${accid}/match/tickets`, {ids, modifieds, limit: ids.length})

	this.list_ticket_view = async () => {
		let res = await apireq.get(`/accounts/${accid}/ticket-views`)
		if (res.error) return res
		let views = lo.get(res, 'body.ticket_views', [])
		return {body: views}
	}
	this.update_ticket_view = (uv) => apireq.post(`accounts/${accid}/ticket-views/${uv.id}`, uv)
	this.create_ticket_view = (uv) => apireq.post(`accounts/${accid}/ticket-views`, uv)
	this.delete_ticket_view = (id) => apireq.post(`accounts/${accid}/ticket-views/${id}/delete`)

	this.list_ticket_type = async () => {
		let res = await apireq.get(`/accounts/${accid}/ticket-types`)
		if (res.error) return res
		let types = lo.get(res, 'body.ticket_types', [])
		return {body: types}
	}
	this.update_ticket_type = (uv) => apireq.post(`accounts/${accid}/ticket-types/${uv.id}`, uv)
	this.create_ticket_type = (uv) => apireq.post(`accounts/${accid}/ticket-types`, uv)
	this.delete_ticket_type = (id) => apireq.post(`accounts/${accid}/ticket-types/${id}/delete`)
	this.list_ticket_events = (id) => apireq.get(`accounts/${accid}/tickets/${id}/events`)
	this.create_ticket_event = (id, ev) => apireq.post(`accounts/${accid}/tickets/${id}/events`, ev)
	this.rate_ticket = (id, rating) => apireq.post(`/tickets/${id}/ratings`, rating)
	this.read_tickets = (ids) => this.api41.post(`/tickets/-/read`, {ids})
	this.unread_tickets = (ids) => this.api41.post(`/tickets/-/unread`, {ids})
	this.read_ticket = (id) => this.api41.post(`/tickets/${id}/read`)
	this.unread_ticket = (id) => this.api41.post(`/tickets/${id}/unread`)
	this.update_ticket_member = (id, member) => apireq.post(`accounts/${accid}/tickets/${id}/members`, member)
	this.delete_ticket_member = (id, memberid) =>
		apireq.post(`accounts/${accid}/tickets/${id}/members/${memberid}/delete`)
	this.subscribe_ticket = (ids) => apireq.post(`accounts/${accid}/tickets/subscribed`, {ids})
	this.unsubscribe_ticket = (ids) => apireq.post(`accounts/${accid}/tickets/unsubscribed`, {ids})
	this.get_bot_template = (templateid) => apireq.get(`bot-templates/${templateid}`)
	this.get_bot_token = (botid) => apireq.post(`accounts/${accid}/bots/${botid}/tokens`)
	this.report_ticket = (p) => apireq.post(`/report/tickets`, p)
	this.report_ticket_rating = (p) => apireq.post(`report/ratings`, p)
	this.report_sla_violations = (p) => apireq.post(`report/sla-violations`, p)
	this.report_ticket_rating_list = (p) => apireq.post(`accounts/${accid}/ratings-list`, p)
	this.report_sla_violation_list = (p) => apireq.post(`accounts/${accid}/sla-violations-list`, p)

	this.create_bank_transfer_request = (p) => this.api41.post(`bank-transfer-requests`, p)
	this.list_ticket_template = async () => {
		let res = await apireq.get(`/accounts/${accid}/ticket-templates`)
		if (res.error) return res
		let templates = lo.get(res, 'body.ticket_templates', [])
		return {body: templates}
	}
	this.update_ticket_template = (uv) => apireq.post(`accounts/${accid}/ticket-templates/${uv.id}`, uv)
	this.create_ticket_template = (uv) => apireq.post(`accounts/${accid}/ticket-templates`, uv)
	this.delete_ticket_template = (id) => apireq.post(`accounts/${accid}/ticket-templates/${id}/delete`)

	this.list_sla_policy = async () => {
		let res = await apireq.get(`/accounts/${accid}/sla-policies`)
		if (res.error) return res
		let templates = lo.get(res, 'body.sla_policies', [])
		return {body: templates}
	}
	this.update_sla_policy = (sla) => apireq.post(`accounts/${accid}/sla-policies/${sla.id}`, sla)
	this.create_sla_policy = (sla) => apireq.post(`accounts/${accid}/sla-policies`, sla)
	this.delete_sla_policy = (id) => apireq.post(`accounts/${accid}/sla-policies/${id}/delete`)
	this.list_ticket_sla_violations = (id) => apireq.get(`accounts/${accid}/sla-violations/${id}`)
	this.list_knowledge_base = async () => {
		let res = await apireq.get(`/accounts/${accid}/knowledge-bases`)
		if (res.error) return res
		return {body: lo.get(res, 'body.knowledge_bases', [])}
	}
	this.get_knowledge_base_detail = async (id) => {
		let res = await apireq.get(`/accounts/${accid}/knowledge-bases/${id}`)
		return res
	}
	this.update_knowledge_base_tree = async (kbid, tree) => {
		let res = await this.api41.post(`/knowledge-bases/${kbid}/tree`, tree)
		return res
	}
	this.update_knowledge_base = async (kb) => {
		let res = await apireq.post(`accounts/${accid}/knowledge-bases/${kb.id}`, kb)
		if (res.error) return res
		return {body: lo.get(res, 'body.knowledge_base', [])}
	}
	this.create_knowledge_base = async (kb) => {
		let res = await apireq.post(`accounts/${accid}/knowledge-bases`, kb)
		if (res.error) return res
		return {body: lo.get(res, 'body.knowledge_base', [])}
	}
	this.delete_knowledge_base = (id) => apireq.post(`accounts/${accid}/knowledge-bases/${id}/delete`)

	this.list_article = async () => {
		let res = await apireq.post(`/accounts/${accid}/articles-list`, {limit: 10000})
		if (res.error) return res
		return {body: lo.get(res, 'body.articles', [])}
	}
	this.update_article = async (article, fields) => {
		let res = await apireq
			.setQuery({'x-fields': (fields || []).join(',')})
			.post(`accounts/${accid}/articles/${article.id}`, article)
		if (res.error) return res
		return {body: lo.get(res, 'body.article', {})}
	}

	this.create_article = async (article) => {
		let res = await apireq.post(`accounts/${accid}/articles`, article)
		if (res.error) return res
		return {body: lo.get(res, 'body.article', [])}
	}

	this.get_article = async (article_id) => {
		let res = await apireq.get(`accounts/${accid}/articles/${article_id}`)
		if (res.error) return res
		return {body: lo.get(res, 'body.article', {})}
	}
	this.delete_article = (id) => apireq.post(`accounts/${accid}/articles/${id}/delete`)
	this.list_article_reports = (art_id) => this.api41.get(`accounts/${accid}/articles/${art_id}/reports`)

	this.list_article_versions = async (art_id) => {
		let res = await this.api41.get(`/articles/${art_id}/versions`)
		if (res.error) return res
		return lo.get(res, 'body', {})
	}
	this.get_article_version = async (art_id, version) => {
		let res = await apireq.setQuery(version).get(`accounts/${accid}/articles/${art_id}`)
		if (res.error) return res
		return {body: lo.get(res, 'body.article', {})}
	}

	this.list_article_topic = async (kbid) => {
		let res = await this.api41.get(`accounts/${accid}/article-topics-list/${kbid}`)
		if (res.error) return res
		return {body: lo.get(res, 'body.topics', [])}
	}
	this.create_article_topic = async (topic) => {
		let res = await this.api41.post(`accounts/${accid}/article-topics`, topic)
		if (res.error) return res
		return {body: lo.get(res, 'body.topic', {})}
	}
	this.update_article_topic = async (topic_id, data) => {
		let res = await this.api41.post(`accounts/${accid}/article-topics/${topic_id}`, data)
		if (res.error) return res
		return {body: lo.get(res, 'body.topic', {})}
	}

	this.get_kb_event = async (kbid, data) => {
		let res = await this.api41.post(`accounts/${accid}/knowledge-bases/${kbid}/events`, data)
		if (res.error) return res
		return {body: lo.get(res, 'body.events', [])}
	}

	this.remove_article_topic = (id) => this.api41.post(`accounts/${accid}/article-topics/${id}/delete`)

	this.add_topic_article = (article_id, topic_id) =>
		this.api41.post(`accounts/${accid}/articles/${article_id}/topics/${topic_id}`)
	this.remove_topic_article = (article_id, topic_id) =>
		this.api41.post(`accounts/${accid}/articles/${article_id}/topics/${topic_id}/delete`)

	this.list_article_category = async () => {
		let res = await apireq.get(`/accounts/${accid}/article-categories`)
		if (res.error) return res
		return {body: lo.get(res, 'body.article_categories', [])}
	}
	this.update_article_category = async (cat) => {
		let res = await apireq.post(`accounts/${accid}/article-categories/${cat.id}`, cat)
		if (res.error) return res
		return {body: lo.get(res, 'body.article_category', [])}
	}
	this.create_article_category = async (cat) => {
		let res = await apireq.post(`accounts/${accid}/article-categories`, cat)
		if (res.error) return res
		return {body: lo.get(res, 'body.article_category', [])}
	}

	this.delete_article_category = (id) => apireq.post(`accounts/${accid}/article-categories/${id}/delete`)

	this.get_referred_agent = async (agid) => await apireq.get(`referrer-agents/${agid}`)
	this.list_referrer_bills = async (agid) => {
		let res = await apireq.get(`referrer-agents/${agid}/referrer-bills`)
		if (res.error) return res
		return {body: lo.get(res, 'body.bills', [])}
	}
	this.list_referrer_customers = async (agid) => {
		let res = await apireq.get(`referrer-agents/${agid}/referrer-customers`)
		if (res.error) return res
		return {body: lo.get(res, 'body.referred_customers', [])}
	}
	this.list_referrer_payouts = async (agid) => {
		let res = await apireq.get(`referrer-agents/${agid}/referrer-payouts`)
		if (res.error) return res
		return {body: lo.get(res, 'body.referrer_payout_bills', [])}
	}

	this.pay_referrer = async (agid, p) => {
		let res = await apireq.post(`referrer-agents/${agid}/referrer-payouts`, p)
		if (res.error) return res
		return {body: {}}
	}

	this.update_bank_info = (info) => apireq.post(`referrer-agents/${info.ref_id}`, info)

	this.accept_invitation = (code, p) => {
		p = Object.assign({}, p)
		p.link = code
		return this.api41.post('accepts', p)
	}

	this.update_password = (email, code, newpassword) =>
		this.api41.post(`passwords`, {token: code, email: email, new_password: newpassword})
	this.list_agent_accounts = (agid) => this.api41.addQuery('account-id', '').get(`agents/${agid}/accounts`)
	this.get_invite_account_link = () => this.api41.get(`invite-links`)
	this.send_invite_email = (emails, scope) =>
		this.api41.addQuery('email', emails).addQuery('scope', scope).post(`agent-invites`)
	this.check_invite_link = (link_id) => this.noauthapi.get(`invite-links/${link_id}`)
	this.request_otp = (email) => this.noauthapi.addQuery('email', email).post(`otps`)
	this.check_otp = (otp, email) => this.noauthapi.addQuery('otp', otp).addQuery('email', email).post(`tokens`)
	this.check_email_exist = (email) => this.noauthapi.get(`emails/${email}`)
	this.update_agent_profile = async (ag) => {
		let out = await this.api41.addQuery('account-id', '').post('agents/' + ag.id, ag)
		if (out.error) return out
		let agent = lo.get(out, 'body.agent_profile', {})
		this.agentprofile = agent
		return agent
	}

	this.match_ai_data_entry = (ids, last_modifieds) => this.api41.post(`match/ai-data-entries`, {ids, last_modifieds})
	this.list_ai_data_entry = (p) => {
		p = p || {}
		if (!p.limit) p.limit = 20
		return this.api41.setQuery(p).get(`ai-data-entries`)
	}
	this.create_ai_data_entry = (entry) => this.api41.post(`ai-data-entries`, entry)
	this.update_ai_data_entry = (entry) => this.api41.post(`ai-data-entries/${entry.id}`, entry)
	this.delete_ai_data_entry = (id) => this.api41.post(`ai-data-entries/${id}/delete`)

	this.test_ai_agent = (p) => this.api41.post(`ai-agents/run-test`, p)
	this.list_ai_agent = async () => {
		let res = await this.api41.get(`ai-agents`)
		if (res.error) return res
		return {body: lo.get(res, 'body.ai_agents', [])}
	}
	this.get_ai_agent = async (id, version) => {
		let res = await this.api41.setQuery({version}).get(`ai-agents/${id}`)
		if (res.error) return res
		return {body: lo.get(res, 'body.ai_agent', {})}
	}
	this.update_ai_agent = async (ai_agent) => {
		let res = await this.api41.post(`ai-agents/${ai_agent.id}`, ai_agent)
		if (res.error) return res
		let obj = lo.get(res, 'body.ai_agent') || {}
		return {body: obj}
	}
	this.update_draft_ai_agent = async (ai_agent) => {
		let res = await this.api41.setQuery({is_draft: 'true'}).post(`ai-agents/${ai_agent.id}`, ai_agent)
		if (res.error) return res
		let obj = lo.get(res, 'body.ai_agent') || {}
		return {body: obj}
	}
	this.create_ai_agent = async (ai_agent) => {
		let res = await this.api41.post(`ai-agents`, ai_agent)
		if (res.error) return res
		let obj = lo.get(res, 'body.ai_agent') || {}
		return {body: obj}
	}
	this.delete_ai_agent = (id) => this.api41.post(`ai-agents/${id}/delete`)
	this.list_workflow = async () => {
		let res = await this.api41.get(`workflows`)
		if (res.error) return res
		return {body: lo.get(res, 'body.workflows', [])}
	}
	this.get_detail_workflow = (id) => this.api41.get(`workflows/${id}`)
	this.update_workflow = async (workflow) => {
		let res = await this.api41.post(`workflows/${workflow.id}`, workflow)
		if (res.error) return res
		let obj = lo.get(res, 'body.workflow') || {}
		return {body: obj}
	}
	this.create_workflow = async (workflow) => {
		let res = await this.api41.post(`workflows`, workflow)
		if (res.error) return res
		let obj = lo.get(res, 'body.workflow') || {}
		return {body: obj}
	}
	this.delete_workflow = (id) => this.api41.post(`workflows/${id}/delete`)
	this.get_workflow_report = (id, params) => this.api41.post(`workflows/${id}/reports`, params)
	this.get_workflow_session_list = (id, params) => this.api41.setQuery(params).get(`workflows/${id}/sessions`)
	this.get_workflow_logs = (id, params) =>
		this.api41.setQuery(params).get(`workflows/${id}/sessions/${params.session_id}/logs`)
	this.get_workflow_session_detail = ({workflow_id, session_id}) =>
		this.api41.get(`workflows/${workflow_id}/sessions/${session_id}`)
	this.command_workflow_session = (params) =>
		this.api41.post(`workflows/${params.workflow_id}/sessions/${params.session_id}`, params)
	this.create_workflow_session = (workflow_id, params) => this.api41.post(`workflows/${workflow_id}/sessions`, params)
	this.run_preview_action = (p) => this.api41.post(`workflows/run-actions`, p)

	this.list_ai_qna_sugesstion = (p) => this.api41.setQuery(p).get(`/ai-qna-suggestions`)
	this.update_ai_qna_suggestion = (p) => this.api41.post(`/ai-qna-suggestions/${p.id}`, p)
	this.delete_ai_qna_suggestion = (id) => this.api41.post(`/ai-qna-suggestions/${id}/delete`)

	this.list_setup_features = () => this.api41.get(`/accounts/${accid}/setup_feature_status`)
	this.update_setup_features = (p) => this.api41.post(`/accounts/${accid}/setup_feature_status`, p)

	this.list_ai_response_source = (id) => this.api41.get(`/ai-response-sources/${id}`)
	this.list_crawl = (p) => this.api41.setQuery(p).get(`/crawls`)

	this.list_ai_entry_chunks = (entryid) => this.api41.get(`/ai-data-entries/${entryid}/chunks`)
	this.retrain_ai_entry = (entryid) => this.api41.post(`/ai-data-entries/${entryid}/trainings`)

	this.list_facebook_post = (p) => this.api41.setQuery(p).get(`/facebook-posts`)
	this.match_facebook_post = (ids, last_modifieds) => this.api41.post(`/match/facebook-posts`, {ids, last_modifieds})

	this.list_email_signature = () => this.api41.get('/email-signatures')
	this.create_email_signature = (p) => this.api41.post('/email-signatures', p)
	this.update_email_signature = (p) => this.api41.post(`/email-signatures/${p.id}`, p)
	this.delete_email_signature = (id) => this.api41.post(`/email-signatures/${id}/delete`)
	this.match_emaiil_signature = (ids, last_modifieds) =>
		this.api41.post(`/match/email-signatures`, {ids, last_modifieds})

	this.widget_create_convo = (p) =>
		widgetapireq.setQuery({v: '6', 'x-user-ref': agentid}).post(`/accounts/${accid}/conversations`, p)
	this.widget_send_convo_event = (cid, ev) =>
		widgetapireq.setQuery({v: '6', 'x-user-ref': agentid}).post(`/accounts/${accid}/conversations/${cid}/events`, ev)
	this.widget_fetch_user = () =>
		widgetapireq.setQuery({v: '6', 'x-user-ref': agentid}).get(`/accounts/${accid}/users/${agentid}`)
}

let apiObj = new Api()
module.exports = apiObj
