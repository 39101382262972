export const SCROLLBAR_OFFSET = 'calc(-1 * var(--removed-body-scroll-bar-size, 0px) / 2)'
export const SCROLLBAR_OFFSET_POSITIVE = 'calc(var(--removed-body-scroll-bar-size, 0px) / 2)'

/**
 * Useful for visually aligning icons within header buttons with the elements
 * below them on the screen. Apply positively or negatively depending on side
 * of the screen you're on.
 */
export const BUTTON_VISUAL_ALIGNMENT_OFFSET = 3

/**
 * Corresponds to the width of a small square or round button
 */
export const HEADER_SLOT_SIZE = 34

// Hitslop constants
export const createHitslop = (size) => ({
	top: size,
	left: size,
	bottom: size,
	right: size,
})
export const HITSLOP_10 = createHitslop(10)
export const HITSLOP_20 = createHitslop(20)
export const HITSLOP_30 = createHitslop(30)
