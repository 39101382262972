// like db but implement using localstorage
let memlocalStorage = require('./inmem_localstorage.js')

let requestIdleCallback = typeof window !== 'undefined' ? window.requestIdleCallback : null
if (!requestIdleCallback) requestIdleCallback = (cb) => cb()

const LIMIT = 80

export default class {
	constructor(schema, inmem) {
		this.localStorage = memlocalStorage
		this.inmem = inmem
		this.allkeys = {}
		if (!inmem) {
			this.localStorage = window.myLocalStorage
		}

		this.schema = schema
	}

	all(table) {
		let items = []
		for (var i = 0; i < this.localStorage.length; i++) {
			let key = this.localStorage.key(i)
			if (!key.startsWith(this.schema + '#' + table + '#')) continue
			this.allkeys[key] = true
			if (this.inmem)
				items.push(this.localStorage.getItem(key)) // fast path
			else items.push(parseJSON(this.localStorage.getItem(key)))
		}
		return items
	}

	removes(table, keys) {
		for (var i = 0; i < keys.length; i++) {
			delete this.allkeys[this.schema + '#' + table + '#' + keys[i]]
			this.localStorage.removeItem(this.schema + '#' + table + '#' + keys[i])
		}
	}

	clear(table) {
		for (var i = 0; i < this.localStorage.length; i++) {
			let key = this.localStorage.key(i)
			if (!key.startsWith(this.schema + '#' + table + '#')) continue
			this.localStorage.removeItem(key)
		}
		this.allkeys = {}
	}

	// write a (key,value) pair to the table
	// if the localstorage is already full, it would try to free by delete the whole table!
	put(table, key, value) {
		this.allkeys[this.schema + '#' + table + '#' + key] = true
		if (this.inmem) return this.localStorage.setItem(this.schema + '#' + table + '#' + key, value) // fast path
		if (Object.keys(this.allkeys).length > LIMIT) this.clear(table)
		requestIdleCallback(() => {
			try {
				this.localStorage.setItem(this.schema + '#' + table + '#' + key, toString(value))
			} catch (e) {
				this.clear(table)
			}
		})
	}
}

function toString(obj) {
	if (obj === undefined) return 'null'
	return JSON.stringify(obj)
}

function parseJSON(str) {
	try {
		return JSON.parse(str)
	} catch (e) {
		console.error(e)
	}
}
