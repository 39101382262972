import React from 'react'
import {StyleSheet} from 'react-native'
import {space, color, atoms as a} from '@/base'

export function Progress({size, failed, progress, total, style}) {
	let height = space.xs
	if (size == 'md') height = space.md
	if (size == 'sm') height = space.sm
	if (size == 'lg') height = space.lg
	if (size == 'xs') height = space.xs
	total == total || 1
	failed = failed || 0
	progress = progress || 0
	let $failed = (
		<View style={{height: height, width: `${(failed * 100) / total}%`, backgroundColor: color.negative_600}}></View>
	)
	let $current = (
		<View
			style={{height: height, width: `${((progress - failed) * 100) / total}%`, backgroundColor: color.primary}}
		></View>
	)
	return (
		<View
			style={StyleSheet.flatten([
				a.overflow_hidden,
				a.rounded_xs,
				a.flex_row,
				{height: height, width: '100%', backgroundColor: color.contrast_50},
				style,
			])}
		>
			{$current}

			{$failed}
		</View>
	)
}
