import store from '@sb/store'
var sb = require('@sb/util')
import MessageEventActions from './message_event_actions.js'

export default {
	name: 'text-message',
	props: ['ev', 'comment', 'task_comment', 'is_quote'],

	data() {
		return {
			detectUserInfo: '',
			focused: false,
		}
	},

	created() {
		this.$root.$on('focusEvent', this.justFocus)
	},

	beforeDestroy() {
		this.$root.$off('focusEvent', this.justFocus)
	},

	render() {
		var format = lo.get(this.ev, 'data.message.format', 'plaintext')
		if (this.is_quote) return this.renderQuote()
		if (lo.get(this.ev, 'data.message.block')) return this.renderBlock()
		if (format === 'delta') return this.renderDelta()
		let text = lo.get(this.ev, 'data.message.text')
		if (lo.size(text) === 0 && !this.is_quote) return null
		// sent by broadcast messenger use lexical template
		if (lo.get(this.ev, 'data.message.is_template') && lo.get(this.ev, 'data.message.format') === 'html') {
			text = sb.lexicalToPlainText(text)
		}

		let byType = lo.get(this.ev, 'by.type')
		if (byType !== 'user') byType = 'agent'
		let cId = lo.get(this.ev, 'data.message.conversation_id')
		let convo = store.matchConvo(cId) || {}
		let user = lo.find(convo.members, (mem) => mem.type === 'user') || {}
		var $tags = sb.extractText(text).map((tag, i) => {
			if (tag.type === 'emoji') return <Emoji emojiCode={tag.text} byType={byType} />
			if (tag.type === 'link') {
				return (
					<a href={absLink(tag.text)} target='_blank'>
						{tag.text}
					</a>
				)
			}
			if (tag.type === 'phone') {
				let clsDetectPhone = `detect_user_info_from_message__container detect_user_info_from_message__container__${byType} `
				if (this.detectUserInfo === `${this.ev.id}_${i}` && byType == 'user') {
					clsDetectPhone +=
						' detect_user_info_from_message__container__visible detect_user_info_from_message__container__' +
						(this.popupgoup ? 'up' : 'down')
				}
				return (
					<a
						vOn:mouseover={(e) => this.onDetectInfoMouseOver(`${this.ev.id}_${i}`, e)}
						vOn:mouseleave={(_) => this.onDetectInfoMouseLeave()}
						href={'tel:' + tag.text}
					>
						{tag.text}
						<div class={clsDetectPhone} style='user-select: none'>
							<strong>
								{this.$t('update')} {this.$t('phone_number')}
							</strong>
							<p> {this.$t('update_info_for_user', [this.$t('phone_number')])}</p>
							<div class='d-flex justify-content-end'>
								<button
									class='btn btn__sm btn__primary detect_user_info_from_message__btn_submit'
									vOn:click_prevent_stop={(_) => this.updateUserInfo(user.id, {key: 'phones', text: tag.text})}
								>
									{this.$t('update')}
								</button>
							</div>
						</div>
					</a>
				)
			}
			if (tag.type === 'email') {
				let clsDetectEmail = `detect_user_info_from_message__container detect_user_info_from_message__container__${byType}`
				if (this.detectUserInfo === `${this.ev.id}_${i}`) {
					clsDetectEmail +=
						' detect_user_info_from_message__container__visible detect_user_info_from_message__container__' +
						(this.popupgoup ? 'up' : 'down')
				}

				return (
					<a
						class='detect_user_info_from_message'
						vOn:mouseover={(e) => this.onDetectInfoMouseOver(`${this.ev.id}_${i}`, e)}
						vOn:mouseleave={(_) => this.onDetectInfoMouseLeave()}
						href={'mailto:' + tag.text}
					>
						{tag.text}
						<div class={clsDetectEmail}>
							<strong>
								{this.$t('update')} {this.$t('email')}
							</strong>
							<p> {this.$t('update_info_for_user', [this.$t('email')])}</p>
							<div class='d-flex justify-content-end'>
								<button
									class='btn btn__sm btn__primary detect_user_info_from_message__btn_submit'
									vOn:click_prevent_stop={(_) => this.updateUserInfo(user.id, {key: 'emails', text: tag.text})}
								>
									{this.$t('update')}
								</button>
							</div>
						</div>
					</a>
				)
			}
			return tag.text
		})
		let cls = `message_text message_text__${byType}`
		if (this.task_comment) cls += ' text__sm'
		let isDelete = false
		lo.map(lo.get(this.ev, 'data.message.pongs', []), (pong) => {
			if (pong.type === 'delete') isDelete = true
		})

		if (isDelete) cls += ' message_text__deleted'
		let createdtime = new Date(sb.unixSec(this.ev.created) * 1000)

		let createdtimestr = createdtime.toLocaleDateString() + ' ' + createdtime.toLocaleTimeString()

		let $sender = null
		if (this.comment) {
			let agentM = store.matchAgent()
			let ag = agentM[lo.get(this.ev, 'by.id')]
			let fullname = sb.getAgentDisplayName(ag)
			$sender = <div class='text__muted'>{fullname}</div>
		}
		if (this.task_comment) {
			let agentM = store.matchAgent()
			let ag = agentM[lo.get(this.ev, 'by.id')]
			let fullname = sb.getAgentDisplayName(ag)
			$sender = (
				<div class='text__sm'>
					<span class='text__semibold'>{fullname}</span> <Time class='text__muted' time={lo.get(this.ev, 'created')} />
				</div>
			)
		}

		if (this.focused) cls += ' focused'
		return (
			<div class={cls}>
				{$sender}
				{$tags}
				{this.renderActions()}
			</div>
		)
	},

	methods: {
		justFocus(ev) {
			if (ev.id !== this.ev.id) return
			let attachmentIndex = sb.getMsgField(ev, 'attachment_index')
			let attachments = lo.get(ev, 'data.message.attachments') || []
			attachments = lo.filter(attachments, (att) => att.type !== 'quote')
			let att = attachments[attachmentIndex]
			if (att) return // this is a quote for image or video, not for text
			this.focused = true
			if (this.focusTimeout) clearTimeout(this.focusTimeout)
			this.focusTimeout = setTimeout(() => {
				this.focused = false
			}, 3000)
		},

		renderQuote() {
			let $img = null
			let $fallbackLink = null
			let $video = null
			let attachments = lo.get(this.ev, 'data.message.attachments', [])
			let attachmentIndex = sb.getMsgField(this.ev, 'attachment_index')
			let text = lo.get(this.ev, 'data.message.text')
			if (attachmentIndex || attachmentIndex === 0) {
				let att = attachments[attachmentIndex] || {}
				if (att.type === 'file' || att.type === 'product' || att.type === 'generic') {
					let mimetype = att.mimetype || ''
					if (mimetype.startsWith('image')) {
						$img = (
							<div class='mt-1'>
								<img2 src={lo.get(att, 'url')} style='max-width: 100px; border-radius: 8px; overflow: hidden' />
							</div>
						)
					} else if (mimetype.startsWith('video')) {
						text = <em>{this.$t('sent_a_video')}</em>
					} else if (mimetype.startsWith('audio')) {
						text = <em>{this.$t('sent_an_audio')}</em>
					} else {
						text = <em>{this.$t('sent_an_attachment')}</em>
					}
				} else if (att.type === 'fallback') {
					$fallbackLink = (
						<a href={fallbackAttachment.url} target='_blank'>
							{fallbackAttachment.title}
						</a>
					)
				}
			}

			let byType = lo.get(this.ev, 'by.type')
			if (byType !== 'user') byType = 'agent'

			let cls = {
				message_text: true,
				message_text__user: byType === 'user',
				message_text__agent: byType === 'agent',
			}
			return (
				<div
					class={cls}
					style='background-color: #fff; filter: brightness(0.9); opacity: 0.6; position: relative; color: #212529; border-radius: 15px;'
				>
					{text}
					{$img}
					{$video}
					{$fallbackLink}
				</div>
			)
		},

		onAction(actionType, data) {
			if (actionType === 'reply') return this.onReplyTextMessage()
		},

		renderActions() {
			return <MessageEventActions ev={this.ev} vOn:action={this.onAction} />
		},

		onReplyTextMessage() {
			let ev = lo.cloneDeep(this.ev)
			lo.set(ev, 'data.message.attachments', [])
			this.$emit('reply', ev)
		},

		onDetectInfoMouseOver(evId, e) {
			if (document.body.scrollHeight - e.clientY < 200) {
				this.popupgoup = true
			} else {
				this.popupgoup = false
			}
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectUserInfo = evId
			}, 500)
		},

		onDetectInfoMouseLeave() {
			clearTimeout(this.detectInfoLeavinghandler)
			this.detectInfoLeavinghandler = setTimeout(() => {
				this.detectUserInfo = ''
			}, 500)
		},

		async updateUserInfo(uid, attr) {
			let user = store.matchUser(uid, true) || {}
			if (user.primary_id) {
				user = store.matchUser(user.primary_id) || {}
			}

			let currentText = sb.getUserAttr(user, attr.key, 'text')
			if (currentText === attr.text) return

			let userattr = lo.find(user.attributes, (cattr) => cattr.key === attr.key)
			let otherValues = lo.get(userattr, 'other_values', [])
			let newValue = currentText

			if (!currentText) {
				newValue = attr.text
			} else {
				otherValues.push(attr.text)
				otherValues = lo.uniq(otherValues)
			}
			attr = {
				key: attr.key,
				text: newValue,
				other_values: otherValues,
			}
			this.$updateContactAttribute({uid, attr})
		},

		renderDelta() {
			let text = lo.get(this.ev, 'data.message.quill_delta', '')
			if (!text) text = lo.get(this.ev, 'data.message.text')
			return this.renderBlock(sb.deltaToBlock(text))
		},

		renderOneBlock(block) {
			if (lo.size(block) == 0) return null
			let ele = {}
			ele.style = ''
			if (block.style) {
				lo.map(block.style, (v, k) => {
					ele.style += k + ': ' + v + ';'
				})
			}

			ele.attrs = {}
			if (block.title != '') {
				ele.attrs['title'] = block.title
			}
			if (block.href != '') {
				ele.attrs['href'] = block.href
			}

			ele.class = block.class
			lo.map(block.attrs, (v, k) => {
				ele.attrs[k] = v
			})
			if (block.type == 'bullet_list' || block.type == 'ordered_list') {
				if (block.type == 'bullet_list') {
					ele.tag = 'ol'
				} else {
					ele.tag = 'ul'
				}
			}

			if (block.type == 'list_item') {
				ele.tag = 'li'
			}

			if (block.type == 'heading') {
				if (block.level < 1) block.level = 1
				if (block.level > 6) block.level = 6
				ele.tag = 'h' + block.level
			}

			if (block.type == 'paragraph' || block.type === 'div') ele.tag = 'div'
			ele.text = block.text
			if (block.type == '' || block.type == 'text' || block.type == 'dynamic-field') {
				ele.tag = 'span'
				if (block.bold) ele.tag = 'b'
				if (block.italic) {
					if (ele.tag == 'b') {
						ele.style += 'font-style: italic;'
					} else {
						ele.tag = 'em'
					}
				}

				if (block.underline) ele.Style += 'text-decoration:underline;'
				if (block.strike_through) ele.Style += 'text-decoration: line-through;'
			}

			if (block.type == 'link') {
				ele.tag = 'a'
				ele.attrs['href'] = block.href
				ele.attrs['target'] = block.target
				ele.attrs['title'] = block.title
			}
			if (block.type == 'mention-agent' || block.type == 'mention') {
				ele.tag = 'span'
				ele.class = 'mention'

				let id = (block.attrs.id = '')

				if (block.attrs.id == '*' || block.attrs.id == 'all') {
					ele.text = '@' + this.$t('all')
				}

				if (id.startsWith('ag')) {
					let ag = store.matchAgent(id)
					let name = sb.getAgentDisplayName(ag, insert.mention.fullname)
					ele.text = '@' + name
				}
			}
			if (block.type == 'horizontal_rule') ele.tag = 'hr'
			if (block.type === 'hard_break') ele.tag = 'br'
			if (block.type == 'emoji') {
				let code = ''
				if (lo.size(block.attrs) > 0) code = block.attrs['code']
				if (code == '') code = block.Text
				if (code != '') {
					ele.tag = 'Emoji' //
					ele.attrs['emojiCode'] = code
				}
			}

			if (block.type == 'table') {
			}

			let $body = null
			if (lo.size(block.content)) {
				$body = lo.map(block.content, (block) => this.renderOneBlock(block))
			} else if (block.type === 'text') {
				let $tags = sb.extractText(ele.text).map((tag, i) => {
					if (tag.type === 'link') {
						return (
							<a href={absLink(tag.text)} target='_blank'>
								{tag.text}
							</a>
						)
					}
					return tag.text
				})
				$body = $tags
			} else {
				$body = ele.text
			}

			switch (ele.tag) {
				case 'div':
					return (
						<div class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body || ' '}
						</div>
					)
				case 'h1':
					return (
						<h1 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h1>
					)
				case 'h2':
					return (
						<h2 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h2>
					)
				case 'h3':
					return (
						<h3 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h3>
					)
				case 'h4':
					return (
						<h4 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h4>
					)
				case 'h5':
					return (
						<h5 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h5>
					)
				case 'h6':
					return (
						<h6 class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</h6>
					)
				case 'ol':
					return (
						<ol class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</ol>
					)
				case 'ul':
					return (
						<ul class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</ul>
					)
				case 'span':
					return (
						<span class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</span>
					)
				case 'em':
					return (
						<em class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</em>
					)
				case 'b':
					return (
						<b class={ele.class} style={ele.style} {...{attrs: ele.attrs}}>
							{$body}
						</b>
					)

				case 'hr':
					return <hr class={ele.class} style={ele.style} {...{attrs: ele.attrs}} />
				case 'br':
					return <br class={ele.class} style={ele.style} {...{attrs: ele.attrs}} />
				case 'a':
					return (
						<a {...{attrs: ele.attrs}} class={ele.class} style={ele.style}>
							{$body}
						</a>
					)
				case 'Emoji':
					return <Emoji {...{attrs: ele.attrs}} class={ele.class} style={ele.style} />
			}

			return (
				<component class={ele.class} is={ele.tag} style={ele.style} {...ele.attrs}>
					{$body}
				</component>
			)
		},

		renderBlock(block) {
			if (!block) block = lo.get(this.ev, 'data.message.block', {})
			if (lo.size(block) === 0) return null

			let $content = this.renderOneBlock(block)

			let byType = lo.get(this.ev, 'by.type')
			if (byType !== 'user') byType = 'agent'

			let $sender = null
			let cls = `message_text message_text__${byType}`
			if (this.task_comment) {
				let agentM = store.matchAgent()
				let ag = agentM[lo.get(this.ev, 'by.id')]
				let fullname = sb.getAgentDisplayName(ag)
				$sender = (
					<div class='text__sm'>
						<span class='text__semibold'>{fullname}</span>{' '}
						<Time class='text__muted' time={lo.get(this.ev, 'created')} />
					</div>
				)
				cls += ' text__sm'
			}
			if (this.focused) cls += ' focused'
			return (
				<div class={cls}>
					{$sender}
					{$content}
					{this.$slots.default}
					{this.renderActions()}
				</div>
			)
		},
	},
}

function absLink(url) {
	if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('//')) return 'http://' + url
	return url
}
