let prod = {
	FacebookAppId: 521984397965108,
	ApiURL: 'https://app.subiz.com.vn/4.0/',
	ApiURL41: 'https://app.subiz.com.vn/4.1/',
	FacebookAppVersion: 'v19.0',
	CaptchaSitekey: '6LexGSEUAAAAAGXO9C7yJ2XpjI4tTNn3VUPHDuQ7',
	EnableCaptcha: false,
	FileUrl: 'https://vcdn.subiz-cdn.com/file/',
	GoogleClientId: '457995922934-ehued9crfjjcmav4qaae2hi6p2u8ps8l.apps.googleusercontent.com',
	FirebaseSenderId: 457995922934,
	FbConfigID: '2529241727283052',
	FirebaseProjectId: 'subiz-version-4',
	FirebasePublichVapidKey: 'BJ2Rc-0ca5z7EURtkLMAz4l94TvLGU8EuD81vOCGdWuUkjOGPoKwUb3lbZK7JLs09gw0bzWRzkFNnlcriCtO3y4',
	FirebaseApiKey: 'AIzaSyD2d2ZUG1soUkOtIE7fDV72KuZWwtXSdiU',
	FirebaseAppId: '1:457995922934:web:87c4a8e067a3d0e500abd7',
	RealtimeURL: 'https://app.subiz.com.vn/rt/0/',
	db_version: '17',
	db_prefix: 'sb_',
	version: 'WebApp v4.4.3',
	mobile_version: '2.2.118',
}

try {
	if (location.hostname == 'app.subiz.com.vn' || location.hostname == 'beta.subiz.com.vn') {
		prod.ApiURL = '/4.0/'
		prod.ApiURL41 = '/4.1/'
		prod.RealtimeURL = '/rt/0/'
	}

	if (location.hostname == 'app.subiz.net') {
		prod.FbConfigID = '459336167031616'
		prod.ApiURL = '/4.0/'
		prod.ApiURL41 = '/4.1/'
		prod.RealtimeURL = '/rt/0/'
	}
} catch (e) {}

module.exports = prod
