// this component handle logic to load, loadmore and render datasets in table
// this expose props: query, object_type, columns
// and methods: ApplyFilter(filter), SetSelectedIds(ids)
import store from '@sb/store'
import accounting from 'accounting'
import sb from '@sb/util'

export default {
	name: 'object-table',
	// props onRowClick or on_row_click cause some convention jsx-babel bug, use row_click_cb
	// props prefer selected use when you have current selected Ids and wnnt its display at top of table in first time render
	props: [
		'init_query',
		'object_type',
		'columns',
		'selectedIds',
		'focusId',
		'customEmptyTemplate',
		'row_click_cb',
		'prefer_selected',
		'dragdrop',
		'extra_cls_cb',
	],

	data() {
		return {
			loading: false,
			loaded: false,

			syncState: '', // syncState: 'loading', 'success', 'error'
			softUpdateState: '', // 'loading', 'success', 'error'
			error: '',
			total: 0,
			anchor: '',
			loadingMore: false,
			percentage: '', // use for streaming, display 10, 20%

			records: [],

			// dragdrop
			draggingId: '',
			dragEnterId: '',
			dragEnterPosition: '',
		}
	},

	created() {
		this.query = lo.cloneDeep(this.init_query || {})
		this.preferRecords = []
		this.prepareFunctions()
	},

	watch: {
		selectedIds() {
			this.setCheckAllIndeterminateState()
		},
	},

	mounted() {
		store.onStandardObject(this, async (payload) => {
			let objecType = lo.get(payload, 'object_type')
			objecType = sb.snackCase(objecType)
			let action = lo.get(payload, 'action')

			if (objecType !== sb.snackCase(this.object_type)) return
			if (action === 'delete') {
				let deletedId = lo.get(payload, 'data.id')
				if (deletedId) {
					this.records = lo.filter(this.records, (record) => record.id !== deletedId)
					this.total = this.total - 1
					this.$emit('check', [])
				}
			}
			if (action === 'update') {
				let newRecord = lo.get(payload, 'data')
				if (!newRecord) return
				if (typeof newRecord !== 'object') return
				let records = lo.cloneDeep(this.records)
				let idx = lo.findIndex(records, (record) => record.id === newRecord.id)
				if (idx < 0) return
				records[idx] = newRecord
				this.records = records
			}
			if (action === 'create') {
				await this.loadData({silent: true})
			}
		})

		if (this.matchFunction && typeof this.matchFunction === 'function') {
			let {records, total} = this.matchFunction(this.query)
			if (lo.size(records)) {
				this.records = records || []
				this.total = total || 0
				this.loadData({silent: true})
			} else {
				this.loadData()
			}
		} else {
			this.loadData()
		}
	},

	methods: {
		ApplyFilter(query, options = {reset_error: true}) {
			this.query = query
			if (options.reset_error) this.error = ''
			this.loadData(options)
		},

		prepareFunctions() {
			let {
				matchFuncName,
				fetchFuncName,
				onFunctionName,
				fetchIdsFuncName,
				updateOrderedFunctionName,
				isRowDisabledFuncName,
			} = this.getFunctionsName()

			this.matchFunction = store[matchFuncName]
			this.fetchFunction = store[fetchFuncName]
			this.fetchIdsFunction = store[fetchIdsFuncName]
			this.onFunction = store[onFunctionName]
			this.updateOrderedFunction = store[updateOrderedFunctionName]
			this.isRowDisabledFunction = store[isRowDisabledFuncName]
		},

		getFunctionsName() {
			let snackName = sb.snackCase(this.object_type)
			let words = sb.snakeCaseToCapitalCamelCase(snackName)

			return {
				matchFuncName: `match${words}List`,
				fetchFuncName: `fetch${words}List`,
				fetchIdsFuncName: `fetch${words}Ids`,
				onFunctionName: `on${words}`,
				updateOrderedFunctionName: `update${words}Ordered`,
				isRowDisabledFuncName: `is${words}Disabled`,
			}
		},

		streamingCb(fetchId, records, totalRecord, {current, total}) {
			if (fetchId !== this._fetchId) return
			this.records = records
			this.total = totalRecord
			this.percentage = sb.displayPercentage(current, total)
		},

		async loadData(options = {silent: false}) {
			if (this.loading || this.syncState === 'loading') return
			if (options.silent) {
				this.syncState = 'loading'
			} else {
				this.loading = true
			}
			this.percentage = ''
			let _fetchId = sb.randomString(4) + Date.now()
			this._fetchId = _fetchId
			let res = await this.fetchFunction(this.query, (records, totalRecord, {current, total}) =>
				this.streamingCb(_fetchId, records, totalRecord, {current, total}),
			)
			if (this.prefer_selected && lo.size(this.selectedIds) && typeof this.fetchIdsFunction === 'function') {
				let res2 = await this.fetchIdsFunction(this.selectedIds)
				if (res2.error) {
					res = {error: res2.error}
				} else {
					this.preferRecords = lo.get(res2, 'items') || []
				}
			}
			if (_fetchId !== this._fetchId) return
			this.percentage = ''
			if (options.silent) {
				this.syncState = 'loading'
			} else {
				this.loading = false
			}
			this.loaded = true

			if (options.silent) {
				this.syncState = res.error ? 'error' : 'success'
				setTimeout(() => {
					this.syncState = ''
				}, 5_000)
			} else {
				this.error = res.error || ''
			}
			if (res.error) return

			let records = lo.get(res, 'items') || []
			if (!lo.size(this.preferRecords)) {
				this.records = records
			} else {
				let result = [...this.preferRecords]
				lo.each(records, (record) => {
					if (!lo.find(this.preferRecords, (precord) => precord.id === record.id)) {
						result.push(record)
					}
				})
				this.records = result
			}
			this.total = lo.get(res, 'total') || 0
			this.anchor = lo.get(res, 'next_anchor')
		},

		async loadMore() {
			if (this.loadingMore) return
			if (!this.anchor) return
			this.loadingMore = true
			let res = await this.fetchFunction({...this.query, anchor: this.anchor})
			this.loadingMore = false

			if (res.error) {
				this.$showError(res.error)
				return
			}

			let records = lo.get(res, 'items') || []
			this.records = [...this.records, ...records]
			this.anchor = lo.get(res, 'next_anchor')
			this.total = lo.get(res, 'total') || 0
		},

		isCheckAll() {
			if (!lo.size(this.selectedIds)) return false
			if (lo.size(this.selectedIds) !== lo.size(this.records)) return false

			let selectedIds = lo.cloneDeep(this.selectedIds)
			selectedIds = selectedIds.sort()
			let ids = lo.map(this.records, (record) => record.id)
			ids = ids.sort()

			return lo.isEqual(ids, selectedIds)
		},

		setCheckAllIndeterminateState() {
			let result = lo.size(this.selectedIds) > 0 && !this.isCheckAll()
			let $cb = this.$refs.checkbox_all
			if ($cb) {
				$cb.indeterminate = result
			}
		},

		toggleCheckAll() {
			if (this.isCheckAll()) {
				this.$emit('check', [])
			} else {
				let selectedIds = lo.map(this.records, (record) => record.id)
				this.$emit('check', selectedIds)
			}
		},

		toggleCheckId(id, e) {
			if (!e || !e.shiftKey) {
				let selectedIds = []
				if (lo.includes(this.selectedIds, id)) {
					selectedIds = lo.filter(this.selectedIds, (sid) => sid !== id)
				} else {
					selectedIds = [...this.selectedIds, id]
				}
				this.lastToggleCheckId = id
				this.$emit('check', selectedIds)
				return
			}
			// remove all selection text
			window.document.getSelection().removeAllRanges()

			let fromidx = lo.findIndex(this.records, (record) => record.id === id)
			let toidx = lo.findIndex(this.records, (record) => record.id === this.lastToggleCheckId)

			let from = 0
			let to = 0
			if (fromidx >= toidx) {
				from = toidx
				to = fromidx
			} else {
				from = fromidx
				to = toidx
			}

			let selectedIds = lo.cloneDeep(this.selectedIds)
			let isChecked = lo.includes(selectedIds, id)

			for (let i = from; i <= to; i++) {
				if (isChecked) {
					selectedIds = selectedIds.filter((id) => id !== lo.get(this.records, [i, 'id']))
				} else {
					selectedIds.push(lo.get(this.records, [i, 'id']))
				}
			}
			selectedIds = lo.uniq(selectedIds)
			this.lastToggleCheckId = id
			this.$emit('check', selectedIds)
		},

		onScroll: lo.throttle(
			function (e) {
				let list = e.target
				let distToBottom = list.scrollHeight - list.scrollTop - list.clientHeight
				// list.scrollTop > this.distToTop mean scrol down
				if (list.scrollTop > this.distToTop && distToBottom < 100) this.loadMore()
				this.distToTop = list.scrollTop
			},
			500,
			{trailing: true},
		),

		renderEmptyContent() {
			if (!this.loaded) return

			let initQuery = this.init_query || {}
			let hasFilter = !lo.isEqual(this.query, initQuery)
			if (hasFilter) {
				return (
					<div key='empty' class='w_100 d-flex align-items-center justify-content-center' style='height: 300px'>
						<div class='text__center text__muted'>
							<img src={require('../assets/img/not_found_search.png')} width='80' />
							<div class='mt-3'>{this.$t('no_search_result')}</div>
						</div>
					</div>
				)
			} else {
				if (this.customEmptyTemplate) return this.customEmptyTemplate
				return (
					<div key='empty_2' class='w_100 d-flex align-items-center justify-content-center' style='height: 300px'>
						<div class='text__center text__muted'>
							<img src={require('../assets/img/empty_2.svg')} width='80' />
							<div class='mt-3'>{this.$t('no_record_found')}</div>
						</div>
					</div>
				)
			}
		},

		renderErrorContent() {
			return (
				<div key='error' class='w_100 d-flex align-items-center justify-content-center' style='height: 300px'>
					<div class='text__center'>
						<img src={require('../assets/img/error-page.svg')} width='200' />
						<div class='mt-3 text__danger'>
							{this.$t('error_occurs_when_load_data')}.{' '}
							<a href='javascript:;' vOn:click={() => this.loadData()}>
								{this.$t('reload')}
								{this.loading && <Spinner mode='blue' size='18' class='ml-2' />}
							</a>
						</div>
					</div>
				</div>
			)
		},

		onTableRowClick(record, idx, e) {
			if (this.row_click_cb && typeof this.row_click_cb === 'function') {
				// avoid select text action is a click behavior
				let selectedText = document.getSelection().toString()
				if (selectedText) return
				this.row_click_cb(record, idx, e)
			}
		},

		computeHeightMap() {
			let result = {}
			lo.each(this.records, (record) => {
				let $row = this.$refs[`row_${record.id}`]
				if ($row && $row.offsetHeight) result[record.id] = $row.offsetHeight
			})

			this.heightMap = result
		},

		onDragStart(e, record) {
			let id = record.id
			let $icon = this.$refs[`${id}_grab_icon`]
			if (!$icon) return e.preventDefault()

			let rect = $icon.getBoundingClientRect()
			if (e.clientX < rect.left) return e.preventDefault()
			if (e.clientX > rect.right) return e.preventDefault()
			if (e.clientY < rect.top) return e.preventDefault()
			if (e.clientY > rect.bottom) return e.preventDefault()

			this.computeHeightMap()

			this.draggingId = id
			this.draggingRowHeight = this.heightMap[id]
		},

		onDragOver(e, record) {
			let id = record.id
			if (!this.draggingId) return
			if (id === this.draggingId) return

			let currentRowHeight = this.heightMap[id]
			let gap = Math.round(this.draggingRowHeight / 2)
			if (currentRowHeight < this.dragEnterPosition) {
				let gap = Math.round(currentRowHeight / 2)
			}

			if (e.offsetY < gap) {
				this.dragEnterId = id
				this.dragEnterPosition = 'before'
				return
			} else if (currentRowHeight - e.offsetY < gap) {
				this.dragEnterId = id
				this.dragEnterPosition = 'after'
				return
			}
			this.dragEnterId = ''
			this.dragEnterPosition = ''
			return
		},

		onDragEnd(e, record) {
			if (!this.draggingId || !this.dragEnterId) {
				this.draggingId = ''
				this.dragEnterId = ''
				this.dragEnterPosition = ''
				return
			}

			if (this.dragEnterId === this.draggingId) {
				this.draggingId = ''
				this.dragEnterId = ''
				this.dragEnterPosition = ''
				return
			}

			let fromindex = lo.findIndex(this.records, (record) => record.id === this.draggingId)
			let toindex = lo.findIndex(this.records, (record) => record.id === this.dragEnterId)

			let direction
			if (fromindex < toindex) direction = 'down'
			else direction = 'up'

			if (this.dragEnterPosition === 'before' && direction === 'down') toindex = toindex - 1
			if (this.dragEnterPosition === 'after' && direction === 'up') toindex = toindex + 1

			if (fromindex === toindex) {
				this.draggingId = ''
				this.dragEnterId = ''
				this.dragEnterPosition = ''
				return
			}

			let records = lo.cloneDeep(this.records)
			let draggingRecord = lo.get(this.records, fromindex)
			if (fromindex < toindex) {
				for (let i = fromindex; i < toindex; i++) {
					records[i] = records[i + 1]
				}
				records[toindex] = draggingRecord
			}
			if (fromindex > toindex) {
				for (let i = fromindex; i > toindex; i--) {
					records[i] = records[i - 1]
				}
				records[toindex] = draggingRecord
			}

			this.records = records
			this.draggingId = ''
			this.dragEnterId = ''
			this.dragEnterPosition = ''
			this.$emit('dragdrop', records)
			this.updateOrderedList(lo.map(records, 'id'))
		},

		async updateOrderedList(ids) {
			if (!this.updateOrderedFunction || typeof this.updateOrderedFunction !== 'function') {
				return
			}

			this.softUpdateState = 'loading'
			let res = await this.updateOrderedFunction(ids)
			await sb.sleep(500)
			this.softUpdateState = res.error ? 'error' : 'success'
			setTimeout(() => {
				this.softUpdateState = ''
			}, 2_000)
		},

		renderRow(record, idx) {
			let cls = {
				sbz_table_row: true,
				selected: lo.includes(this.selectedIds, record.id),
				focused: this.focusId && this.focusId === record.id,
				clickable: this.row_click_cb && typeof this.row_click_cb === 'function',
				dragging: this.draggingId && this.draggingId === record.id,
			}
			if (this.isRowDisabledFunction && typeof this.isRowDisabledFunction === 'function') {
				if (this.isRowDisabledFunction(record, idx, this.records)) cls.disabled = true
			}
			if (this.extra_cls_cb && typeof this.extra_cls_cb === 'function') {
				let result = this.extra_cls_cb(record, idx, this.records)
				let clsnames = []
				if (typeof result === 'string') {
					clsnames = lo.split(result, ' ')
				} else if (Array.isArray(result)) {
					clsnames = result
				}

				lo.each(clsnames, (clsname) => {
					clsname = lo.trim(clsname)
					cls[clsname] = true
				})
			}

			return (
				<div
					ref={`row_${record.id}`}
					draggable={this.dragdrop}
					vOn:dragstart={(e) => this.onDragStart(e, record)}
					vOn:dragover={(e) => this.onDragOver(e, record)}
					vOn:dragend={(e) => this.onDragEnd(e, record)}
					class={cls}
					key={record.id}
					vOn:click={(e) => this.onTableRowClick(record, idx, e)}
				>
					{this.dragdrop && (
						<div class='no-shrink sbz_table_cell cell_grip'>
							<div style='position: relative; line-height: 1' ref={`${record.id}_grab_icon`}>
								<Icon name='grip-vertical' size='18' class='x-icon' style='cursor: grabbing' />
							</div>
						</div>
					)}
					{this.selectedIds && (
						<div class='no-shrink sbz_table_cell' vOn:click_stop={(e) => this.toggleCheckId(record.id, e)}>
							<input
								checked={lo.includes(this.selectedIds, record.id)}
								style='margin: 0'
								type='checkbox'
								class='form-check-input form-check-input--bold'
							/>
						</div>
					)}
					{lo.map(this.columns, (column) => {
						let $content = record[column.key]
						if (column.render && typeof column.render === 'function') {
							$content = column.render(record, idx)
						}

						let cls = 'sbz_table_cell'
						let style = {}
						if (column.width) {
							style.width = `${column.width}px`
						}
						if (column.minWidth) {
							style.minWidth = `${column.minWidth}px`
						}
						if (column.flex) {
							style.flex = column.flex
						}
						if (column.right) {
							cls += ' justify-content-end'
						}
						return (
							<div class={cls} style={style} key={column.key}>
								{$content}
							</div>
						)
					})}
				</div>
			)
		},

		renderContent() {
			if (this.error) return this.renderErrorContent()
			if (!lo.size(this.records)) return this.renderEmptyContent()

			return (
				<Fragment>
					{lo.map(this.records, (record, idx) => {
						let $content = this.renderRow(record, idx)
						if (!this.dragdrop) return $content
						return (
							<div key={record.id} class='sbz_table_row_dragdrop_wrapper'>
								{this.draggingId && this.dragEnterId === record.id && this.dragEnterPosition === 'before' && (
									<div class='sbz_table_row_dragable_line before' />
								)}
								{$content}
								{this.draggingId && this.dragEnterId === record.id && this.dragEnterPosition === 'after' && (
									<div class='sbz_table_row_dragable_line after' />
								)}
							</div>
						)
					})}
					{this.anchor && (
						<div
							class='item-row justify-content-center'
							style={this.loadingMore ? 'opacity: 0.7; pointer-events: none' : ''}
						>
							<a href='javascript:;' vOn:click={this.loadMore}>
								{this.loadingMore ? (
									<Fragment>
										<Spinner size='16' mode='blue' class='ml-2' /> {this.$t('loading')}
									</Fragment>
								) : (
									this.$t('load_more')
								)}
							</a>
						</div>
					)}
				</Fragment>
			)
		},

		renderSyncState() {
			if (!this.syncState) return
			if (this.syncState === 'loading') return <em>{this.$t('syncing')}</em>
			if (this.syncState === 'success')
				return (
					<div class='d-flex align-items-center'>
						{this.$t('sync_data_successfully')} <Icon name='circle-check-filled' class='text__success ml-2' size='16' />
					</div>
				)
			if (this.syncState === 'error')
				return (
					<div class='d-flex align-items-center'>
						<Icon name='circle-x-filled' class='text__danger mr-2' size='16' />
						<div>
							<span class='text__danger'>{this.$t('sync_data_failed')}.</span>{' '}
							<a class='link link__danger text__underline' href='javascript:;' vOn:click={() => this.loadData()}>
								{this.$t('retry')}
							</a>
						</div>
					</div>
				)
		},

		renderSoftUpdateState() {
			let $icon = <Spinner size='16' />
			let text = this.$t('updating') + '...'

			if (this.softUpdateState === 'success') {
				$icon = <Icon size='16' name='circle-check-filled' class='text__success' />
				text = this.$t('update_success')
			} else if (this.softUpdateState === 'error') {
				$icon = <Icon size='16' name='circle-x-filled' class='text__danger' />
				text = this.$t('update_failed')
			}

			return (
				<div
					class='campaign_design__loading_label'
					style={{left: 0, opacity: 0.85, zIndex: 1, display: this.softUpdateState ? 'flex' : 'none'}}
				>
					{$icon}
					<div class='ml-3'>{text}</div>
				</div>
			)
		},
	},

	render() {
		return (
			<div class='sbz_common_table_container'>
				<div class='sbz_common_table_header'>
					<div class='sbz_table_row header'>
						{this.dragdrop && <div class='no-shrink sbz_table_cell cell_grip'></div>}
						{this.selectedIds && (
							<div class='sbz_table_cell no-shrink' vOn:click_stop={this.toggleCheckAll}>
								<input
									style='margin: 0'
									ref={'checkbox_all'}
									checked={this.isCheckAll()}
									type='checkbox'
									class='form-check-input form-check-input--bold'
								/>
							</div>
						)}
						{lo.map(this.columns, (column) => {
							let cls = 'sbz_table_cell'
							let style = {}
							if (column.width) {
								style.width = `${column.width}px`
							}
							if (column.minWidth) {
								style.minWidth = `${column.minWidth}px`
							}
							if (column.flex) {
								style.flex = column.flex
							}
							if (column.right) {
								cls += ' justify-content-end'
							}
							return (
								<div class={cls} style={style}>
									{column.label}
								</div>
							)
						})}
					</div>
				</div>
				<div class='sbz_common_table_content_wrapper'>
					<div class='loading-wrapper' style={this.loading && !this.error ? '' : 'display: none'}>
						<div class='sbz_table_loading_inner'>
							<Spinner size='32' mode='dark' />
							<br />
							<div class='text__secondary mt-3'>
								{this.$t('loading_data')} {this.percentage}
							</div>
						</div>
					</div>
					{this.renderSoftUpdateState()}

					<div class='sbz_common_table_content' vOn:scroll={this.onScroll}>
						{this.renderContent()}
					</div>
				</div>
				<div class='sbz_common_table_footer d-flex align-items-center'>
					{this.renderSyncState()}
					<div class='flex__1' />
					{lo.size(this.selectedIds) ? (
						<div class='text__muted'>
							{this.$t('selected')} {accounting.formatNumber(lo.size(this.selectedIds))} {this.$t('in_total')}{' '}
							{accounting.formatNumber(this.total)} {this.$t('records').toLowerCase()}
						</div>
					) : (
						<div class='text__muted'>
							{this.$t('total2')}: {accounting.formatNumber(this.total)} {this.$t('records').toLowerCase()}
						</div>
					)}
				</div>
			</div>
		)
	},
}
