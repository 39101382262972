import {StyleSheet} from 'react-native'
import {useInteractionState} from './hooks/useInteractionState'
import {web} from './platform'
import {color} from './atoms'

export function Link({
	children,
	href,
	action = 'push',
	disableMismatchWarning,
	disabled,
	style,
	onPress,
	onLongPress,
	download,
	selectable,
	label,
	shareOnLongPress,
	disableUnderline,
	overridePresentation,
	...rest
}) {
	const {state: hovered, onIn: onHoverIn, onOut: onHoverOut} = useInteractionState()
	const flattenedStyle = StyleSheet.flatten(style) || {}
	let isExternal = true
	return (
		<Text
			selectable={selectable}
			accessibilityHint=''
			accessibilityLabel={label}
			{...rest}
			style={[
				{color: color.primary_500},
				hovered && {
					...web({
						outline: 0,
						textDecorationLine: !disableUnderline ? 'underline' : 'none',
						textDecorationColor: flattenedStyle.color ?? color.primary_600,
						color: color.primary_600,
					}),
				},
				disabled && {
					...web({
						cursor: 'auto',
						opacity: 0.7,
						textDecorationLine: 'none',
					}),
				},
				flattenedStyle,
			]}
			role='link'
			onPress={download || disabled ? undefined : onPress}
			onLongPress={onLongPress}
			onMouseEnter={onHoverIn}
			onMouseLeave={onHoverOut}
			accessibilityRole='link'
			href={href}
			{...web({
				hrefAttrs: {
					target: download ? undefined : isExternal ? 'blank' : undefined,
					rel: isExternal ? 'noopener noreferrer' : undefined,
					download,
				},
				dataSet: {
					// default to no underline, apply this ourselves
					noUnderline: '1',
				},
			})}
		>
			{children}
		</Text>
	)
}
