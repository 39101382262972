var sb = require('@sb/util')
const LoadWidget = () => import('@subiz/widget-v4/compiled_src/lib.js')
const config = require('@sb/config')
import {OnelineMsg} from '@sb/com'
import store from '@sb/store'

export default {
	name: 'internal-chat',
	props: ['quickviewUcid', 'isShowQuickview'],
	data() {
		return {
			cid: '',
			closed: true,
			view: 'list', //chat, list

			groupName: '',
			selectedMembers: {},
			cid: '',

			creatingGroup: false,

			outOfChat: false,
			loadingMore: false,

			history: [],
			pinned: false,

			showMsg: {},
		}
	},

	render() {
		return (
			<div class='d-flex' style='flex-shrink: 0; width: 45px'>
				<div style='position: relative'>
					<div class='recent_users' style='width: 45px' />
					<HelpButton />
				</div>
			</div>
		)
	},
}

let HelpButton = {
	name: 'help-button',

	data() {
		return {
			unread: 0,
			isHelpAvailable: false,
			previewEv: null,
			closedPreview: false,
			previewClass: 'help_wave',
		}
	},

	mounted() {
		this.wginterval = setInterval(async () => {
			if (!this.widget) return
			let unread = await this.widget.countUnreadMessages()
			if (this.unread != unread) this.unread = unread
		}, 2000)

		this.loadWidget()
		store.onAccount(this, () => this.$forceUpdate())
		this.$root.$on('expandWidget', this.openWidget)
	},

	beforeDestroy() {
		this.widget && this.widget.logout()
		this.widget && this.widget.unEvent(this.onWidgetEvent)
		clearInterval(this.wginterval)
	},

	methods: {
		openWidget() {
			if (this.widget) this.widget.expandWidget()
		},

		async loadWidget() {
			if (this.widget) return
			let Widget = (await LoadWidget()).default
			let widget = new Widget(config.ApiURL, config.FileUrl, config.RealtimeURL, true, false)
			widget.setTrackPageView(false)

			window._widget = widget
			let accid = store.me().account_id
			let {body, error} = await store.fetchWidget()
			if (!body || error) {
			}

			store.setSubizAgent(body.agents)

			let chatbox = lo.find(body.plugins, (plugin) => plugin.id == 'wpwidget' || plugin.type == 'chatbox')
			if (!chatbox) return

			let chatboxHeight = 550
			let chatboxWidth = 400
			// somehow 1280x768 screen, chatbox close button was overlapped
			if (window.innerWidth <= 1380) {
				chatboxHeight = 500
				chatboxWidth = 380
			}
			chatbox.account_id = 'acpxkgumifuoofoosble'
			lo.set(chatbox, 'chatbox.desktop_button.hidden', true)
			lo.set(chatbox, 'chatbox.height', chatboxHeight)
			lo.set(chatbox, 'chatbox.width', chatboxWidth)
			lo.set(chatbox, 'chatbox.enabled', true)
			lo.set(chatbox, 'chatbox.desktop_button.position', 'right')
			lo.set(chatbox, 'chatbox.desktop_button.margin_x', 5)
			lo.set(chatbox, 'chatbox.desktop_button.margin_y', 90)
			lo.set(chatbox, 'triggers', undefined)
			lo.set(chatbox, 'initiative_frequency', undefined)
			lo.set(chatbox, 'conditions', undefined)
			lo.set(chatbox, '_no_auto_expand', true)
			widget._overridePlugin([chatbox])

			// this.isHelpAvailable = lo.get(chatbox, 'chatbox.is_online') ||
			this.isHelpAvailable = lo.get(body, 'is_in_business_hour')
			await widget.login(
				'acpxkgumifuoofoosble',
				store.me().id,
				async () => {
					let out = await store.createUserAccessToken()
					return out.body
				},
				this.$refs.widget,
			)

			widget.onEvent(this.onWidgetEvent)
			setTimeout(() => {
				this.widget = widget
				let convos = lo.map(widget.store.matchConvo('*'))
				// if (lo.size(convos) > 0) this.closeHelpWave()
				let convo = lo.maxBy(convos, function (convo) {
					return lo.get(convo, 'last_message_sent.created', convo.created)
				})
				if (convo) widget.store.setLastview('chat', {cid: convo.id})

				// show preview if has the last convo
				let redconvo = lo.find(convos, (convo) => !isConvoSeen(convo, widget.store.matchUser().id))
				if (redconvo && redconvo.last_message_sent) {
					this.showWidget('chat', redconvo.id)
				}
				this.$forceUpdate()
			}, 3000)
		},

		shouldRenderPreview() {
			if (!this.previewEv) return false
			if (this.closedPreview) return false
			if (Date.now() - (parseInt(store.matchCloseHelpWave()) || 0) < 5 * 60 * 1000) return false
			return true
		},

		renderPreview() {
			if (!this.shouldRenderPreview()) return null

			let ag = store.matchSubizAgent(lo.get(this.previewEv, 'by.id')) || {}
			let name = ag.fullname || 'Subiz'

			let convoid = sb.convoOfEv(this.previewEv)
			return (
				<div class={this.previewClass} vOn:click={(_) => this.showWidget(undefined, convoid)}>
					<div class='d-flex' style='align-items: flex-start; overflow: hidden;'>
						<div style='margin-left: 10px; overflow: hidden; flex: 1'>
							<div class='text__semibold' style='font-size: 14px'>
								{name}
							</div>
							<OnelineMsg class='text-truncate' ev={this.previewEv} />
						</div>
						<Icon
							name='x'
							stroke-width='2'
							v-tooltip={this.$t('close')}
							class='btn__close btn__close_light'
							vOn:click_stop={this.manuallyCloseHelpWave}
							style='flex-shrink: 0'
						/>
					</div>
				</div>
			)
		},

		onWidgetEvent(ev) {
			if (ev.type !== 'message_sent') return
			if (ev.by.type === 'user') return

			// show on bot message that start conversation
			let convoid = lo.get(ev, 'data.message.conversation_id')
			if (!this.widget) return
			if (lo.get(this.widget, 'store.matchConvo')) {
				let convo = this.widget.store.matchConvo(convoid)
				if (ev.created - convo.created < 3000) {
					// new convo
					this.showWidget('chat', convoid)
					this.setPreviewEvent()
					return
				}
			}
			this.setPreviewEvent(ev)
		},

		manuallyCloseHelpWave() {
			// close for 5 min
			this.closedPreview = true
			store.closeHelpWave(Date.now())
			this.$forceUpdate()
		},

		showWidget(_, convoid) {
			this.closedPreview = true
			store.closeHelpWave(0) // reset close
			this.$forceUpdate()

			if (!this.widget) return
			if (!convoid) {
				this.widget.showWidget()
			} else {
				this.widget.showWidget('chat', convoid)
			}
		},

		async setPreviewEvent(ev) {
			this._setPreviewIndex = this._setPreviewIndex || 0
			let _setPreviewIndex = this._setPreviewIndex
			this.previewClass = 'help_wave help_wave__hidden'
			this.closedPreview = false

			await sb.sleep(200)
			let cv = await this.widget.getWidgetCurrentView()
			if (_setPreviewIndex != this._setPreviewIndex) return // outdated
			let isWidgetOpen = lo.get(cv, 'is_show_widget', false)
			if (isWidgetOpen) this.previewEv = null
			else this.previewEv = ev

			if (_setPreviewIndex != this._setPreviewIndex) return // outdated
			this.previewClass = 'help_wave'
		},

		onSelectChatOption(item) {
			if (item.id === 'chat') return this.showWidget()
			if (item.id === 'call') return this.$root.$emit('show_call_modal', {call_to: '02473021368'})
		},
	},

	render() {
		if (!this.widget) {
			return (
				<div style='opacity: 0.5;' key='bg'>
					<div class='global_help_button' v-tooltip={this.$t('chat_with_subiz')}>
						{this.$t('loading')}...
					</div>
				</div>
			)
		}

		// let $icon = <Emoji emojiCode='wave' md class="ml-2" style='transform: rotate(90deg)' />
		// let $icon = <Icon name='message' stroke-width='2' size='18' class='ml-2' />
		let $icon = (
			<div style='width: 18px; height: 18px;' class='ml-2 d-flex align-items-center justify-content-center'>
				<div style='width: 11px; height: 11px; background: #00000030; border-radius: 50%'></div>
			</div>
		)
		if (this.isHelpAvailable) {
			$icon = (
				<div style='width: 18px; height: 18px;' class='ml-2 d-flex align-items-center justify-content-center'>
					<div style='width: 11px; height: 11px; background: #19b600; border-radius: 50%'></div>
				</div>
			)
		}

		if (this.shouldRenderPreview()) {
			let ag = store.matchSubizAgent(lo.get(this.previewEv, 'by.id')) || {}
			$icon = <Avatar agent={ag} nodot size='18' class='ml-2' style='transform: rotate(90deg);' />
		} else {
			if (this.unread > 0)
				$icon = (
					<div
						style='background: #EA3D2F; width: 18px; height: 18px; border-radius: 50%; padding: 2px; overflow: hidden; color: white; font-size: 11px;  transform: rotate(90deg); font-weight: 700'
						class='ml-2 d-flex align-items-center justify-content-center'
					>
						{this.unread > 9 ? '*' : this.unread}
					</div>
				)
		}
		return (
			<div key='bg' style='position: relative;'>
				<div
					class='global_help_button global_help_button__enabled'
					vOn:click={this.showWidget}
					v-tooltip={{content: this.$t('chat_with_subiz'), placement: 'left'}}
				>
					{this.$t('support_247')} {$icon}
				</div>
				{this.renderPreview()}
			</div>
		)
	},
}

function isConvoSeen(convo, memberid) {
	if (convo.state === 'ended') return true
	let lastmsgsent = convo.last_message_sent
	if (!lastmsgsent) return true
	if (lo.get(lastmsgsent, 'by.id') === memberid) return true
	let mem = lo.find(convo.members, (m) => m.id === memberid)
	if (!mem) return true
	return sb.getMs(lastmsgsent.created) < sb.getMs(mem.seen_at)
}
