const sb = require('@sb/util')
import React from 'react'
var pubsub = new sb.Pubsub()
import store from '../store'
import {addMinutes, subDays, format, formatDistanceStrict} from 'date-fns'
import {getDateFnsLocale, getCurrentLanguage} from '../language.js'

let TZMin = getTimezoneMinutes()
let DF_DATE_FORMAT = getDefaultDateFormat()
setInterval(() => {
	pubsub.publish('time')
	TZMin = getTimezoneMinutes()
	DF_DATE_FORMAT = getDefaultDateFormat()
}, 20000)

setTimeout(() => {
	TZMin = getTimezoneMinutes()
	DF_DATE_FORMAT = getDefaultDateFormat()
	pubsub.publish('time')
}, 1000)

function addMiniutes(date, min) {
	return new Date(Date(date).getTime() + min * 60000)
}

function getDefaultDateFormat() {
	let fm = lo.get(store.me(), 'account.date_format') || 'dd/MM/yyyy'
	fm = fm.replace(/DD/g, 'dd')
	fm = fm.replace(/Y/g, 'y')

	return fm
}

function getTimezoneMinutes() {
	// 07:00 => 420
	let tz = sb.getTimezone()
	let accountTimezone = lo.get(store.me(), 'account.timezone')
	let agentTimezone = lo.get(store.me(), 'timezone')

	if (accountTimezone) tz = accountTimezone
	if (agentTimezone) tz = agentTimezone

	return sb.offsetToMin(tz)
}

// timezone aware
// 1. Full
//    Format: ${account_date_format} 04:44:24
//    Example: 2022-04-13 04:44:44
//
// 2. Default (default, should use most often)
//    Example: "15:45 Hom nay", "15:45 Hom qua" ; "05:54 24/04" ; "05:54 24/04/2022"
//
// 3. Date only
//    Format: ${account_date_format}
//    Example: 2022/04/24
//
// 4. Shorten
//    Example: 04:45 AM  ; Yesterday  ; Jun 9 ; Mar 7 ; 5/5/21
//
// 5. Ago (short, suffix)
//    Example: 30 giay ; 2 thang ; 1 nam ;
//    Example with suffix enabled: vua xong ; 30 giay ago; 2 thang truoc
//    Example with short enabled: 1s ; 3p ; 4g, 5n ; 3th ; 1n
//
// 6. Duration (short, duration)
//    Example: 4 giay, 10 giay, 50 ngay
//    Example: 4s, 10p, 5h, 8n, 9t, 1n

/*
	time: null,
	ago: null,
	short: null,
	duration: null,
	notooltip: null,
	agox: null,
	format: null,
	full: null,
	suffix: null,
	date_only: null,
	tooltip_prefix: null,
	tooltip_suffix: null,
	*/
export const Time = React.memo(function Time(props) {
	const [_, forceRender] = React.useState(0)
	React.useEffect(() => {
		let subid
		if (props.ago || props.duration) {
			subid = pubsub.on('time', () => {
				forceRender((prev) => prev + 1)
			})
		}
		return () => {
			if (subid) pubsub.un(subid)
		}
	}, [props.ago, props.duration])

	let renderFull = (ms, shorten) => {
		let date = getTzTime(new Date(ms))
		let fm = DF_DATE_FORMAT + ' HH:mm:ss'

		// date only
		if (shorten) fm = DF_DATE_FORMAT
		return <Text {...props}>{format(date, fm, {locale: getDateFnsLocale()})}</Text>
	}

	let renderDateOnly = (ms) => {
		let date = new Date(ms)
		let datetz = getTzTime(date)

		let day = ''
		if (isToday(date)) day = $t('today')
		else if (isYesterday(date)) day = $t('yesterday')
		else if (isSameYear(date)) {
			let fm = DF_DATE_FORMAT

			// find weird charactor
			let delimiter = '/'
			for (var i = 0; i < fm.length; i++) {
				let c = fm.charAt(i)
				if (c < 'A' || c > 'z') {
					delimiter = c
					break
				}
			}
			fm = fm.replace(/y/g, '')
			fm = fm.replace(/Y/g, '')

			fm = fm
				.split(delimiter)
				.filter((a) => a)
				.join(delimiter)

			day = format(datetz, fm, {locale: getDateFnsLocale()})
		} else {
			day = format(datetz, DF_DATE_FORMAT, {locale: getDateFnsLocale()})
		}

		return <Text {...props}>{day}</Text>
	}

	let renderDefault = (ms) => {
		let date = new Date(ms)
		let datetz = getTzTime(date)
		let hour = format(datetz, 'HH:mm')

		let day = ''
		if (isToday(date)) day = $t('today')
		else if (isYesterday(date)) day = $t('yesterday')
		else if (isSameYear(date)) {
			let fm = DF_DATE_FORMAT

			// find weird charactor
			let delimiter = '/'
			for (var i = 0; i < fm.length; i++) {
				let c = fm.charAt(i)
				if (c < 'A' || c > 'z') {
					delimiter = c
					break
				}
			}
			fm = fm.replace(/y/g, '')
			fm = fm.replace(/Y/g, '')

			fm = fm
				.split(delimiter)
				.filter((a) => a)
				.join(delimiter)

			// display more readable like, 8:00, 3 thg8, 9:00, Jun 1
			fm = 'MMM d'
			if (getCurrentLanguage() === 'vi') fm = 'd MMM'
			day = format(datetz, fm, {locale: getDateFnsLocale()})
		} else {
			day = format(datetz, DF_DATE_FORMAT, {locale: getDateFnsLocale()})
		}

		if (!day) return <Text {...props}>{hour}</Text>
		return <Text {...props}>{hour + ', ' + day}</Text>
	}

	let renderShortDistance = (distance) => {
		let lang = getCurrentLanguage()
		if (distance < 60_000) {
			out = Math.round(distance / 1000) + lo.get(TOKENS, `second.${lang}`)
		} else if (distance < 60 * 60_000) {
			out = Math.round(distance / 60_000) + lo.get(TOKENS, `minute.${lang}`)
		} else if (distance < 24 * 60 * 60_000) {
			out = Math.round(distance / (60 * 60_000)) + lo.get(TOKENS, `hour.${lang}`)
		} else if (distance < 30 * 24 * 60 * 60_000) {
			out = Math.round(distance / (24 * 60 * 60_000)) + lo.get(TOKENS, `day.${lang}`)
		} else if (distance < 12 * 30 * 24 * 60 * 60_000) {
			out = Math.round(distance / (30 * 24 * 60 * 60_000)) + lo.get(TOKENS, `month.${lang}`)
		} else {
			out = Math.round(distance / (12 * 30 * 24 * 60 * 60_000)) + lo.get(TOKENS, `year.${lang}`)
		}
		return <Text {...props}>{out}</Text>
	}

	let renderAgo = (ms, shorten) => {
		if (isNaN(ms)) return <Text {...props}></Text>
		if (props.short) {
			return <Text {...props}>{renderShortDistance(sb.now() - ms)}</Text>
		}

		let text = formatDistanceStrict(ms, sb.now(), {addSuffix: props.suffix, locale: getDateFnsLocale()})
		if (sb.now() - ms < 30000) text = $t('time_ago_now') // recently
		return <Text {...props}>{text}</Text>
	}

	// 04:45 ; Yesterday  ; Jun 9 ; Mar 7 ; 5/5/21
	let renderShorten = (ms) => {
		let date = new Date(ms)
		if (isToday(date)) {
			let datetz = getTzTime(date)
			return <Text {...props}>{format(datetz, 'HH:mm', {locale: getDateFnsLocale()})}</Text>
		}

		if (isYesterday(date)) return <Text {...props}>{$t('yesterday')}</Text>

		let datetz = getTzTime(date)
		if (isSameYear(date)) {
			let dateFormat = 'MMM d'
			if (getCurrentLanguage() === 'vi') dateFormat = 'd MMM'
			return <Text {...props}>{format(datetz, dateFormat, {locale: getDateFnsLocale()})}</Text>
		}
		return <Text {...props}>{format(datetz, DF_DATE_FORMAT)}</Text>
	}

	let renderDuration = (short, duration) => {
		if (short) {
			return <Text {...props}>{renderShortDistance(duration)}</Text>
		}
		return (
			<Text {...props}>
				{formatDistanceStrict(0, duration, {
					locale: getDateFnsLocale(),
					includeSeconds: true,
				})}
			</Text>
		)
	}

	if (props.duration !== undefined) return renderDuration(props.short, props.duration)

	let ms = props.time || 0
	if (typeof ms === 'number') ms = sb.getMs(ms)
	else ms = new Date(ms).getTime()
	if (ms < 0) ms = 0
	if (ms == 0) return <Text></Text>
	if (isNaN(ms)) return <Text></Text>

	let fulldate = new Date(ms)
	fulldate = getTzTime(fulldate) || 0
	let tooltip = format(fulldate, DF_DATE_FORMAT + ', HH:mm:ss')
	if (props.tooltip_prefix) tooltip = `${props.tooltip_prefix} ${tooltip}`
	if (props.tooltip_suffix) tooltip = `${tooltip} ${props.tooltip_suffix}`
	if (props.notooltip || props.full || props.duration) tooltip = ''
	if (props.full) return renderFull(ms, props.short)
	if (props.ago || props.agox) return renderAgo(ms, props.short)
	if (props.date_only) return renderDateOnly(ms)

	if (props.short) return renderShorten(ms)
	return renderDefault(ms)
})

// since format(Date.now(), 'HH:mm') always return HH:mm in the current timezone of the OS
// we dont want that, we want HH:mm in timezone of the agent (or account)
// this function first convert HH:mm in OS timezone to HH:mm in UTC, then convert from UTC to correct tz
//
// eg: if you pass in 10:00 UTC and the OS timezone is +07:00, timezone of agent is 08:00
// calling format(time, "HH:mm") would return 17:00
// calling format(getTzTime(time), "HH:mm") would return 18:00
let getTzTime = (date) => {
	let utcDate = addMinutes(date, date.getTimezoneOffset())
	return addMinutes(utcDate, TZMin)
}

let isSameYear = (date) => {
	let tzNow = getTzTime(new Date())
	let tzDate = getTzTime(date)

	return tzNow.getYear() === tzDate.getYear()
}

let isToday = (date) => {
	let tzNow = getTzTime(new Date())
	let tzDate = getTzTime(date)
	return (
		tzNow.getDate() === tzDate.getDate() &&
		tzNow.getMonth() === tzDate.getMonth() &&
		tzNow.getYear() === tzDate.getYear()
	)
}

let isYesterday = (date) => {
	let yesterday = subDays(new Date(), 1)
	let tzNow = getTzTime(yesterday)
	let tzDate = getTzTime(date)
	return (
		tzNow.getDate() === tzDate.getDate() &&
		tzNow.getMonth() === tzDate.getMonth() &&
		tzNow.getYear() === tzDate.getYear()
	)
}

const TOKENS = {
	second: {vi: 's', en: 's'},
	minute: {vi: 'p', en: 'm'},
	hour: {vi: 'g', en: 'h'},
	day: {vi: 'ng', en: 'd'},
	month: {vi: 'thg', en: 'mo'},
	year: {vi: 'n', en: 'y'},
}
